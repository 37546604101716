export type RelativeInfo = {
  id: number
  title: string
  firstname: string
  lastname: string
  birthday: number
  nationalId: string
  userId: number
  relativeTypeId: number
  createdDate: number
  createdBy: string
  updatedDate: number
  updatedBy: string
  enable: boolean
}
export type CreateRelative = {
  title: string
  firstname: string
  lastname: string
  birthday: moment.Moment | string
  nationalId: string
  userId: number
  relativeTypeId: number
  id: number
}
export type RelativeType = {
  typeId: number
  typeName: string
  description: string
  maxNumber: number
  relativeList: RelativeInfo[]
}

export type BenefitTypeChoices = {
  id: number
  choicesName: BenefitType
  choicesDescription: string
  consentDetails: string
  defaultCoinValue: number
  taggedPrice: number
  selected: boolean
  isDefault: boolean
}
export type BenefitChoices = {
  id: number
  choicesName: string
  choicesDescription: string
  consentDetails: string
  defaultCoinValue: number
  taggedPrice: number
  selected: boolean
  isDefault: boolean
}
export type SelfBenefitsChoices = {
  id: number
  selfBenefitsCategoryId: number
  choicesName: string
  choicesDescription: string
  consentDetails: string
  defaultCoinValue: number
  createdDate: number
  createdBy: string
  updatedDate: number
  updatedBy: string
  enable: boolean
}

export enum BenefitsCategoryCode {
  SELF = 'SELF',
  SPOUSE_CHILD = 'SPOUSE_CHILD',
}

export type BenefitsCategoryWrapperList = {
  benefitsCategoryId: number
  benefitsCategoryName: string
  benefitsCategoryCode: BenefitsCategoryCode
  choices: BenefitChoices[]
}

export type RelativeBenefitRespond = {
  selectedSelfBenefitId: number
  selectedChoices: SelectedChoice[]
}

export type SelfBenefit = {
  id: number
  choicesName: string
  choicesDescription: string
  selectedCoinValue: number
  isSelected: boolean
  price: any
}

export type AllBenefit = {
  userLeaveBenefits: number
}

export type LeaveBenefitParams = {
  enrollmentId: number
  dayLeave: number
}

export type GetBenefitsByCategoryResponse = {
  id: number
  selfBenefitsId: number
  categoryName: string
  categoryIcon?: string
  probationAccess: boolean
  createdDate: number
  updatedDate: number
  createdBy: string
  updatedBy: string
  enable: boolean
  selfBenefitsChoicesList: SelfBenefitsChoices[]
}

export type GetAllSelfBenefitsResponse = BenefitsCategoryWrapperList[]
export type GetAllRelativeBenefitsResponse = SelfBenefit[]
export type GetEnrollmentPeriodResponse = {
  benefitYearId: number
  startEnrollmentDate: number
  endEnrollmentDate: number
  startPeriodDate: number
  endPeriodDate: number
}

export type GetRelativesResponse = RelativeType[]
export type GetRelativeResponse = RelativeInfo

export type SelfBenefitsSendParams = {
  benefitYearId: number
  selectChoiceId: any
}

export type RelativeBenefitsSendParams = {
  benefitYearId: number
  relativeId: number
  selectedChoices: {
    relativeBenefitsCategoryId: number
    relativeBenefitsChoicesId: number
  }[]
}

export type GetBenefitsResponse = {
  selfBenefitsId: number | null
  selfBenefitsName: string | null
  totalCost: number
  icon: string | null
  userSelectedWrapperList: UserSelectedBenefits[]
  // relativesWrapper: RelativeBenefitsResponse
  // selfBenefitWrapper: SelfBenefitsResponse
  // preSelectedClaimsWrapper2: LifeStyleBenefitsResponse
  // totalAvailableCoins: number
  // totalUsedCoins: number
  // totalEarnedCoins: number
  // totalRemainingCoins: number
  // userPointBalance: number
  // selfBenefitsChoice: SelfBenefitsChoices
}

export type SelfBenefitsResponse = {
  totalSelectedCoin: number
  selfBenefitsWrapperList: SelfBenefitsList[]
}

export type RelativeBenefitsResponse = {
  totalSelectedCoin: number
  relativeWrapperList: RelativeWrapperList[]
}

export type LifeStyleBenefitsResponse = {
  totalSelectedCoin: number
  preSelectedClaimsWrapperList: LifeStyleWrapperList[]
}

export type LifeStyleWrapperList = {
  claimId: number
  benefitsYearId: number
  preSelectedCredits: number
  availableCredits: number
  claimName: string
  claimDetails: string
}

export type RelativeWrapperList = {
  relativeId: number
  relativeName: string
  relativeDOB: number
  relativeTypeName: string
  totalSelectedCoin: number
  relativeBenefitsWrapperList: RelativeBenefitsList[]
}

export type RelativeBenefitsList = {
  relativeBenefitsId: number
  relativeBenefitsName: string
  totalCosts: number
  relativeSelectedWrapperList: UserSelectedBenefits[] | null
}

export type SelfBenefitsList = {
  selfBenefitsId: number | null
  selfBenefitsName: string | null
  totalCost: number
  icon: string | null
  userSelectedWrapperList: UserSelectedBenefits[]
}

export type UserSelectedBenefits = {
  id: number
  categoryId?: number
  choicesId?: number
  userId?: number
  selectedCoinValue: number
  categoryName: string
  choiceName?: string
  choicesDescription?: string
  categoryIcon?: string
}

export type DeleteRelativeBenefitsParams = {
  benefitYearId: number
  relativeId: number
}

export type GetGroupNumberResponse = {
  insuranceGroup: string
  healthCheckupGroup: string
}

export enum BenefitType {
  Traditional = "Traditional",
  FLEXi = "FLEXi",
}

export type SelfBenefitsType = {
  id: number;
  benefitYearId: number;
  selfBenefitsName: BenefitType;
  description: string;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  enable: boolean;
  icon: null;
}

export type GetAllSelfBenefitsTypeResponse = SelfBenefitsType[]

export type GetSelectedBenefitsResponse = {
  selectedSelfBenefitId: number;
  selectedChoices: SelectedChoice[];
}

export type SelectedChoice = {
  selfBenefitsId: number;
  selfBenefitsName: string;
  selfBenefitsCategoryId: number;
  selfBenefitsCategoryName: string;
  selfBenefitsChoiceId: number;
  selfBenefitsChoiceName: string;
  selfBenefitsChoice: SelfBenefitsChoice;
  selectedPoint: number;
  selfBenefitsCategoryCode: BenefitsCategoryCode
}

export type SelfBenefitsChoice = {
  id: number;
  selfBenefitsCategoryId: number;
  choicesName: string;
  choicesDescription: string;
  defaultCoinValue: number;
  consentDetails: string;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  enable: boolean;
  code: string;
}

export type SendConsentParams = {
  isConsent: boolean;
  benefitYearId: number;
}

export type SelectSelfBenefitTypeParams = {
  benefitYearId: number;
  selfBenefitTypeId: number;
}