import styled from '@emotion/styled'
import { ModalProps } from 'antd/lib/modal'
import { useCallback, useMemo } from 'react'
import Button from '../../components/common/Button'
import Image, { ImageNameKeys } from '../../components/common/Image'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
    padding: 24px;

  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    padding-top: 24px;

    .header {
      padding-right: 32px;
    }
  }
`
const Header = styled.div`
  text-align: center;
  margin-bottom: 24px;
`
const Content = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
`
const TextCenter = styled(Text)`
  text-align: center;
`
const ConfirmButton = styled(Button)`
  width: 200px;
`
const Footer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.WHITE};
  padding: 16px;
  border-radius: 5px;
  row-gap: 8px;
`

type ModalHealthInsuranceProps = {
  icon?: ImageNameKeys
  errorMessage: string
  onConfirmClick: () => void
  onCloseClick: () => void
} & Required<Pick<ModalProps, 'visible'>>

const ModalHealthInsurance = (props: ModalHealthInsuranceProps) => {
  const { icon = null, errorMessage, visible, onConfirmClick, onCloseClick } = props

  const onCancel = useCallback(() => {
    onCloseClick()
  }, [onCloseClick])

  const onConfirm = useCallback(() => {
    onConfirmClick()
  }, [onConfirmClick])

  const errorMsg = useMemo(() => errorMessage.split("/n") || [], [errorMessage])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      style={{
        padding: 0,
      }}
      hideFooter
      hideCloseIcon
    >
      <Layout>
        <Header>
          {icon && <Image imageName={icon} width={56} height={56} />}
        </Header>
        <Content>
          {errorMsg.length > 0 && errorMsg.map((val, i) =>
            <TextCenter key={i} size={20} type="Bold">{val}</TextCenter>
          )}
        </Content>
        <Footer>
          <ConfirmButton onClick={onConfirm}>
            ตกลง
          </ConfirmButton>
          <ConfirmButton ghost fontColor={color.CHOICES_PRIMARY} onClick={onCancel}>
            ยกเลิก
          </ConfirmButton>
        </Footer>
      </Layout>
    </Modal>
  )
}

export default ModalHealthInsurance