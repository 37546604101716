import styled from '@emotion/styled'
import { Radio, Space } from 'antd'
import { isEqual, some, sum, uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Button from '../../../../components/common/Button'
import Card from '../../../../components/common/Card'
import FooterContent from '../../../../components/FooterContent'
import color from '../../../../constant/colors'
import {
  BenefitsCategoryWrapperList,
  BenefitChoices,
  SelfBenefit,
} from '../../../../services/enrollment/enrollment-types'
import { mobile } from '../../../../utils/responsive-helper'
import BenefitsCategory from '../BenefitsCategory'
import { SelectedChoices } from './EditSelfBenefits'

export const ContentSpace = styled(Space)`
  width: 100%;

  ${mobile} {
    padding: 0px 16px;
  }
`

export const Layout = styled.div`
  margin-bottom: 40px;
`

export const BreadcrumbLayout = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  ${mobile} {
    margin: 24px 16px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`

export const Header = styled(Row)`
  justify-content: center;
  width: 100%;
  margin-bottom: 27px;
`

export const UserName = styled(Row)`
  flex-wrap: wrap;
  margin-bottom: 16px;

  .username {
    margin-right: 6px;
  }

  > *:not(:last-of-type) {
    margin-right: 8px;
  }
`

export const Content = styled(Card)`
  border: none;
  box-shadow: none !important;
  .icon-enroll {
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ContentHeader = styled(Row)`
  padding: 16px 0px;
  justify-content: center;
  background-color: ${color.PRIMARY};
  border-radius: 8px;
  transform: translate3d(0px, 10px, 0px);
`

export const ChoicesRadioTitle = styled(Row)`
  padding: 15px 24px;
  background-color: ${color.BACKGROUND_LIGHT};

  .icon-insurance-title {
    margin-right: 12px;
    > {
      width: 32px;
      height: 32px;
    }
  }
`

export const RedeemButton = styled(Button)`
  width: 265px;
  height: 45px;
  margin-left: auto;
  ${mobile} {
    margin: auto;
    width: 100%;
  }
`

export const SummaryPoint = styled(Row)`
  flex-wrap: wrap;
  > *:not(:last-child) {
    margin-right: 8px;
  }

  ${mobile} {
    margin-bottom: 16px;
  }
`

export const FooterContentLayout = styled(FooterContent)`
  .left {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  ${mobile} {
    flex-flow: column;
    .right {
      width: 100%;
    }
  }
`
export const BenefitsCategoryStyled = styled(BenefitsCategory)`
  padding: 20px;
  background-color: ${color.BACKGROUND_LIGHT};
  .title-layout {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const PeriodTabsLayout = styled(Radio.Group) <{ isNextPeriod: boolean }>`
  width: 100%;
  display: flex;
  margin-bottom: 32px;

  .ant-radio-button-wrapper:first-child {
    border-radius: ${(props) => (props.isNextPeriod ? '8px 0 0 8px' : '8px 8px 8px 8px')};
    width: ${(props) => (props.isNextPeriod ? null : '100%')};
  }

  .ant-radio-button-wrapper:nth-child(2) {
    border-radius: 0 8px 8px 0;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: ${color.PRIMARY} !important;
    border-color: ${color.PRIMARY} !important;
  }
`
export const PeriodTabButton = styled(Radio.Button)`
  width: calc(100% / 2);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
`

export const useSelectBenefitsHelper = (benefit?: BenefitsCategoryWrapperList, userPoint = 0, isFetched = true) => {
  const [oldTotalBenefitsSelectedPoint, setOldTotalBenefitsSelectedPoint] = useState(0)
  const [totalBenefitsSelectedPoint, setTotalBenefitsSelectedPoint] = useState(0)
  const [remainPoint, setRemainPoint] = useState(0)

  const [oldSelectedChoices, setOldSelectedChoices] = useState<SelectedChoices[]>()
  const [selectedChoices, setSelectedChoices] = useState<SelectedChoices[]>([])

  const onSelectedChoices = useCallback(
    (benefitsChoices: BenefitChoices) => {
      const { id } = benefitsChoices
      setSelectedChoices((prev) => {
        const benefitsChoicesIsExists = some(prev, (v) => v.id === id)
        if (!benefitsChoicesIsExists) {
          prev = []
          prev.push({
            ...benefitsChoices,
          })
        }
        const _selectedChoices = uniqBy(prev, (v) => `${v.id}`)
        const _totalSelectedPoint =
          sum(
            _selectedChoices.map((v) => {
              const point = v.taggedPrice || 0
              return v.selected ? point : point
            }),
          ) || 0
        const _remainPoint = userPoint + oldTotalBenefitsSelectedPoint - _totalSelectedPoint
        setTotalBenefitsSelectedPoint(_totalSelectedPoint)
        setRemainPoint(_remainPoint)

        return _selectedChoices
      })
    },
    [oldTotalBenefitsSelectedPoint, userPoint],
  )

  // const isValuesNotChange = useMemo(() => {
  //   const oldSelected = oldSelectedChoices
  //     ? oldSelectedChoices.map((v) => `${v.benefitsCategoryId}-${v.id}`)
  //     : []
  //   const newSelected = selectedChoices.map((v) => `${v.benefitsCategoryId}-${v.id}`)
  //   const isLengthEqual = oldSelected.length === newSelected.length
  //   const isSelecteIdEqual = isEqual(oldSelected.sort(), newSelected.sort())
  //   return isLengthEqual && isSelecteIdEqual
  // }, [oldSelectedChoices, selectedChoices])

  useEffect(() => {
    if (isFetched) {
      setSelectedChoices(() => {
        const selecteds = benefit
          ? benefit?.choices.reduce(
            (acc: SelectedChoices[], currentValue) => {
              if (currentValue.selected) {
                acc.push({
                  ...currentValue
                })
              }
              return acc
            },
            [],
          )
          : []

        const _totalSelectedPoint = sum(
          selecteds.map((v) => v.taggedPrice || 0),
        )
        setOldSelectedChoices((prev) => (!prev ? selecteds : prev))
        setOldTotalBenefitsSelectedPoint(_totalSelectedPoint)
        setTotalBenefitsSelectedPoint(_totalSelectedPoint)
        setRemainPoint(userPoint)
        return selecteds
      })
    }
  }, [benefit, isFetched, userPoint])

  return {
    // isValuesNotChange,
    remainPoint,
    oldTotalBenefitsSelectedPoint,
    totalBenefitsSelectedPoint,
    selectedChoices,
    onSelectedChoices,
  }
}
