import styled from '@emotion/styled'
import Breadcrumb from '../../../components/common/Breadcrumb'
import Card from '../../../components/common/Card'
import Text from '../../../components/common/Text'
import Welfare from './Welfare'
import FooterContent from '../../../components/FooterContent'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import Button from '../../../components/common/Button'
import { useCallback, useMemo } from 'react'
import * as paths from '../../../constant/path'
import { useHistory } from 'react-router'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { convertDateFormatTH, DateFormat } from '../../../utils/dateFormat'
import { PointCardBenefit } from '../../../components/PointCard'
import { useCanEditable } from '../../../utils/helper'
import color from '../../../constant/colors'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux-store'

const ButtonStyle = styled(Button)`
  width: 267px;
`

const Header = styled.div`
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
`

const TextFormat = styled.div`
  text-align: start;
  margin-bottom: 14px;

  ${mobile} {
    margin: 16px;
  }
`

const CustomStyleCard = styled(Card)`
  display: flex;
  /* position: relative; */
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`

const LayoutBodyWelware = styled.div`
  text-align: start;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
`
const BreadcrumbContainer = styled.div`
  ${mobile} {
    margin: 24px 16px;
  }
`
const ConditionLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const ConditionTitle = styled(Text)`
  text-decoration: underline;
`
const ConditionListLayout = styled.ul`
  text-align: left;
`
const ConditionListItem = styled.li`
  list-style-type: disc;
`

const HealthCheckupDetail = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const screen = useScreen()
  const { isMobile } = screen
  const history = useHistory()

  // const {
  //   benefitYearId,
  //   startDate: benefitStartDate,
  //   endDate: benefitEndDate,
  //   benefitYear,
  // } = useSelector((state: RootState) => state.enrollment.enrollment)

  // const canEditable = useCanEditable(benefitStartDate, benefitEndDate)

  // const startDate = useMemo(() => {
  //   if (dayjs(benefitStartDate).isSame(benefitEndDate, 'year') && dayjs(benefitStartDate).isSame(benefitEndDate, 'month')) {
  //     return dayjs(benefitStartDate).locale(language).format('D')
  //   } else if (dayjs(benefitStartDate).isSame(benefitEndDate, 'year')) {
  //     return dayjs(benefitStartDate).locale(language).format('D MMMM')
  //   } else {
  //     return dayjs(benefitStartDate).locale(language).format('D MMMM BBBB')
  //   }
  // }, [benefitStartDate, benefitEndDate, language])
  // const endDate = dayjs(benefitEndDate).locale(language).format('D MMMM BBBB')

  // const getBenefits = useCallback(() => {
  //   history.push(
  //     paths.healthCheckupBenefits({
  //       routeParam: { benefitYearId: benefitYearId },
  //     }),
  //   )
  // }, [benefitYearId, history])

  // return (
  //   <>
  //     <BreadcrumbContainer>
  //       <Breadcrumb
  //         overide={(paths) => {
  //           paths.unshift({ label: 'Home', path: '/' })
  //           paths[1].label = t('app.breadcrumb.myWelFare')
  //           return paths
  //         }}
  //       />
  //     </BreadcrumbContainer>
  //     <Header>
  //       <Text size={28} type="Bold" color={color.PRIMARY}>
  //         {t(`healthCheckup.titleHealthCheckup`)}
  //       </Text>
  //       <Text size={28} type="Bold" color={color.PRIMARY}>
  //         {startDate} - {endDate}
  //       </Text>
  //     </Header>
  //     <ConditionLayout>
  //       <ConditionTitle>{t('healthCheckup.condition.header')}</ConditionTitle>
  //       <ConditionListLayout>
  //         <ConditionListItem>
  //           <Text>
  //             {t('healthCheckup.condition.list1')}
  //           </Text>
  //         </ConditionListItem>
  //         <ConditionListItem>
  //           <Text>
  //             {t('healthCheckup.condition.list2')}
  //           </Text>
  //         </ConditionListItem>
  //         <ConditionListItem>
  //           <Text>
  //             {t('healthCheckup.condition.list3')}
  //           </Text>
  //         </ConditionListItem>
  //         <ConditionListItem>
  //           <Text>
  //             {t('healthCheckup.condition.list4')}
  //           </Text>
  //         </ConditionListItem>
  //       </ConditionListLayout>
  //     </ConditionLayout>
  //     <TextFormat>
  //       <Text size={26} type="Bold">
  //         {/* เหรียญของปี {benefitYear} */}
  //         {t(`healthCheckup.coinOFYear`)}
  //       </Text>
  //     </TextFormat>
  //     {benefitYearId && <PointCardBenefit benefitYearId={benefitYearId} />}
  //     <TextFormat>
  //       <Text size={26} type="Bold" style={{ textAlign: 'start' }}>
  //         {t(`healthCheckup.insuranceList`)}
  //       </Text>
  //     </TextFormat>
  //     <CustomStyleCard>
  //       <LayoutBodyWelware>
  //         <Welfare iconPath={'iconUser'} title={t('healthCheckup.myHealthCheckup')} descriptions={[]}></Welfare>
  //         {/* <Welfare iconPath={'iconUser'} title={'ประกันของครอบครัว'} descriptions={[]}></Welfare> */}
  //         {/* <Welfare
  //           iconPath={'iconUserGroup'}
  //           title={'ประกันเพิ่มเติม ให้ครอบครัว'}
  //           descriptions={[
  //             'สำหรับ คู่สมรส บุตร บิดา มารดา เท่านั้น',
  //             'ต้องส่งเอกสารแสดงความสัมพันธ์ให้กับ HR ตรวจสอบด้วย',
  //           ]}
  //         ></Welfare>
  //         <Welfare
  //           iconPath={'iconChange'}
  //           title={'สวัสดิการอื่นๆ'}
  //           descriptions={[
  //             // 'แลกเปลี่ยนวันลา',
  //             'สวัสดิการเพื่อไลฟ์สไตล์',
  //           ]}
  //         ></Welfare> */}
  //       </LayoutBodyWelware>
  //     </CustomStyleCard>
  //     <FooterContent
  //       style={{
  //         height: 80,
  //         display: 'flex',
  //         justifyContent: isMobile ? 'center' : 'flex-end',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <ButtonStyle onClick={getBenefits}>{t('healthCheckup.nextButton')}</ButtonStyle>
  //     </FooterContent>
  //   </>
  // )
  return (<></>)
}

export default HealthCheckupDetail
