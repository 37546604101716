import styled from '@emotion/styled'
import { DatePicker as AntdDatePicker } from 'antd'
import { DatePickerProps as AntdDatePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import locale from 'antd/lib/date-picker/locale/th_TH'
import color from '../../constant/colors'
import { CSSProperties, useCallback } from 'react'
import { InputLabel } from './Input'
import dayjs, { Dayjs } from 'dayjs'

const DatePickerLayout = styled.div``

const CustomAntdDatePicker = styled(AntdDatePicker)`
  padding: 8px 8px;
  border-radius: 4px;
  border-color: ${color.DIM_GRAY};
  font-family: IBMPlexSansThai-Medium;
  font-size: 14x;
  line-height: 18px;
  height: 100%;
  width: 100%;
`

const dateFormat = 'DD/MM/YYYY'
type LabelType = "Medium" | "Bold" | "SemiBold" | "Text" | "Regular" | undefined

export type DatePickerProps = {
  label?: string
  labelType?: LabelType
  style?: CSSProperties
  datePickerStyle?: CSSProperties
  minDate?: Dayjs
  maxDate?: Dayjs
  labelSize?: number
} & Omit<AntdDatePickerProps, 'disabledDate'>

const DatePicker = (props: DatePickerProps) => {
  const {
    value,
    onChange,
    label,
    labelType,
    style,
    datePickerStyle,
    minDate,
    maxDate,
    onBlur,
    className,
    labelSize = 14,
    ...restProps
  } = props
  const disabledDate = useCallback(
    (current) => {
      const currentDate = dayjs(current).endOf('day')
      if (
        (current && minDate && currentDate < minDate.endOf('day')) ||
        (maxDate && currentDate > maxDate.endOf('day'))
      ) {
        return true
      } else {
        return false
      }
    },
    [maxDate, minDate],
  )

  return (
    <DatePickerLayout style={{ ...style }} className={className}>
      {label && <InputLabel size={labelSize} type={labelType}>{label}</InputLabel>}
      <CustomAntdDatePicker
        value={moment(value).isValid() ? moment(value) : undefined}
        onChange={onChange}
        locale={locale}
        format={dateFormat}
        style={datePickerStyle}
        disabledDate={disabledDate}
        {...restProps}
      />
    </DatePickerLayout>
  )
}

export default DatePicker
