import { Switch, Route, Redirect } from 'react-router'
import * as paths from '../../constant/path'
import EClaimComplete from '../EClaimDetail/EClaimComplete'
import EClaimHistoryDetail from '../EclaimDetailHistory'
import EClaimHistory from './EClaimHistory'
import EClaimSummary from '../EClaimDetail/EClaimSummary'
import EClaimDetail from '../EClaimDetail'
import EClaim from '.'
import { ProtectedPeriodRoute } from '../../routes/protected'

import { useGetAllSelfBenefitsType, useGetAllSelectedBenefits, useGetEnrollmentActivePeriod } from '../../services/enrollment/enrollment-query'
import { BenefitType } from '../../services/enrollment/enrollment-types'
import { useMemo } from 'react'
import Loading from '../../components/common/Loading'
import { useLocation } from 'react-router'
import * as path from '../../constant/path'


const EClaimRoute = () => {
  const location = useLocation()
  const { data: activePeriod } = useGetEnrollmentActivePeriod()
  const { data, isLoading: isLoadingGetAllSelfBenefitsType } = useGetAllSelfBenefitsType(activePeriod?.benefitYearId)
  const { data: selectedBenefits, isLoading: isLoadingGetAllSelectedBenefits } = useGetAllSelectedBenefits(activePeriod?.benefitYearId)

  const isFlexi = useMemo(() => {
    return data?.find(item => item.selfBenefitsName === BenefitType.FLEXi)?.id === selectedBenefits?.selectedSelfBenefitId
  }, [selectedBenefits, data])

  const isEclaimPath = useMemo(() => {
    return Boolean(location.pathname.includes(paths.eClaim()))
  }, [location])

  if (!isEclaimPath) return <></>

  return (
    <Loading loading={isLoadingGetAllSelfBenefitsType || isLoadingGetAllSelectedBenefits}>
      {isFlexi ? (
        <Switch>
          <ProtectedPeriodRoute path={paths.eClaimCompleted()} component={EClaimComplete} />
          <ProtectedPeriodRoute path={paths.eClaimHistoryDetail()} component={EClaimHistoryDetail} />
          <ProtectedPeriodRoute path={paths.eClaimHistory()} component={EClaimHistory} />
          <ProtectedPeriodRoute path={paths.eClaimSummary()} component={EClaimSummary} />
          <ProtectedPeriodRoute path={paths.eClaimDetail()} component={EClaimDetail} />
          <ProtectedPeriodRoute exact path={paths.eClaim()} component={EClaim} />
        </Switch>
      ) : (
        <Redirect to={paths.home()} />
      )}
    </Loading>
  )
}

export default EClaimRoute