import styled from '@emotion/styled'
import { hideScrollStyle, mobile } from '../../utils/responsive-helper'
import color from '../../constant/colors'
import * as paths from '../../constant/path'
import { useRouter } from '../../utils/helper'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background-color: ${color.BACKGROUND};
  position: relative;
  ${hideScrollStyle}
  .layout-content {
    ${hideScrollStyle}
  }
`
const ContentLayout = styled.div<{
  isHiddenHeader: boolean
  isHiddenFooter: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: calc(100vh - 80px - 90px);
  max-width: 1172px;
  padding: 0 16px;
  ${mobile} {
    min-height: ${({ isHiddenHeader, isHiddenFooter }) =>
    `calc(100vh - ${isHiddenHeader ? '0px' : '90px'} - ${isHiddenFooter ? '0px' : '105px'})`};
    padding: 0px;
  }
`
const Content: React.FC<{ id: string }> = (props) => {
  const { children, ...rest } = props
  const { pathname } = useRouter()
  const isHiddenHeader =
    pathname.startsWith(`${paths.product()}/`) || pathname.startsWith(`${paths.shopUser()}/`)
  const isHiddenFooter =
    pathname.startsWith(`${paths.product()}/`) || pathname.startsWith(`${paths.shopUser()}/`)

  return (
    <Layout {...rest}>
      <ContentLayout
        isHiddenHeader={isHiddenHeader}
        isHiddenFooter={isHiddenFooter}
        className="layout-content"
      >
        {children}
      </ContentLayout>
    </Layout>
  )
}

export default Content
