import { SelfBenefitsChoice } from "../enrollment/enrollment-types";

export type Relative = {
  id: number;
  firstname: string;
  lastname: string;
  birthday: number;
  idCard: string;
  education: RelativeEducationType;
  status: RelativeStatus;
  statusUpdatedDate: number;
  statusUpdatedBy: string;
  userId: number;
  relativeType: RelativeType;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  enable: boolean;
  documentTypes: RelativeDocumentType[]
}

export type RelativeEducationType = {
  id: number
  enable: boolean
  name: string
}

export type RelativeList = Relative[]
export type RelativeBenefitList = RelativeBenefit[]

export type RelativeBenefit = {
  id: number
  firstname: string
  lastname: string
  birthday: number
  idCard: string
  status: string
  statusUpdatedDate: number
  statusUpdatedBy: string
  reason: string
  remark: string
  userId: number
  userInfo: UserInfo
  relativeType: RelativeType
  education: Education
  relativeBenefitChoice: SelfBenefitsChoice
  documentTypes: RelativeDocumentType[]
  createdDate: number
  createdBy: string
  updatedDate: number
  updatedBy: string
  enable: boolean
}

export type UserInfo = {
  userId: number
  empId: string
  firstname: string
  lastname: string
  firstnameEn: string
  lastnameEn: string
  phoneNumber: string
  email: string
}

export type Education = {
  id: number
  name: string
  enable: boolean
}

export type RelativeType = {
  id: number
  code: string
  name: string
  maxNumber: number
  description: string
  createdDate: moment.Moment
  createdBy: string
  updatedDate: moment.Moment
  updatedBy: string
  enable: boolean
}

export type RelativeTypeList = RelativeType[]

export type RelativeDocumentType = {
  id: number
  name: string
  enable: boolean
  description: string
  files: UploadFile[]
}

export type UploadFile = {
  id: number
  name: string
  fileType: string
}

export type EducationType = {
  id: number
  name: string
  isPassEducation: boolean
  enable: boolean
}

export type EducationTypeList = EducationType[]

export type RelativeFormParams = {
  id?: number
  name: string
  relativeTypeId?: number
  birthDate: moment.Moment
  idCard: string
  educationId?: number
  files?: string[]
}

export type CreateRelativeParams = {
  id?: number
  userId?: number
  firstname: string
  lastname: string
  relativeTypeId?: number
  birthday: string
  idCard: string
  educationId?: number
  files?: UploadFile[] | string[]
}

export type CheckRelativeParams = {
  status: string
  message: string
}

export type CheckCurrentProrate = {
  id: number
  startMonth: number
  defaultCoin: number
  coinType: string
}

export type DocListTypeList = DocumentType[]

export type DocumentType = {
  id: number
  isRequired: boolean
  relativeTypeId: number
  documentType: FileType
}

export type FileType = {
  id: number
  name: string
  description: string
  enable: boolean
  files: UploadFile[]
}

export enum RelativeTypeCode {
  CHILD = 'CHILD',
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  MARRIED_COUPLE = 'MARRIED_COUPLE',
}
export enum RelativeStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}