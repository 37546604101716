import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router'

import AppLayout from '../components/app-layout'
import * as paths from '../constant/path'
import Home from '../pages/Home'
import News from '../pages/News'
import NewsDetail from '../pages/NewsDetail'
import Play from '../pages/Play'
import PlayDetail from '../pages/PlayDetail'
import Shop from '../pages/Shop'
import ShopCategory from '../pages/Shop/ShopCategory'
import ShopRewardClaim from '../pages/ShopRewardClaim'
// import ShopCart from '../pages/Shop/ShopCart'
// import ShopUserCoin from '../pages/Shop/ShopUserCoin'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Order from '../pages/Order'
import Profile from '../pages/Profile'
import Promotion from '../pages/Promotion'
import CouponDetail from '../pages/Promotion/CouponDetail'
import PromotionCategoryDetail from '../pages/Promotion/PromotionCategoryDetail'
import Product from '../pages/Shop/Product'
import TermAndCondition from '../pages/TermAndCondition'
// import Product from '../pages/Product'
// import ProductDetail from '../pages/Product/ProductDetail'
// import ProductDetailDescription from '../pages/Product/ProductDetailDescription'
import ShopBrand from '../pages/Shop/ShopBrand'
// import Notification from '../pages/Home/Notification'
import AppShortcut from '../pages/AppShortcut'
import Enrollment from '../pages/Enrollment'
import EnrollmentTraditionalSummary from '../pages/Enrollment/Benefit/TraditionalSummary'
import EnrollmentComplete from '../pages/Enrollment/Complete'
import Notification from '../pages/Notification'
import PointHistory from '../pages/PointHistory'
// import EditSelfBenefits from '../pages/Enrollment/Benefits/EditBenefits/EditSelfBenefits'
// import EditRelativeBenefits from '../pages/Enrollment/Benefits/EditBenefits/EditRelativeBenefits'
// import EnrollmentLeave from '../pages/Enrollment/Benefits/EnrollmentLeave'
import EClaimRoute from '../pages/EClaim/EClaimRoute'
import EClaim from '../pages/EClaim'
import EClaimDetail from '../pages/EClaimDetail'
import EClaimSummary from '../pages/EClaimDetail/EClaimSummary'
import Benefit from '../pages/Enrollment/Benefit'
// import EnrollmentInsuranceFamily from '../pages/Enrollment/Benefits/EnrollmentInsuranceFamily'
// import RelativeForm from '../pages/Enrollment/Benefits/EnrollmentInsuranceFamily/RelativeForm'
import EClaimHistory from '../pages/EClaim/EClaimHistory'
import EClaimComplete from '../pages/EClaimDetail/EClaimComplete'
// import EditWellBeing from '../pages/Enrollment/Benefits/WellBeing/EditWellBeing'
// import BenefitWellBeing from '../pages/Enrollment/Benefits/WellBeing'
import EClaimHistoryDetail from '../pages/EclaimDetailHistory'
import HeathCheckupDetail from '../pages/HealthCheckup/HealthCheckupDetail'
import HealthProfile from '../pages/HealthProfile'
import BloodCell from '../pages/HealthProfile/bloodCell'
import HealthTips from '../pages/HealthTips'
import WellBeing from '../pages/WellBeing'

import HealthInsurance from '../pages/HealthInsurance'
import HealthInsuranceCompleted from '../pages/HealthInsurance/HealthInsuranceCompleted'
import HealthInsurancePlan from '../pages/HealthInsurance/HealthInsurancePlan'
import HealthInsuranceRelative from '../pages/HealthInsurance/HealthInsuranceRelative'
import HealthTipsDetail from '../pages/HealthTipsDetail'
import OPD from '../pages/OPD'
import OPDDetail from '../pages/OPDDetail'
import OpdCompleted from '../pages/OPDDetail/OpdComplete'
import OpdSummary from '../pages/OPDDetail/OpdSummary'
import Relative from '../pages/Relative'
import RelativeCreate from '../pages/RelativeCreate'
import RelativeCompleted from '../pages/RelativeDetail/RelativeCompleted'
import RelativeDetail from '../pages/RelativeDetail/RelativeDetail'
import OpdHistory from '../pages/OPD/OpdHistory'
import OpdDetailHistory from '../pages/OpdDetailHistory'
import React, { useState, useMemo, useEffect } from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import Button from '../components/common/Button'
import Modal from '../components/common/Modal'
import Image from '../components/common/Image'
import Text from '../components/common/Text'
import mainImage from '../assets/images/base/mainModal.png'
import Loading from '../components/common/Loading'
import { useGetAllSelfBenefitsType, useGetAllSelectedBenefits, useGetEnrollmentActivePeriod } from '../services/enrollment/enrollment-query'
import { BenefitType } from '../services/enrollment/enrollment-types'
import { useServicePeriod } from '../services/status/status-query'
import { StatusCode } from '../services/status/status-typed'

const ContentModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`

const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`

const BenefitSelectAButton = styled(Button)`
  width: min-content;
  width: 100%;
  max-width: 100%;
`

type ProtectedPeriodRouteProps = {
  component: any
  path: string
  exact?: boolean
}

export const ProtectedPeriodRoute: React.FC<ProtectedPeriodRouteProps> = ({ component: Component, path, ...rest }) => {
  const history = useHistory()
  const location = useLocation()
  const [modal, setModal] = useState(false)
  const { data: activePeriod } = useGetEnrollmentActivePeriod()
  const { data, isLoading: isLoadingGetAllSelfBenefitsType } = useGetAllSelfBenefitsType(activePeriod?.benefitYearId)
  const { data: selectedBenefits, isLoading: isLoadingGetAllSelectedBenefits } = useGetAllSelectedBenefits(activePeriod?.benefitYearId)
  const { data: servicePeriod, isLoading: isLoadingServicePeriod } = useServicePeriod()

  useEffect(() => {
    if (servicePeriod?.code === StatusCode.ONLINE) {
      setModal(false)
    } else {
      setModal(dayjs().isBetween(dayjs(servicePeriod?.data?.startDate), dayjs(servicePeriod?.data?.endDate)))
    }
  }, [location.pathname, servicePeriod])

  const isModal = useMemo(() => {
    return modal && !Boolean(paths.eClaimHistory() === path || paths.eClaimHistoryDetail() === path)
  }, [modal, path])

  const isFlexi = useMemo(() => {
    return data?.find(item => item.selfBenefitsName === BenefitType.FLEXi)?.id === selectedBenefits?.selectedSelfBenefitId
  }, [selectedBenefits, data])

  return (
    <>
      {isLoadingGetAllSelectedBenefits || isLoadingGetAllSelfBenefitsType || isLoadingServicePeriod ? null : (
        <>
          <Modal
            visible={isModal}
            hideCloseIcon
            onCancel={() => setModal(true)}
            hideFooter={!isFlexi}
            footerComponent={
              isFlexi && (
                <FooterModal>
                  <BenefitSelectAButton
                    onClick={() => {
                      setModal(false)
                      history.push(paths.eClaimHistory())
                    }}
                  >
                    ดูประวัติการเบิก
                  </BenefitSelectAButton>
                </FooterModal>
              )
            }
          >
            <ContentModal>
              <Image src={mainImage} />
              <Text size={18} type="Bold">
                ไม่อยู่ในช่วงเวลาที่ให้บริการ
              </Text>
              <Text size={14} style={{
                textAlign: 'center'
              }}>
                ระบบจะเปิดให้ส่งเรื่องเบิกสวัสดิการอีกครั้ง <br />
                ในวันที่ {dayjs(servicePeriod?.data?.endDate).add(543, 'year').format('DD MMMM YYYY')} ขออภัยในความไม่สะดวก
              </Text>

            </ContentModal>
          </Modal>
          <Loading loading={isModal}>
            <Route
              {...rest}
              render={props => (
                <Component {...props} />
              )
              }
            />
          </Loading>
        </>
      )}
    </>
  );
}

export const ProtectedRoute = () => {
  return (
    <AppLayout>
      <Switch>
        <Redirect exact from="/" to={paths.home()} />
        {/* เพิ่ม route ตรงนี้ ไม่ต้องใส่ exact */}
        <ProtectedPeriodRoute path={paths.notification()} component={Notification} />
        <ProtectedPeriodRoute path={paths.app()} component={AppShortcut} />
        <ProtectedPeriodRoute path={paths.pointHistory()} component={PointHistory} />
        <ProtectedPeriodRoute path={paths.profile()} component={Profile} />
        <ProtectedPeriodRoute path={paths.about()} component={About} />
        <ProtectedPeriodRoute path={paths.contact()} component={Contact} />
        <ProtectedPeriodRoute path={paths.termAndCondition()} component={TermAndCondition} />
        <ProtectedPeriodRoute path={paths.app()} component={AppShortcut} />
        <ProtectedPeriodRoute path={paths.home()} component={Home} />
        <ProtectedPeriodRoute path={paths.newsDetails()} component={NewsDetail} />
        <ProtectedPeriodRoute path={paths.news()} component={News} />
        <ProtectedPeriodRoute path={paths.playDetail()} component={PlayDetail} />
        <ProtectedPeriodRoute path={paths.play()} component={Play} />

        {/* <Route
          path={[paths.claimCategoryReward(), paths.claimBrandReward()]}
          component={ShopRewardClaim}
        /> */}
        {/* <Route path={[paths.categoryReward(), paths.brandReward()]} component={Product} /> */}

        {/* <Route path={paths.shopCart()} component={ShopCart} />
        <Route path={paths.shopUserCoin()} component={ShopUserCoin} /> */}
        <ProtectedPeriodRoute path={paths.shopCategory()} component={ShopCategory} />
        <ProtectedPeriodRoute path={paths.shopBrand()} component={ShopBrand} />
        <ProtectedPeriodRoute path={paths.shop()} component={Shop} />
        {/* <Route path={paths.shop()} component={ComingSoon} /> */}
        <ProtectedPeriodRoute path={paths.promotion()} component={Promotion} />
        <ProtectedPeriodRoute path={paths.promotionCategoryDetail()} component={PromotionCategoryDetail} />
        <ProtectedPeriodRoute path={paths.couponDetail()} component={CouponDetail} />
        <ProtectedPeriodRoute path={paths.order()} component={Order} />
        {/* <Route path={paths.products()} component={Product} />
        <Route path={paths.productDetailDescription()} component={ProductDetailDescription} />
        <Route path={paths.productDetail()} component={ProductDetail} /> */}
        {/* Enrollment */}
        {/* <Route path={paths.enrollmentWellBeingEdit()} component={EditWellBeing} />
        <Route path={paths.enrollmentWellBeing()} component={BenefitWellBeing} /> */}
        {/* <Route path={paths.enrollmentWellBeingEdit()} component={EditWellBeing} />
        <Route path={paths.enrollmentWellBeing()} component={WellBeing} />
        <Route path={paths.insuranceFamilyForm()} component={RelativeForm} />
        <Route path={paths.enrollmentInsuranceFamily()} component={EnrollmentInsuranceFamily} />
        <Route path={paths.editRelativeBenefits()} component={EditRelativeBenefits} />
        <Route path={paths.editSelfBenefits()} component={EditSelfBenefits} />
        <Route path={paths.enrollmentLeave()} component={EnrollmentLeave} />
        <Route path={paths.benefits()} component={Benefits} /> */}
        {/* E-Claim */}
        {/* <Route path={paths.eClaimCompleted()} component={EClaimComplete} />
        <Route path={paths.eClaimHistoryDetail()} component={EClaimHistoryDetail} />
        <Route path={paths.eClaimHistory()} component={EClaimHistory} />
        <Route path={paths.eClaimSummary()} component={EClaimSummary} />
        <Route path={paths.eClaimDetail()} component={EClaimDetail} />
        <Route path={paths.eClaim()} component={EClaim} /> */}
        {/* <ProtectedPeriodRoute path={paths.eClaim()} component={EClaimRoute} /> */}
        {/* Relative */}
        <ProtectedPeriodRoute path={paths.relativeCompleted()} component={RelativeCompleted} />
        <ProtectedPeriodRoute path={paths.relativeAdd()} component={RelativeCreate} />
        <ProtectedPeriodRoute path={paths.relativeDetail()} component={RelativeDetail} />
        <ProtectedPeriodRoute path={paths.relative()} component={Relative} />
        {/* Enrollment */}
        <ProtectedPeriodRoute path={paths.enrollmentTraditionalSummary()} component={EnrollmentTraditionalSummary} />
        <ProtectedPeriodRoute path={paths.enrollmentComplete()} component={EnrollmentComplete} />
        <ProtectedPeriodRoute path={paths.benefit()} component={Benefit} />
        <ProtectedPeriodRoute path={paths.enrollment()} component={Enrollment} />
        {/* Health Insurance */}
        <ProtectedPeriodRoute path={paths.healthInsuranceCompleted()} component={HealthInsuranceCompleted} />
        <ProtectedPeriodRoute path={paths.healthInsuranceRelative()} component={HealthInsuranceRelative} />
        <ProtectedPeriodRoute path={paths.healthInsurancePlan()} component={HealthInsurancePlan} />
        <ProtectedPeriodRoute path={paths.healthInsurance()} component={HealthInsurance} />
        {/* Health Checkup */}
        <ProtectedPeriodRoute path={paths.healthCheckup()} component={HeathCheckupDetail} />

        {/* WellBeing */}
        <ProtectedPeriodRoute path={paths.healthTipsDetails()} component={HealthTipsDetail} />
        <ProtectedPeriodRoute path={paths.healthTips()} component={HealthTips} />

        <ProtectedPeriodRoute path={paths.heartCareDetails()} component={HealthTipsDetail} />
        <ProtectedPeriodRoute path={paths.heartCare()} component={HealthTips} />

        <ProtectedPeriodRoute path={paths.bloodCell()} component={BloodCell} />
        <ProtectedPeriodRoute path={paths.healthProfile()} component={HealthProfile} />
        <ProtectedPeriodRoute path={paths.wellBeing()} component={WellBeing} />

        {/* OPD */}
        <ProtectedPeriodRoute path={paths.opdCompleted()} component={OpdCompleted} />
        <ProtectedPeriodRoute path={paths.opdHistoryDetail()} component={OpdDetailHistory} />
        <ProtectedPeriodRoute path={paths.opdHistory()} component={OpdHistory} />
        <ProtectedPeriodRoute path={paths.opdSummary()} component={OpdSummary} />
        <ProtectedPeriodRoute path={paths.opdDetail()} component={OPDDetail} />
        <ProtectedPeriodRoute path={paths.opd()} component={OPD} />

        <ProtectedPeriodRoute path={paths.home()} component={Home} />

        {/* E-Claim */}
        <EClaimRoute />

        <Redirect to={paths.notFound()} />
      </Switch>
    </AppLayout>
  )
}
