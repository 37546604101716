import styled from '@emotion/styled'
import { Collapse } from 'antd'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import WellBeingCardScg from '../../components/WellBeingCardScg'
import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import config from '../../config'
import { default as colors } from '../../constant/colors'
import { eClaimDetail } from '../../constant/path'
import { useGetEClaimListScg } from '../../services/e-claim/e-claim-query'
import { EClaimItem } from '../../services/e-claim/e-claim-types'
import { useVisibility } from '../../utils/custom-hook'
import { mobile, mobileVerySmall, tablet, useScreen } from '../../utils/responsive-helper'
import { CollapseWellbeing } from './component'

const { Panel } = Collapse

const WellBeingContainer = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
  ${mobile} {
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const LayoutHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  user-select: none;
  background-color: ${colors.WHITE}

  :hover {
    cursor: pointer;
  }

  div:first-of-type {
    display: flex;
    column-gap: 10px;
  }

  ${tablet} {
    width: 100%;
  }
`
const DescriptionLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
const TextItems = styled(Text)`
  text-indent: 3em;
`
const TableCondition = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  width: 100%;

  .right {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .left {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`
const TitleLayout = styled.div<{ background: string }>`
  background: ${(props) => props.background};
  width: 100%;
  padding: 8px 16px;
  text-align: center;
`
const Title = styled(Text) <{ color: string }>`
  color: ${(props) => props.color};
`
const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`

// const data: EClaimListResponse = [{
//   claimTypeGroupId: 1,
//   claimList: [
//     {
//       claimTypeId: 1,
//       claimName: 'test',
//       claimTypeGroupId: 1,
//       claimTypeGroupName: 'test',
//       blcpClaimWrapperList: [
//         {
//           claimId: 1,
//           claimName: 'test',
//           claimIcon: 'test',
//           maxClaimablePrice: 1,
//           fixExchangeCoin: false,
//           availableAmount: 1,
//           claimDetail: 'test',
//           claimTypeId: 1,
//           sumClaimAmount: 1,
//           claimTypeGroupId: 1,
//           claimTypeName: 'test',
//           claimTypeGroupName: 'test'
//         }
//       ]
//     }
//   ]
// }]

const EClaimListComponent = () => {
  const { isMobile } = useScreen()
  const history = useHistory()
  const [visible, modalAction] = useVisibility()
  const [eClaimItem, setEClaimItem] = useState<EClaimItem>()

  const { data } = useGetEClaimListScg()

  const onClickItems = (eClaimItem: EClaimItem) => () => {
    setEClaimItem(eClaimItem)
    modalAction.show()
  }

  const onSubmit = useCallback(() => {
    if (eClaimItem?.id)
      history.push(eClaimDetail({
        routeParam: {
          eClaimId: eClaimItem.id,
        }
      }))
  }, [eClaimItem?.id, history])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        hideFooter
        style={{ maxWidth: 750 }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 24,
        }}>
          <Image imageName='iconAlertPrivacy' />
          <Text size={24}>
            {eClaimItem?.showDialogMessage?.includes('<!DOCTYPE html>') ? <div dangerouslySetInnerHTML={{ __html: eClaimItem?.showDialogMessage }}></div> : eClaimItem?.showDialogMessage}
          </Text>
          <Button onClick={onSubmit}>ตกลง</Button>
        </div>
      </Modal>

      <CollapseWellbeing
        style={{
          margin: isMobile ? '0px 16px 0px' : '0px 0px 0px',
        }}
      >
        {data?.map((val, index) => {
          const { name, claimList } = val
          return (
            <Panel
              key={index}
              style={{ marginBottom: '14px' }}
              header={
                <LayoutHeader>
                  <div>
                    <ImageStyle width={24} height={24} imageName={'IconInsurance'} />
                    <Text type="Bold" size={14}>
                      {name}
                    </Text>
                  </div>
                  <Text size={10} type='Medium' color={'#666666'}>ใช้วงเงิน FLEXi Coin ในการเบิก</Text>
                </LayoutHeader>
              }
            >
              <div style={{ backgroundColor: '#ECECEC', padding: 16 }}>
                <WellBeingContainer isMobile={isMobile}>
                  {claimList?.map((item: EClaimItem) => {
                    return (
                      <WellBeingCardScg
                        title={item.nameEn}
                        description={item.name}
                        icon={item.icon && `${config.apiHost}/files${item.icon}`}
                        maxClaimablePrice={item.maxClaimableLimit}
                        availableAmount={item.allowedAmount}
                        onClick={onClickItems(item)}
                      />
                    )
                  })}
                </WellBeingContainer>
              </div>
            </Panel >

          )
        })}
      </CollapseWellbeing >
    </>
  )
}

export default EClaimListComponent
