import styled from '@emotion/styled'
import { CSSProperties, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { useRouter } from '../../utils/helper'
import Text from './Text'
import color from '../../constant/colors'
import IconNext from '../../assets/images/base/icons-bar-next-normal-18-px.svg'

const customLabel: { [key: string]: string } = {}

type BreadcrumbProps = {
  overide?: (paths: ItemProps[]) => ItemProps[]
  style?: CSSProperties
  className?: string
}

export type ItemProps = {
  label: string
  path: string
}

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  .breadcrumb-item {
    user-select: none;
    :not(:last-of-type):hover {
      cursor: pointer;
      color: ${color.FONT_LIGHT_GRAY};
    }
    :last-of-type {
      font-family: 'IBMPlexSansThai-Bold';
    }
  }
`
const Img = styled.img`
  margin: 0 8px;
`

const makePaths = (pathname: string) => {
  let p = pathname.split('/').filter((value) => !!value)
  return p.reduce((result, rawLabel, i) => {
    const label =
      customLabel[rawLabel] ||
      rawLabel.slice(0, 1).toUpperCase() + rawLabel.slice(1).replace(/_|-/g, ' ')
    let path = `/${rawLabel}`
    if (i !== 0) {
      path = `${result[i - 1].path}${path}`
    }
    result.push({ label, path })
    return result
  }, [] as ItemProps[])
}

const CustomLink: React.FC<{ label: string; path: string; clickable: boolean; title?: string }> = (
  props,
) => {
  const { label, path, clickable, ...rest } = props
  const history = useHistory()

  const onTextClick = useCallback(() => {
    history.push(path)
  }, [history, path])
  return (
    <Text
      className="breadcrumb-item"
      type="Medium"
      onClick={clickable ? onTextClick : undefined}
      color={color.FONT_BLACK}
      {...rest}
    >
      {label}
    </Text>
  )
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { overide, ...rest } = props
  const { pathname } = useRouter()

  const pathList = useMemo(() => {
    let paths = makePaths(pathname)
    if (overide) {
      paths = overide(paths)
    }
    return paths
  }, [pathname, overide])

  return (
    <Layout {...rest}>
      {pathList.map((p, i) => {
        return [
          i !== 0 && <Img key={p.label} src={IconNext} />,
          <CustomLink
            key={p.path}
            path={p.path}
            label={p.label}
            clickable={i !== pathList.length - 1}
            title={p.path}
          />,
        ]
      })}
    </Layout>
  )
}

export default Breadcrumb
