import styled from '@emotion/styled'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Button from '../../components/common/Button'
import Headbar from '../../components/common/HeadBar'
import Image from '../../components/common/Image'
import Loading from '../../components/common/Loading'
import Text from '../../components/common/Text'
import colors from '../../constant/colors'
import * as paths from '../../constant/path'
import { useGetCurrentBenefitYear } from '../../services/e-claim/e-claim-query'
import { useGetAllSelectedBenefits, useGetEnrollmentBenefits } from '../../services/enrollment/enrollment-query'
import { BenefitsCategoryCode } from '../../services/enrollment/enrollment-types'
import {
  useCheckCurrentProrate,
  useExchangeBenefit,
  useGetRelativeBenefit,
  useGetRelativeHealthInsurance
} from '../../services/relative/relative-query'
import { useGetUserCoinBenefitYear } from '../../services/user/user-query'
import { useVisibility } from '../../utils/custom-hook'
import { mobile } from '../../utils/responsive-helper'
import ItemRelative from '../Relative/ItemRelative'
import HealthInsuranceItem from './HealthInsuranceItem'
import ModalHealthInsurance from './ModalHealthInsurance'
import ModalHealthInsuranceError from './ModalHealthInsuranceError'

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
}

${mobile} {
  padding: 0px 16px 40px;
}
`

const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  min-height: 500px;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 4px;
  padding: 34px 20px;
  border-radius: 8px;
`
const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  div:first-of-type{
    display: flex;
    column-gap: 16px;
  }
`
const ContentRelativeList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const RelativeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`

const ContentEmptyList = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  div:first-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`

const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`
const CreateButton = styled(Button)`
  // align-self: center;
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  width: 768px;
  margin: 22px auto;
  
  ${mobile} {
    width: 100%;
  }
`
const PointCard = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);
  background-color: ${colors.WHITE};
  padding: 24px;
  margin-bottom: 30px;
`
const PointLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`
const PointWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
`
const PointLabel = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 4px;
`

const ignoreCode = "NOT_EXCHANGE_RELATIVE_INSURANCE_CARD"
const isCheckCodeBenefit = ["EXCHANGE_RELATIVE_INSURANCE_CARD", "NOT_EXCHANGE_RELATIVE_INSURANCE_CARD"]

const HealthInsuranceList = () => {
  const history = useHistory()
  const { t } = useTranslation()

  // const { data: user, refetch: refetchGetUser, isLoading: userLoading } = useGetUser()
  const { data: currentBenefitYear } = useGetCurrentBenefitYear()
  const { data: checkCurrentProrate } = useCheckCurrentProrate()
  const { data: selectedBenefits, refetch: refetchGetAllSelectedBenefits } = useGetAllSelectedBenefits(currentBenefitYear?.benefitYearId || 0)
  const { data: relativeList } = useGetRelativeHealthInsurance(currentBenefitYear?.benefitYearId || 0)
  const { data: userCoin, refetch: refetchUserCoinBenefitYear } = useGetUserCoinBenefitYear(currentBenefitYear?.benefitYearId || 0)
  const { mutate: exchangeBenefit } = useExchangeBenefit()
  const { data: relativeBenefit } = useGetRelativeBenefit(currentBenefitYear?.benefitYearId || 0)


  const { data: enrollmentsBenefits } = useGetEnrollmentBenefits(currentBenefitYear?.benefitYearId || 0)

  const [visible, modal] = useVisibility()
  const [visibleError, modalError] = useVisibility()
  const [message, setMessage] = useState(null)
  const getPoint = useCallback((point) => {
    if (!point) return 0

    return point >= 0 ? point.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0
  }, [])

  const onClickExchange = () => {
    modal.hide()
    if (currentBenefitYear) {
      exchangeBenefit(currentBenefitYear.benefitYearId, {
        onSuccess: (data: any) => {
          refetchGetAllSelectedBenefits()
          refetchUserCoinBenefitYear()
          history.push(paths.healthInsuranceCompleted())
        },
        onError: (e: any) => {
          setMessage(e?.message)
          modalError.show()
        },
      })
    }
  }

  const onClickCheckCoin = () => {
    modal.show()
  }

  const enrollmentsBenefitFilter = useMemo(() => {
    return enrollmentsBenefits?.selectedChoices.filter(val => !isCheckCodeBenefit.includes(val.selfBenefitsChoice.code))
  }, [enrollmentsBenefits?.selectedChoices])

  const checkCoinMessage = useMemo(() => {
    return `คุณต้องใช้ FLEXi Coin ${checkCurrentProrate?.defaultCoin} คะแนน /n สำหรับแลกรับบัตรประกันสุขภาพของคู่สมรส-บุตร`
  }, [checkCurrentProrate])

  const isCheckRelativeBenefit = useMemo(() => {
    if (relativeBenefit) return relativeBenefit.length > 0
  }, [relativeBenefit])

  const isShowCoin = useMemo(() => {
    return selectedBenefits?.selectedChoices?.find((val) => val.selfBenefitsCategoryCode === BenefitsCategoryCode.SPOUSE_CHILD)?.selfBenefitsChoice?.code === ignoreCode
  }, [selectedBenefits?.selectedChoices])

  const isShowClaimFliexi = useMemo(() => {
    return selectedBenefits?.selectedChoices.length !== 0
  }, [selectedBenefits?.selectedChoices])

  if (!userCoin) return <>Loading...</>

  return (
    <>
      <ModalHealthInsurance
        icon={'iconPoints'}
        visible={visible}
        errorMessage={checkCoinMessage}
        onCloseClick={() => modal.hide()}
        onConfirmClick={onClickExchange}
      />
      <ModalHealthInsuranceError
        message={message}
        icon={'iconAlertSign'}
        visible={visibleError}
        onCloseClick={modalError.hide}
        onConfirmClick={modalError.hide}
      />

      <Loading loading={false}>
        <Layout>
          <BackgroundHeader />
          <FlexRow>
            <Headbar titleSize={16} title='ประกันสุขภาพของพนักงาน และ คู่สมรส-บุตร' backWard={paths.home()} />
            <ContentLayout>
              {isShowClaimFliexi &&
                <PointCard>
                  <PointLayout>
                    <Text size={12} type={'Bold'}>
                      วงเงิน FLEXi Item
                    </Text>
                    <Text size={12} type={'Medium'}>
                      สำหรับเบิกสวัสดิการ FLEXi Item
                    </Text>
                    <PointWrapper>
                      <Image imageName={userCoin.find(item => item.name === "Flexi Coin") ? 'iconPoints' : 'iconPointsBlue'} width={48} height={48} />
                      <PointLabel>
                        <Text size={32} type='Bold'>
                          {
                            userCoin.find(item => item.name === "Flexi Coin") ?
                              getPoint(userCoin.find(item => item.name === "Flexi Coin")?.balance)
                              : 0
                          }
                        </Text>
                        <Text size={12}>
                          {t('app.point')}
                        </Text>
                      </PointLabel>
                    </PointWrapper>
                  </PointLayout>
                </PointCard>
              }

              <ContentHeader>
                <div>
                  <ImageStyle width={24} height={24} imageName={'IconInsurance'} />
                  <Text size={16} type='Bold' color={colors.BLACK}>แผนประกันสุขภาพของคุณ</Text>
                </div>
              </ContentHeader>
              <ContentRelativeList>
                <RelativeList>
                  {enrollmentsBenefitFilter?.map((val) =>
                    <HealthInsuranceItem key={val.selfBenefitsChoiceId} data={val} />
                  )}
                </RelativeList>
              </ContentRelativeList>

              <ContentHeader>
                <div>
                  <ImageStyle width={24} height={24} imageName={'iconFamilyInsurance'} />
                  <Text size={16} type='Bold' color={colors.BLACK}>ข้อมูลบัตรประกันสุขภาพของคู่สมรส-บุตร</Text>
                </div>
                <div>
                  {isShowCoin &&
                    <Button
                      fontSize={14}
                      style={{ maxHeight: "36px" }}
                      onClick={onClickCheckCoin}
                      disabled={!isCheckRelativeBenefit}
                    >
                      แลก FLEXi Coin เพื่อรับบัตรประกันคู่สมรส-บุตร
                    </Button>
                  }
                </div>
              </ContentHeader>
              {relativeList && relativeList.length > 0 ?
                <ContentRelativeList>
                  <RelativeList>
                    {relativeList?.map((val) =>
                      <ItemRelative key={val.id} data={val} isMore={false} />
                    )}
                  </RelativeList>
                </ContentRelativeList>
                :
                <ContentEmptyList>
                  <div>
                    <ImageStyle width={24} height={24} imageName={'iconPin'} />
                    <Text size={16} type='Medium' color={colors.BLACK}>ไม่พบรายชื่อสมาชิกในครอบครัว</Text>
                  </div>
                  <CreateButton ghost fontColor={colors.GREEN} onClick={() => history.push(paths.relativeAdd())}>เพิ่มรายชื่อสมาชิกในครอบครัว</CreateButton>
                </ContentEmptyList>
              }
            </ContentLayout>
          </FlexRow>
        </Layout >
      </Loading>
    </>)
}

export default HealthInsuranceList