import styled from '@emotion/styled'
import { useHistory } from 'react-router'
import Button from '../../components/common/Button'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import { home } from '../../constant/path'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const CardLayout = styled(Card)`
  width: 100%;
  max-width: 784px;
  min-height: 600px;

  margin: 65px auto 0 auto;
  padding: 40px 28px;
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);
  border-radius: 8px;
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 16px;

  ${mobile} {
    margin: 22px auto 0 auto;
    padding: 56px 24px;
  }
`
const RelativeLayout = styled.div`
  background: #FAF9F6;
  border: 1px solid #E7E2D8;
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 0px;
  margin-bottom: 40px;
`
const TextCenter = styled(Text)`
  text-align: center;
`
const ConfirmButton = styled(Button)`
  width: 350px;
  
  ${mobile} {
    width: calc(100vw - 42px);
  }
`

const HealthInsuranceCompleted = () => {
  const history = useHistory()

  return (
    <Layout>
      <BackgroundHeader />
      <CardLayout>
        <Image style={{ marginBottom: 40 }} imageName='GroupImage' />
        <Text size={20} type="Bold">แลกรับบัตรประกันสุขภาพของคู่สมรส-บุตรสำเร็จ</Text>
        <RelativeLayout>
          <TextCenter size={16} type="Regular">
            หลังจากได้รับการอนุมัติในระบบแล้ว  <br />
            HR จะดำเนินการสำหรับการออกบัตรอย่างน้อย 14 วัน <br />
            ความคุ้มครองจะมีผลตั้งแต่วันที่อนุมัติในระบบ <br />
            กรณีมีค่ารักษาพยาบาลก่อนได้รับบัตรประกัน ให้พนักงานสำรองจ่ายแล้วนำมาเบิกกับบริษัทประกันภายหลัง
          </TextCenter>
        </RelativeLayout>
        <ConfirmButton onClick={() => { history.push(home()) }}>
          กลับหน้าแรก
        </ConfirmButton>
      </CardLayout>
    </Layout>
  )
}

export default HealthInsuranceCompleted