import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import { GET_RELATIVE_SCG } from '../e-claim/e-claim-query'
import { CreateEclaimUserResponse, RelativeScgResponse } from '../e-claim/e-claim-types'
import { GET_USER_COIN_URL } from '../user/user-query'
import { OPDItem, ClinicListsResponse, CreateOpdUser, OPDHistoryListResponse } from './opd-types'

const APP = '/app'
export const USER_CLAIM = 'userClaim'
export const OPD_LIST = `${APP}/${USER_CLAIM}/getClaim/OPD`
export const CHECK_CLAIM_AMOUNT = `${APP}/${USER_CLAIM}/claimableAmount/OPD`
export const CREATE_OPD_CLAIM = `${APP}/${USER_CLAIM}/createUserClaim/OPD`
export const CLINIC_LISTS = `${APP}/${USER_CLAIM}/opd/clinic`
export const OPD_HISTORY_LIST = `${APP}/${USER_CLAIM}/getAllUserClaimByUser/opd`

export const useGetOPD = () => {
  return useQuery([OPD_LIST], () => {
    return api.belive.get<OPDItem>(`${OPD_LIST}`)
  })
}

export const useCreateOpdClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateOpdUser) => {
      const {
        claimId,
        receiptAmount,
        receiptDate,
        relativeId,
        clinicType,
        userClaimDocumentList
      } = data
      return api.belive.post<CreateEclaimUserResponse>(
        CREATE_OPD_CLAIM,
        JSON.stringify({
          claimId,
          receiptAmount,
          receiptDate,
          relativeId,
          clinicType,
          userClaimDocumentList
        }),
        { contentType: ContentType.JSON },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OPD_LIST])
        queryClient.invalidateQueries([GET_USER_COIN_URL])
        queryClient.invalidateQueries([OPD_HISTORY_LIST])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useGetClinicLists = (relativeTypeId: number | string) => {
  return useQuery([CLINIC_LISTS, relativeTypeId], () => {
    return api.belive.get<ClinicListsResponse>(`${CLINIC_LISTS}?relativeTypeId=${relativeTypeId}`)
  })
}
export const useGetOpdHistoryList = () => {
  return useQuery([OPD_HISTORY_LIST], () => {
    return api.belive.get<OPDHistoryListResponse>(`${OPD_HISTORY_LIST}`)
  })
}

export const useGetRelativeOption = (claimId?: number) => {
  return useQuery([GET_RELATIVE_SCG, 'options', claimId], async () => {
    const data = await api.belive.get<RelativeScgResponse>(`${GET_RELATIVE_SCG}/opd/${claimId}`)
    const optionRelative: {
      label: string
      value: Record<string, string>
      disabled?: boolean
    }[] = []

    data.map((relative) => {
      const { firstname, lastname, id, typeId, typeName } = relative
      optionRelative.push({
        label: `${typeName}: ${firstname} ${lastname}`, value: {
          id: id.toString(),
          typeId: typeId.toString(),
        }
      })
    })
    // }

    return optionRelative
  })
}