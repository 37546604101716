import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useGetUser, useGetUserCoin } from '../../services/user/user-query'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'
import Text from '../../components/common/Text'
import Image from '../../components/common/Image'
import PointLabel from '../../components/common/PointLabel'
import color from '../../constant/colors'
import Avatar from '../../components/common/Avatar'
import config from '../../config'
import Point from '../../components/common/Point'

const ProfileLayout = styled.div`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  z-index: inherit;
  margin-bottom: 16px;

  .left {
    display: flex;
    row-gap: 8px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }
`
const TextProfile = styled(Text)`
  padding-left: 16px;
`

const CustomeStyleAvatar = styled(Avatar)`
  margin: 0 auto;
  ${mobile} {
    margin: auto 0;
  }
`

const LeftCointainer = styled.div`
  display: flex;
  margin: 8px;
  `

const Profile = () => {
  const { t } = useTranslation()
  const { data: user } = useGetUser()
  const { data: userCoin } = useGetUserCoin()
  const { isMobile } = useScreen()

  return (
    <ProfileLayout>
      <div className='left'>
        <LeftCointainer>
          <CustomeStyleAvatar
            src={`${config.apiHost}${user?.profileImagePath}`}
            isBorder={true}
            size={80}
            borderWidth={1}
          />

          <div>
            <TextProfile size={24} type="Bold">{`${user?.firstName} ${user?.lastName}`}</TextProfile>
          </div>
        </LeftCointainer>
      </div>


      <div className='right'>
        <Point />
      </div>
    </ProfileLayout>
  )
}

export default Profile