import styled from '@emotion/styled'
import { useMemo } from 'react'
import HtmlWithStyled from '../../components/HtmlWithStyled'
import Card from '../../components/common/Card'
import Headbar from '../../components/common/HeadBar'
import Text from '../../components/common/Text'
import colors from '../../constant/colors'
import * as paths from '../../constant/path'
import { useGetEnrollmentBenefits, useGetEnrollmentSelectPeriod, useGetEnrollmentActivePeriod } from '../../services/enrollment/enrollment-query'
import { useRouter } from '../../utils/helper'
import { mobile, tablet } from '../../utils/responsive-helper'

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
}
`
const CardLayout = styled(Card)`
  width: 100%;
  max-width: 784px;
  margin: 0 auto;
  padding: 56px 90px;
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);
  border-radius: 8px;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;

  ${mobile} {
    padding: 56px 24px;
  }
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
  `
const FlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  width: 768px;
  margin: 22px auto;
  
  ${mobile} {
    width: 100%;
  }
`
const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  min-height: 500px;
  max-width: 784px;
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 32px;
  padding: 34px 120px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);

  .benefit-description {
    padding: 16px;
    border-radius: 8px;
    background-color: ${colors.BACKGROUND};
  }
  
  ${tablet} {
    padding: 56px 24px;
  }

  ${mobile} {
    padding: 36px 8px;
  }
`

const HealthInsurancePlan = () => {
  const { query } = useRouter()
  const { data: enrollmentSelectPeriod, isError, isFetched } = useGetEnrollmentSelectPeriod()
  const { data: enrollmentActivePeriod } = useGetEnrollmentActivePeriod()

  const isNotFoundBenefitYear = useMemo(() => {
    if (isFetched && enrollmentSelectPeriod) return false

    return true
  }, [enrollmentSelectPeriod, isFetched])

  const { data } = useGetEnrollmentBenefits(isNotFoundBenefitYear ? enrollmentActivePeriod?.benefitYearId : enrollmentSelectPeriod?.benefitYearId)

  const newData = useMemo(() => data?.selectedChoices.find(val => val.selfBenefitsChoiceId = query.selfBenefitsChoiceId), [data?.selectedChoices, query.selfBenefitsChoiceId])

  return (
    <Layout>
      <BackgroundHeader />
      <FlexRow>
        <Headbar style={{ marginLeft: "12px", marginRight: "12px" }} titleSize={16} title='ประกันสุขภาพของพนักงาน และ คู่สมรส-บุตร' backWard={paths.healthInsurance()} />
        <ContentLayout>
          <Text size={20} type="Bold">รายละเอียดความคุ้มครอง</Text>
          <HtmlWithStyled className="benefit-description" html={newData?.selfBenefitsChoice?.choicesDescription || ""} />
        </ContentLayout>
      </FlexRow>
    </Layout>
  )
}

export default HealthInsurancePlan