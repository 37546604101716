import styled from '@emotion/styled'
import moment, { Moment } from 'moment'
import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { InjectedFormProps, change, getFormValues, reduxForm } from 'redux-form'
import Button from '../../components/common/Button'
import Headbar from '../../components/common/HeadBar'
import Image from '../../components/common/Image'
import Loading from '../../components/common/Loading'
import Text from '../../components/common/Text'
import { DatePickerField, InputField, SelectField, UploadfileField } from '../../components/common/fields'
import { default as color, default as colors } from '../../constant/colors'
import * as paths from '../../constant/path'
import { RelativeInf, setRelativeInfo } from '../../redux-store/relative-reducer'
import {
  useCheckRelative,
  useCreateRelative,
  useDeleteRelativeById,
  useGetDocumentType,
  useGetEducationTypeList,
  useGetRelativeTypeList,
  useUploadFileRelative
} from '../../services/relative/relative-query'
import { CheckRelativeParams, Relative, RelativeFormParams } from '../../services/relative/relative-typed'
import { useGetUser } from '../../services/user/user-query'
import { useVisibility } from '../../utils/custom-hook'
import { convertBase64ToFile, useRouter } from '../../utils/helper'
import { mobile } from '../../utils/responsive-helper'
import ModalRelativeConsent from './ModalRelativeConsent'
import ModalRelativeDelete from './ModalRelativeDelete'
import ModalRelativeError from './ModalRelativeError'

const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  min-height: 500px;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 4px;
  padding: 16px;
  border-radius: 8px;
`
const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-bottom: 16px;
`
const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`
const FlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  width: 768px;
  margin: 0 auto;
  
  ${mobile} {
    width: 100%;
  }
`
export const InputLabel = styled(Text)`
  line-height: normal;
  margin-bottom: 3px;
  color: ${color.INPUT_LABLE};
`
const Spacer = styled.div`
  width: 20px;
  height: 20px;

  ${mobile} {
    display: none;
  }
`
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FormLayout = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 32px;

  ${mobile} {
    flex-flow: column;
    justify-content: flex-start;
  }
`
const CustomEditableFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 42px 16px 16px 16px;

  ${mobile} {
    justify-content: center;
  }
`
const ButtonStyle = styled(Button)`
  width: 100px;
  justify-content: center;
`


// const validatrelativeAmount = useCallback(
//   (value) => {
//     if (relativeItem?.claimPrice && value > relativeItem?.claimPrice) return 'relative.claimPriceError'
//   },
//   [relativeItem?.claimPrice],
// )

// const isEnoughCoin = useCallback(
//   (value) => {
//     if (value > userCoin) return 'relative.PointError'
//   },
//   [userCoin])

// const isNotZero = useCallback(
//   (value) => {
//     if (value === '0') return 'error.required'
//   }
//   , [])

const RELATIVE_FORM_NAME = 'RELATIVE_FORM'
type RelativeParam = {
  relativeItem?: Relative
  editable?: boolean
  isEditData?: boolean
  relativeFormValue?: RelativeInf['relativeInfo']
  isPreview?: boolean
  disbleField?: boolean
  isHistory?: boolean
}

const required = (value: string) => (value ? undefined : 'error.required')
const requiredDate = (value: Moment) => {
  const x = moment(value).isValid() ? undefined : 'error.required'
  return x
}

const RelativeForm: ComponentType<
  RelativeParam & InjectedFormProps<RelativeFormParams, RelativeParam, string>
> = (props) => {
  const history = useHistory()
  const { query } = useRouter<{ relativeId: number }>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    handleSubmit,
    invalid,
    relativeItem,
    editable = true,
    isEditData = false,
    relativeFormValue,
    isPreview = false,
    disbleField = false,
    isHistory = false,
    initialValues
  } = props

  const formValuesSelector = getFormValues(RELATIVE_FORM_NAME)
  const formValues = useSelector<any, RelativeFormParams>(
    (state) => formValuesSelector(state) as RelativeFormParams,
  )
  const [errorMessage, setErrorMessage] = useState("")

  const [visibleConsent, modalConsent] = useVisibility()
  const [visibleDelete, modalDelete] = useVisibility()
  const [visibleError, modalError] = useVisibility()

  const { data: documentType, isLoading: isLoadingDocumentType, refetch: refetchDocumentType } = useGetDocumentType(formValues.relativeTypeId || 0)
  const { mutate: createRelative, isLoading: isCreateRelative } = useCreateRelative()
  const { mutate: checkRelative, isLoading: isCheckRelative } = useCheckRelative()
  const { mutate: deleteRelative } = useDeleteRelativeById()
  const { data: relativesOptions } = useGetRelativeTypeList()
  const { data: educationOptions } = useGetEducationTypeList()
  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFileRelative()
  const { data: user } = useGetUser()

  const { firstName, lastName } = user || {}
  const fullname = `${firstName} ${lastName}`

  useEffect(() => {
    if (formValues?.relativeTypeId && formValues?.relativeTypeId > 0)
      refetchDocumentType()

    dispatch(dispatch(change(RELATIVE_FORM_NAME, 'educationId', 0)));

  }, [dispatch, formValues?.relativeTypeId, refetchDocumentType])

  const getImageUploadUrl = useCallback(async () => {
    if (formValues?.files && documentType) {
      let newFile = documentType.filter((file, i) => {
        if (!formValues?.files?.[i]) return false;
        return true;
      }).map(async (file, i) => {
        const files = await convertBase64ToFile(formValues?.files?.[i] || '')
        return uploadFile({ files, documentTypeId: file.documentType.id })
      })
      return await Promise.all(newFile)
    }

  }, [documentType, formValues?.files, uploadFile])

  const onSubmit = useCallback((form: RelativeFormParams) => {
    const fullName = form?.name ? form.name.split(/\s+/) : []

    const req = {
      id: initialValues?.id,
      userId: user?.userId,
      firstname: fullName[0] || '',
      lastname: fullName[1] || '',
      idCard: form?.idCard,
      relativeTypeId: form?.relativeTypeId ? form.relativeTypeId : 0,
      birthday: moment(form?.birthDate).format("YYYY-MM-DD"),
      educationId: form?.educationId ? form.educationId : 0,
    }

    checkRelative(req, {
      onSuccess: (data: CheckRelativeParams) => {
        dispatch(
          setRelativeInfo({
            id: initialValues?.id || relativeItem?.id || 0,
            name: form?.name,
            idCard: form?.idCard,
            relativeTypeId: form?.relativeTypeId ? form.relativeTypeId : 0,
            birthDate: moment(form?.birthDate),
            educationId: form?.educationId ? form.educationId : 0,
          }),
        )
        modalConsent.show()
      },
      onError: (e: any) => {
        setErrorMessage(e?.message)
        modalError.show()
      }
    })
  },
    [checkRelative, dispatch, initialValues?.id, modalConsent, modalError, relativeItem?.id, user?.userId],
  )

  const onSubmitApplication = useCallback(async () => {
    //uploadfile
    try {
      const urlFilesUpload = await getImageUploadUrl()
      const fullName = relativeFormValue?.name ? relativeFormValue.name.split(/\s+/) : []

      const req = {
        id: initialValues?.id,
        userId: user?.userId,
        firstname: fullName[0] || '',
        lastname: fullName[1] || '',
        idCard: relativeFormValue?.idCard || '',
        relativeTypeId: relativeFormValue?.relativeTypeId ? relativeFormValue.relativeTypeId : undefined,
        birthday: relativeFormValue?.birthDate.format("YYYY-MM-DD") || '',
        educationId: relativeFormValue?.educationId ? relativeFormValue.educationId : undefined,
        files: urlFilesUpload?.map((data: any) => { return data[0] })
      }

      createRelative(
        req,
        {
          onSuccess: (data: Relative) => {
            history.push(paths.relativeCompleted({
              routeParam: { relativeId: data?.id || 0 },
            }))
          },
          onError: (error) => {
            setErrorMessage(error.message)
            modalConsent.show()
          },
        },
      )
    } catch (error) {
      console.log(error)
    }
  }, [createRelative, getImageUploadUrl, history, initialValues?.id, modalConsent, relativeFormValue?.birthDate, relativeFormValue?.educationId, relativeFormValue?.idCard, relativeFormValue?.name, relativeFormValue?.relativeTypeId, user?.userId])

  const relativeLists = useMemo(() => {
    let optionRelative: {
      label: string
      value: string | number;
      disabled?: boolean | undefined;
    }[] = []

    if (relativesOptions)
      optionRelative.push({ label: 'กรุณาเลือก', value: 0, disabled: true }, ...relativesOptions)

    return optionRelative;
  }, [relativesOptions])

  const educationLists = useMemo(() => {
    let optionEducation: {
      label: string
      value: string | number;
      disabled?: boolean | undefined;
    }[] = []

    if (educationOptions)
      optionEducation.push({ label: 'กรุณาเลือก', value: 0, disabled: true }, ...educationOptions)

    return optionEducation;
  }, [educationOptions])

  const nameRelative = useCallback(
    (relativeId) => {
      const data = relativeLists?.find(({ value }) => relativeId === value)
      return data ? data.label : 'ตัวเอง'
    }, [relativeLists])

  const nameEducation = useCallback(
    (educationId) => {
      const data = educationLists?.find(({ value }) => educationId === value)
      return data ? data.label : 'ตัวเอง'
    }, [educationLists])

  return (
    <>
      <ModalRelativeConsent
        name={relativeFormValue?.name || ""}
        idCard={relativeFormValue?.idCard || ""}
        relativeTypeName={nameRelative(relativeFormValue?.relativeTypeId)}
        visible={visibleConsent}
        onCloseClick={modalConsent.hide}
        onConfirmClick={onSubmitApplication}
      />
      <ModalRelativeDelete
        icon={'iconRedBin'}
        visible={visibleDelete}
        errorMessage='คุณต้องการลบข้อมูลครอบครัว ?'
        onCloseClick={modalDelete.hide}
        onConfirmClick={() => {
          deleteRelative(query.relativeId)
          modalDelete.hide();
          history.push(paths.relative())
        }}
      />
      <ModalRelativeError
        icon={'iconAlertSign'}
        visible={visibleError}
        errorMessage={errorMessage}
        onCloseClick={modalError.hide}
        onConfirmClick={modalError.hide}
      />
      <FlexRow style={{ marginTop: 22 }}>
        <Headbar titleSize={16} title={isEditData ? 'แก้ไขข้อมูลครอบครัว' : 'เพิ่มข้อมูลครอบครัว'} />
        <ContentLayout>
          <ContentHeader>
            <ImageStyle width={24} height={24} imageName={'iconFamilyInsurance'} />
            <Text size={16} type='Bold' color={colors.BLACK}>ข้อมูลครอบครัว</Text>
          </ContentHeader>
          <FormLayout>
            <InputContainer>
              {editable ? (
                <FlexCol>
                  <InputField
                    name="name"
                    labelType="Bold"
                    label="ชื่อ - นามสกุล"
                    placeholder="กรุณากรอก"
                    validate={[required]}
                    disabled={disbleField}
                  />
                </FlexCol>
              ) : (
                <FlexCol>
                  <InputLabel size={14} type='Bold'>ชื่อ - นามสกุล</InputLabel>
                  <Text type="Bold">{ }</Text>
                </FlexCol>
              )}
              <Spacer />
              {editable ? (
                <FlexCol>
                  <InputLabel size={14} type='Bold'>ความสัมพันธ์</InputLabel>
                  <SelectField
                    name="relativeTypeId"
                    placeholder="กรุณาเลือก"
                    validate={[required]}
                    options={[...relativeLists]}
                    disabled={disbleField}
                  />
                </FlexCol>
              ) : (
                <FlexCol>
                  <InputLabel size={14} type='Bold'>ความสัมพันธ์</InputLabel>
                  <Text type="Bold">{nameRelative(relativeFormValue?.relativeTypeId)}</Text>
                </FlexCol>

              )}
            </InputContainer>

            <InputContainer>
              {editable ? (
                <FlexCol>
                  <DatePickerField
                    labelType="Bold"
                    name="birthDate"
                    label="วัน / เดือน / ปีเกิด"
                    validate={[requiredDate]}
                    disabled={disbleField}
                  />
                </FlexCol>
              ) : (
                <FlexCol>
                  <InputLabel size={14} type='Bold'>ชื่อ - นามสกุล</InputLabel>
                  <Text type="Bold">{relativeFormValue?.birthDate.format('DD/MM/YYYY')}</Text>
                </FlexCol>
              )}
              <Spacer />
              {editable ? (
                <FlexCol>
                  <InputField
                    name="idCard"
                    labelType="Bold"
                    label="เลขบัตรประจำตัวประชาชน"
                    placeholder="กรุณากรอก"
                    validate={[required]}
                    disabled={disbleField}
                  />
                </FlexCol>
              ) : (
                <FlexCol>
                  <InputLabel size={14} type='Bold'>ชื่อ - นามสกุล</InputLabel>
                  <Text type="Bold">{relativeFormValue?.idCard}</Text>
                </FlexCol>
              )}
            </InputContainer>

            {formValues?.relativeTypeId === 2 &&
              <InputContainer style={{ width: '100%' }}>
                {editable ? (
                  <FlexCol>
                    <InputLabel size={14} type='Bold'>การศึกษา</InputLabel>
                    <SelectField
                      name="educationId"
                      placeholder="กรุณาเลือก"
                      validate={[required]}
                      options={[...educationLists]}
                      disabled={disbleField}
                    />
                  </FlexCol>
                ) : (
                  <FlexCol>
                    <InputLabel size={14} type='Bold'>ชื่อ - นามสกุล</InputLabel>
                    <Text type="Bold">{nameEducation(relativeFormValue?.educationId)}</Text>
                  </FlexCol>
                )}
                <Spacer />
                <FlexCol></FlexCol>
              </InputContainer>
            }
          </FormLayout>

          <Loading loading={isLoadingDocumentType}>
            {documentType && documentType.length > 0 &&
              <>
                <ContentHeader>
                  <ImageStyle width={24} height={24} imageName={'iconClaim'} />
                  <Text size={16} type='Bold' color={colors.BLACK}>แนบเอกสาร</Text>
                </ContentHeader>
                {documentType.map((val, i) =>
                  <FileUploadContainer key={val.id}>
                    <UploadfileField
                      name={`files[${i}]`}
                      // icon={'receiptIcon'}
                      title={`${i + 1}. ${val.documentType.name}`}
                      desc={val.documentType.description}
                      isPreview={isPreview}
                      validate={val.isRequired || (formValues?.educationId === 2 && val.id === 4) ? [required] : []}
                      maximumFileSize={10}
                      disabled={disbleField}
                    />
                    <Spacer />
                  </FileUploadContainer>
                )}
              </>
            }
          </Loading>

          <CustomEditableFooter>
            {isEditData ?
              <ButtonStyle
                ghost
                fontColor={color.CHOICES_PRIMARY}
                fontSize={16}
                onClick={() => modalDelete.show()}
              >
                <ImageStyle width={20} height={20} imageName={'iconGreenBin'} />
                &nbsp;
                ลบ
              </ButtonStyle> : <div></div>}
            <ButtonStyle
              fontSize={16}
              onClick={handleSubmit(onSubmit)}
              disabled={invalid || disbleField}
            >
              บันทึกข้อมูล
            </ButtonStyle>
          </CustomEditableFooter>
        </ContentLayout>
      </FlexRow >
    </>
  )
}

export default reduxForm<RelativeFormParams, RelativeParam, string>({
  form: RELATIVE_FORM_NAME,
  initialValues: {
    relativeTypeId: 0,
    educationId: 0,
  },
  enableReinitialize: true
})(RelativeForm)
