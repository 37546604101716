import { useMemo } from 'react'
import styled from '@emotion/styled'
import Text from '../../../components/common/Text'
import color from '../../../constant/colors'
import { useRouter, useProbation } from '../../../utils/helper'
import { useGetUser } from '../../../services/user/user-query'
import Button from '../../../components/common/Button'
import Image from '../../../components/common/Image'
import {
  GetBenefitsResponse,
} from '../../../services/enrollment/enrollment-types'
import { healthCheckupEditSelfBenefits } from '../../../constant/path'
import { useHistory } from 'react-router'
import { Content, ContentSpace, ContentHeader, BenefitsCategoryStyled } from './EditBenefits/shared'
import { useTranslation } from 'react-i18next'
import HtmlWithStyled from '../../../components/HtmlWithStyled'
import { mobile } from '../../../utils/responsive-helper'

const Row = styled.div`
  display: flex;
  flex-flow: row;
`
const ButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  padding-bottom: 32px;
`
const StyledGroup = styled.div`
  width: 100%;
  display: grid;
  column-gap: 16px;
`
const BenefitSelectLayout = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${color.BORDER};
  border-radius: 8px;
  padding: 24px;
  column-gap: 16px;
`
const BenefitFamily = styled.div`
  * {
    text-align: left;
    color: red;
  }

  div {
    display: flex;
    align-items: center;
    column-gap: 16px;

    &:nth-child(2), &:nth-child(3) {
      padding-left: 16px;
    }
  }
`
const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  min-width: 165px;
`

const DescriptionLayout = styled(Row)`
  width: 100%;
  align-items: flex-end;
  > *:not(:last-child) {
    margin-right: 8px;
  }

  .benefit-description {
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
    font-family: IBMPlexSansThai-Medium;
    font-size: 20px;
    font-weight: 300;
    color: #333333;

    * {
      width: inherit;
    }
  }

  ${mobile} {
    flex-flow: column;
  }
`
const DownloadButton = styled(Button)`
  width: fit-content;
`

type SelfBenefitsProp = {
  selectedHealthCheckupBenefits: GetBenefitsResponse
  canEditable: boolean
  endDate: number
  onClickShowModalInsuranceDetail: () => void
}

const SelfBenefits = (props: SelfBenefitsProp) => {
  const { data: user } = useGetUser()
  const history = useHistory()
  const { query } = useRouter()
  const { t } = useTranslation()
  const isProbation = useProbation()

  const { selectedHealthCheckupBenefits, canEditable, endDate, onClickShowModalInsuranceDetail } = props
  const { userSelectedWrapperList } = selectedHealthCheckupBenefits

  const isAvailable = user?.lastEnrollDate > Date.now()
  const categoriesSelfBenefits = useMemo(() => {

    const onClickButton = () => {
      history.push(
        healthCheckupEditSelfBenefits({
          routeParam: {
            benefitYearId: query.benefitYearId,
          },
        }),
      )
    }

    enum DownloadFileList {
      'additional' = 1,
      'familyHealthCheckup' = 2,
    }
    const onClickDownload = (file: DownloadFileList) => {
      if (file === DownloadFileList.additional) {
        window.open('https://beliveshop.blob.core.windows.net/product/dentsu/%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%95%E0%B8%A3%E0%B8%A7%E0%B8%88%E0%B9%80%E0%B8%9E%E0%B8%B4%E0%B9%88%E0%B8%A1.pdf')
      }

      if (file === DownloadFileList.familyHealthCheckup) {
        window.open('https://beliveshop.blob.core.windows.net/product/dentsu/%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%81%E0%B8%81%E0%B8%A3%E0%B8%A1%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%95%E0%B8%A3%E0%B8%A7%E0%B8%88%E0%B8%AA%E0%B8%B8%E0%B8%82%E0%B8%A0%E0%B8%B2%E0%B8%9E%E0%B8%AA%E0%B8%B3%E0%B8%AB%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%84%E0%B8%A3%E0%B8%AD%E0%B8%9A%E0%B8%84%E0%B8%A3%E0%B8%B1%E0%B8%A7%E0%B8%9E%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%87%E0%B8%B2%E0%B8%99.pdf')
      }
    }

    return (
      <div>
        <Content>
          {userSelectedWrapperList.map((items, index) => {
            if (items.choicesId) {
              return (
                <>
                  <ContentHeader>
                    <div style={{ display: 'flex' }}>
                      <div className="icon-enroll">
                        <Image imageName="iconEnrollWhite" />
                      </div>
                      <Text
                        size={24}
                        type="Bold"
                        color={color.WHITE}
                      >
                        {`แผนตรวจสุขภาพของคุณ: ${items.choiceName?.split("<br>").join("")}`}
                      </Text>
                    </div>
                  </ContentHeader>
                  <BenefitsCategoryStyled>
                    <StyledGroup>
                      {items.choicesDescription && (
                        <DescriptionLayout>
                          <HtmlWithStyled className="benefit-description" html={items.choicesDescription} />
                        </DescriptionLayout>
                      )}

                      <Row className="second"></Row>
                      <Row
                        style={{
                          justifyContent: 'center',
                          padding: 12,
                          columnGap: 16,
                          backgroundColor: color.LIGHT_GRAY,
                          height: 54,
                        }}
                      >
                        <Image imageName="iconBenefit" width={34} />
                        <Text color={color.PRIMARY}>{t('healthCheckup.selfBenefits.healthCheckupSelected')}</Text>
                      </Row>
                    </StyledGroup>
                  </BenefitsCategoryStyled>
                </>
              )
            } else {
              return (
                <Text size={24} type="Bold" color={color.WHITE}>
                  {`ไม่พบประกันของคุณ`}
                </Text>
              )
            }
          })}

        </Content>
        {isAvailable === true && !isProbation && (
          <BenefitSelectLayout>
            <BenefitFamily>
              <Text>พนักงานสามารถคลิกดาวน์โหลดรายการตรวจเพิ่มและแผนตรวจสุขภาพราคาพิเศษสำหรับครอบครัวหรือญาติพนักงาน</Text>
              <div>
                <Text>1. รายการตรวจเพิ่ม</Text><DownloadButton fontSize={14} size='small' onClick={() => onClickDownload(DownloadFileList.additional)}>Download</DownloadButton>
              </div>
              <div>
                <Text>2. แผนตรวจสุขภาพสำหรับครอบครัวหรือญาติพนักงาน</Text><DownloadButton fontSize={14} size='small' onClick={() => onClickDownload(DownloadFileList.familyHealthCheckup)}>Download</DownloadButton>
              </div>
              <Text>* รายการตรวจเพิ่มและแผนตรวจสุขภาพสำหรับครอบครัวหรือญาติ พนักงานต้องรับผิดชอบค่าใช้จ่ายในส่วนนี้เอง</Text>
            </BenefitFamily>
            <BenefitSelectButton onClick={onClickButton}>{t('healthCheckup.selfBenefits.changeHealthCheckup')}</BenefitSelectButton>
          </BenefitSelectLayout>
        )}

        {/* {(canEditable === false) && (isAvailable === true) && (
          <BenefitSelectLayout>
            <BenefitSelectButton onClick={onClickButton}>{t('healthCheckup.selfBenefits.changeHealthCheckup')}</BenefitSelectButton>
          </BenefitSelectLayout>
        )} */}
      </div>
    )
  }, [
    canEditable,
    history,
    query.benefitYearId,
    isAvailable,
    t,
    userSelectedWrapperList,
    isProbation,
  ])

  const categoriesSelfBenefits2 = useMemo(() => {
    const onClickButton = () => {
      history.push(
        healthCheckupEditSelfBenefits({
          routeParam: {
            benefitYearId: query.benefitYearId,
          },
        }),
      )
    }

    return (
      <ButtonStyle style={{}}>
        <Button disabled={!canEditable} onClick={onClickButton}>
          + {t('healthCheckup.selfBenefits.addHealthCheckup')}
        </Button>
      </ButtonStyle>
    )
  }, [history, query.benefitYearId, canEditable, t])

  return (
    <ContentSpace direction="vertical" size={10} style={{ marginBottom: 32 }}>
      {userSelectedWrapperList.length > 0 ? categoriesSelfBenefits : categoriesSelfBenefits2}
    </ContentSpace>
  )
}

export default SelfBenefits
