import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ServicePeriod } from './status-typed'

const API = '/api'
const SERVICE_PERIOD = `${API}/service-period`

export const useServicePeriod = () => {
  return useQuery(
    [SERVICE_PERIOD],
    () => {
      return api.belive.get<ServicePeriod>(`${SERVICE_PERIOD}`)
    },
    {
      staleTime: 5 * 60 * 1000,
    },
  )
}