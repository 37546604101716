import styled from '@emotion/styled'
import { ModalProps } from 'antd/lib/modal'
import { useCallback } from 'react'
import Button from '../../components/common/Button'
import Image, { ImageNameKeys } from '../../components/common/Image'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import { mobile } from '../../utils/responsive-helper'
import { Footer } from '../Enrollment/ModalAcceptPrivacy'

const Layout = styled.div`
    padding: 24px;

  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    padding-top: 24px;

    .header {
      padding-right: 32px;
    }
  }
`
const Header = styled.div`
  text-align: center;
  margin-bottom: 24px;
`
const Content = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
`
const TextCenter = styled(Text)`
  text-align: center;
`
const ConfirmButton = styled(Button)`
  width: 200px;
`

type ModalRelativeErrorProps = {
  icon?: ImageNameKeys
  errorMessage: string
  onConfirmClick: () => void
  onCloseClick: () => void
} & Required<Pick<ModalProps, 'visible'>>

const ModalRelativeError = (props: ModalRelativeErrorProps) => {
  const { icon = null, errorMessage, visible, onConfirmClick, onCloseClick } = props

  const onCancel = useCallback(() => {
    onCloseClick()
  }, [onCloseClick])

  const onConfirm = useCallback(() => {
    onConfirmClick()
  }, [onConfirmClick])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      style={{
        padding: 0,
      }}
      hideFooter
      hideCloseIcon
    >
      <Layout>
        <Header>
          {icon && <Image imageName={icon} width={56} height={56} />}
        </Header>
        <Content>
          <TextCenter size={16} type="Bold">{errorMessage}</TextCenter>
        </Content>
        <Footer>
          <ConfirmButton onClick={onConfirm}>
            ตกลง
          </ConfirmButton>
        </Footer>
      </Layout>
    </Modal>
  )
}

export default ModalRelativeError