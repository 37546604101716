import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import Button from '../../components/common/Button'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import { useRouter } from '../../utils/helper'
import { BenefitType } from '../../services/enrollment/enrollment-types'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
  margin-top: 32px;
`
const CardLayout = styled(Card)`
  width: 100%;
  max-width: 784px;
  margin: 0 auto;
  padding: 56px 90px;
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);
  border-radius: 8px;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  row-gap: 32px;

  ${mobile} {
    padding: 56px 24px;
  }
`

const BenefitLayout = styled.div`
  background: #FAF9F6;
  border: 1px solid #E7E2D8;
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 16px;
`

const Complete = () => {
  const history = useHistory()
  const { query } = useRouter<{ benefitType: BenefitType, myBenefitPlan?: string, benefitFamily?: string }>()
  const { benefitType, myBenefitPlan, benefitFamily } = query

  const handleBack = useCallback(() => {
    history.push(paths.home())
  }, [])

  return (
    <Layout>
      <CardLayout>
        <Image imageName='GroupImage' />
        <Text size={20} type="Bold">เลือกแผนสวัสดิการสำเร็จ</Text>
        <BenefitLayout>
          <Text size={18} type="Medium">สรุปการเลือกแผนสวัสดิการของคุณ​</Text>
          <Text>แผนสวัสดิการ : {benefitType}</Text>
          {BenefitType.FLEXi === benefitType && (
            <>
              <Text>คุณได้รับความคุ้มครองตามแผน : {myBenefitPlan}</Text>
              <Text>แลกบัตรประกันสุขภาพของคู่สมรส-บุตร : {benefitFamily}</Text>
            </>

          )}
        </BenefitLayout>
        <Button onClick={handleBack}>กลับไปหน้าแรก</Button>
      </CardLayout>
    </Layout>
  )
}

export default Complete