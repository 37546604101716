import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import InfographicQuizFalse from '../../assets/images/base/infographic-quiz-false.svg'
import Button from '../../components/common/Button'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import * as paths from '../../constant/path'
import { useBuzzebeesLogin } from '../../services/auth/auth-query'
import { useGetUser } from '../../services/user/user-query'
import { useVisibility } from '../../utils/custom-hook'

const LayoutModal = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
`

const LayoutButtonModal = styled(Button)`
  width: 300px;
  margin: 50px 0 0 0;
`
const Icon = styled.img`
  width: 150px;
`
const Shop = () => {
  const history = useHistory()
  const { data: user } = useGetUser()
  const { mutate: loginBuzzebees } = useBuzzebeesLogin()
  const [modalVisible, modalAction] = useVisibility()
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (user)
      loginBuzzebees({
        identityProfile: `BST-${user.empId}`,
        firstName: user.firstName,
        lastName: user.lastName,
        gender: user.gender ? user.gender : '',
        birthDate: user.birthDay ? dayjs(user.birthDay).format('YYYY-MM-DD') : '',
        mobileNo: user.phoneNumber,
        email: user.email,
      },
        {
          onSuccess: (data) => {
            if (data.success) {
              if (window.navigator.userAgent.indexOf("Safari")) {
                window.open(data.data.urlAccess, '_self');
              } else {
                window.open(data.data.urlAccess, '_blank');
                history.push(paths.home())
              }
            } else {
              setErrorMessage(data?.message || "เกิดข้อผิดพลาด");
              modalAction.show();
            }
          },
          onError: (e) => {
            modalAction.show();
            if (e.message) {
              console.log(e.message)
              setErrorMessage("เกิดข้อผิดพลาด");
            }
          },
        },)
  }, [])

  return <>
    <Modal
      hideFooter
      visible={modalVisible}
      onCancel={() => {
        modalAction.hide()
        history.push(paths.profile())
      }}
    >
      <LayoutModal>
        <Icon src={InfographicQuizFalse} />
        <br />
        <Text>{errorMessage}</Text>
        {!user?.phoneNumber && <Text>กรุณาเพิ่มหมายเลขโทรศัพท์ ที่เมนู Profile</Text>}
        <LayoutButtonModal
          onClick={() => {
            modalAction.hide()
            history.push(paths.profile())
          }}
        >
          ตกลง
        </LayoutButtonModal>
      </LayoutModal>
    </Modal >
  </>
}

export default Shop
