import { Collapse } from 'antd'
import color from '../../constant/colors'
import { CSSProperties, ReactNode, useMemo } from 'react'
import styled from '@emotion/styled'
import { mobile, mobileVerySmall, useScreen } from '../../utils/responsive-helper'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import { UserClaimList, OPDStatus } from '../../services/opd/opd-types'
import { EClaimStatus } from '../../services/e-claim/e-claim-types'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import dayjs from 'dayjs'

const EClaimCardContainer = styled.div`
  background: ${color.WHITE};
  border: 0.5px solid ${color.BORDER};
  box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
  border-radius: 8px;
  padding: 16px;
`
const EClaimCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const EClaimCardProfileLayout = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`
const EClaimCardProfileTextLayout = styled.div`
  display: flex;
  flex-direction: column;
`
const EClaimCardRightTextLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
`

const CustomCollapse = styled(Collapse)`
  border-radius: 8px;
  border: none;
  background-color: ${color.WHITE};
  box-shadow: '0px 0px 6px 6px rgba(242, 222, 223, 0.4)';
  padding: 0;
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-collapse.ant-collapse-icon-position-left {
    padding: 0 !important;
  }

  .ant-collapse-item {
    padding: 0 !important;
    border-bottom: none;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content {
    border-top: none;
  }
`

const OPDStatusContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
`

const EClaimDate = styled.div`
  display: flex;
`

type CollapeProps = {
  children: ReactNode
  style?: CSSProperties
}

export const CollapseWellbeing = (props: CollapeProps) => {
  const { children, style } = props
  return (
    <CustomCollapse
      expandIcon={() => null}
      style={style}>
      {children}
    </CustomCollapse>
  )
}

type EClaimCardLayoutProps = {
  data: UserClaimList
  status: EClaimStatus
}

export const OpdHistoryCard = (props: EClaimCardLayoutProps) => {
  const { data, status } = props
  const { t } = useTranslation()

  const statusText = useMemo(() => {
    let statusText = ''
    switch (status) {
      case EClaimStatus.UNAPPROVED:
        statusText = t('eClaim.history.status.pending')
        break;
      case EClaimStatus.APPROVED:
        statusText = t('eClaim.history.status.approved')
        break;
      case EClaimStatus.REJECTED:
        statusText = t('eClaim.history.status.rejected')
        break;
      case EClaimStatus.CANCELED:
        statusText = t('eClaim.history.status.cancel')
        break;
      default: statusText = t('eClaim.history.status.pending')
        break;
    }

    return statusText
  }, [status, t])
  const statusColor = useMemo(() => {
    let textColor = ''
    switch (status) {
      case EClaimStatus.UNAPPROVED:
        textColor = color.PENDING
        break;
      case EClaimStatus.APPROVED:
        textColor = color.APPROVED
        break;
      case EClaimStatus.REJECTED:
        textColor = color.REJECT
        break;
      case EClaimStatus.CANCELED:
        textColor = color.CANCELED
        break;
      default: textColor = color.PENDING
        break;
    }

    return textColor
  }, [status])
  const backgroundColor = useMemo(() => {
    let backgroundColor = ''
    switch (status) {
      case EClaimStatus.UNAPPROVED:
        backgroundColor = color.PENDING_LIGHT
        break;
      case EClaimStatus.APPROVED:
        backgroundColor = color.APPROVED_LIGHT
        break;
      case EClaimStatus.REJECTED:
        backgroundColor = color.REJECT_LIGHT
        break;
      case EClaimStatus.CANCELED:
        backgroundColor = color.CANCELED_LIGHT
        break;
      default: backgroundColor = color.PENDING_LIGHT
        break;
    }

    return backgroundColor
  }, [status])

  const exchangeCoin = useMemo(() => {
    return data?.userClaimAmountApprove.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0
  }, [data.userClaimAmountApprove])

  return (
    <EClaimCardContainer>
      <EClaimCardWrapper>
        <EClaimCardProfileLayout>
          <Image src={`${config.apiHost}/files/${data.claim.icon}`} />
          <EClaimCardProfileTextLayout>
            <Text size={14} type='Medium' color={color.PRIMARY}>{data.claim.name}</Text>
            <Text size={14} type='Medium' color={color.PRIMARY}>{data.claim.nameEn}</Text>
          </EClaimCardProfileTextLayout>
        </EClaimCardProfileLayout>
        <EClaimCardRightTextLayout>
          <OPDStatusContainer backgroundColor={backgroundColor}>
            <Text size={14} type='Bold' color={statusColor}>{statusText}</Text>
          </OPDStatusContainer>
          <Text size={18} type='Bold' color={color.PRIMARY}>-{exchangeCoin} ฿</Text>
        </EClaimCardRightTextLayout>
      </EClaimCardWrapper>
      <EClaimDate>
        <Text size={14} type='Regular' color={color.DIM_GRAY}>วันที่ทำรายการเบิก : {dayjs(data?.createdDate).add(543, 'year').format('DD MMM YY')}</Text>
      </EClaimDate>
    </EClaimCardContainer>
  )
}