import React, { useMemo, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/common/Button'
import colors from '../../../constant/colors'
import Text from '../../../components/common/Text'
import Card from '../../../components/common/Card'
import { BenefitType, SelectSelfBenefitTypeParams } from '../../../services/enrollment/enrollment-types'
import { numberWithCommas, useRouter } from '../../../utils/helper'
import { useGetRelative } from '../../../services/relative/relative-query';
import { RelativeStatus } from '../../../services/relative/relative-typed';
import { useHistory } from 'react-router'
import * as paths from '../../../constant/path'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import { useSelfBenefitsTypeSend, useGetEnrollmentSelectPeriod } from '../../../services/enrollment/enrollment-query'
import Loading from '../../../components/common/Loading'

const Layout = styled(Card)`
  margin-top: 24px;
  padding: 24px;
`
const CardLayout = styled(Card)`
  margin-bottom: 16px;
`
const Title = styled(Text)`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`
const SubTitle = styled(Text)`
  text-align: center;
  font-size: 18px;
  margin-bottom: 16px;
`
const Lists = styled.ol`
  padding: 0;
  margin: 0;
`
const ListItem = styled.li`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  column-gap: 8px;
`
const ButtonGroup = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: flex-end;
`
const ButtonStyle = styled(Button)`
  width: fit-content;
  min-width: 120px;
`

const TraditionalSummary = () => {
  const { query } = useRouter<{ id: number }>()
  const { t } = useTranslation()
  const history = useHistory()
  const { mutate: selfBenefitsTypeSend, isLoading } = useSelfBenefitsTypeSend()
  const { data: selectPeriod } = useGetEnrollmentSelectPeriod()

  const { data } = useGetRelative();

  const filterData = useMemo(() => {
    return data?.filter((item) => !['มารดา', 'บิดา'].includes(item.relativeType.name));
  }, [data]);

  const familyStatus = useCallback((status) => {
    switch (status) {
      case RelativeStatus.PENDING:
        return 'รอการอนุมัติ'
      case RelativeStatus.APPROVED:
        return 'อนุมัติแล้ว'
      default:
        return 'รอการอนุมัติ'
    }
  }, [])

  const prev = () => {
    history.push(paths.enrollment())
  }

  const onSubmit = useCallback(() => {
    if (!selectPeriod?.benefitYearId) return

    const params: SelectSelfBenefitTypeParams = {
      selfBenefitTypeId: query.id,
      benefitYearId: selectPeriod.benefitYearId
    }
    selfBenefitsTypeSend(params, {
      onSuccess: () => {
        history.push(paths.enrollmentComplete({
          queryParam: {
            benefitType: BenefitType.Traditional
          }
        }))
      }
    })
  }, [history, query.id, selfBenefitsTypeSend, selectPeriod])

  return (
    <Loading loading={isLoading}>
      <Layout>
        <Title>แผนสวัสดิการของคุณ</Title>
        <SubTitle>Traditional Package</SubTitle>
        <CardLayout>
          <Title>แผนประกันสุขภาพของคุณ (Traditional Insurance)</Title>
          <SubTitle>Core Plan (แผนประกันเดิม)</SubTitle>
        </CardLayout>
        <CardLayout>
          <Title>และบัตรประกันคู่สมรส-บุตร</Title>
          <SubTitle>ตามแผนประกันของบริษัท</SubTitle>
        </CardLayout>
        <ButtonGroup>
          <ButtonStyle onClick={prev} fontColor={colors.CHOICES_PRIMARY} style={{ background: colors.WHITE }}>ย้อนกลับ</ButtonStyle>
          <ButtonStyle onClick={onSubmit}>ยืนยัน</ButtonStyle>
        </ButtonGroup>
      </Layout>
    </Loading>
  )
}

export default TraditionalSummary