import styled from '@emotion/styled'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import Headbar from '../../components/common/HeadBar'
import MyPlayCardList from '../../components/play/MyPlayCardList'
import PlayCardList from '../../components/play/PlayCardList'
import color from '../../constant/colors'
import * as paths from '../../constant/path'
import { useGetUser } from '../../services/user/user-query'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'
import colors from '../../constant/colors'

const Layout = styled.div`
position: relative;
flex: 1;
display: flex;
flex-direction: column;

> * {
  margin-top: 24px;
}
.profile-card {
  margin-right: 24px;
  ${mobile} {
    margin-right: unset;
    margin-bottom: 16px;
  }
}
`

const Container = styled.div`
  width: 791px;
  margin: 0 auto;
`

const FlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  width: 100%;
  margin: 22px auto;
  
  ${mobile} {
    width: 100%;
  }
`
const TabsLayout = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 16px;
    font-family: "IBMPlexSansThai-Bold";
    color: ${color.PRIMARY};
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-family: "IBMPlexSansThai-Medium";
    color: ${color.PRIMARY};
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
    margin: 0 24px;
  }
  
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: ${color.PRIMARY} !important;
  }

  ${mobile} {
    .ant-tabs-nav {
      margin: 0 0;
    }
  }
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  min-height: 500px;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 4px;
  padding: 34px 20px;
  border-radius: 8px;
`

const Play = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <BackgroundHeader />
      <Container>
        <FlexRow>
          <Headbar titleSize={16} title='ข่าวสาร' backWard={paths.home()} />
          <ContentLayout>
            <TabsLayout>
              <Tabs>
                <Tabs.TabPane tab={t('play.events')} key="item-1">
                  <PlayCardList />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('play.myevent')} key="item-2">
                  <MyPlayCardList />
                </Tabs.TabPane>
              </Tabs>
            </TabsLayout>
          </ContentLayout>
        </FlexRow>
      </Container>
    </Layout>
  )
}

export default Play
