import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { reduxForm, getFormValues } from 'redux-form'
import { useSelector } from 'react-redux'
import { message } from 'antd';
import { mobile, useScreen } from '../../utils/responsive-helper'
import { numberWithCommas } from '../../utils/helper'
import color from '../../constant/colors'
import Text from '../../components/common/Text'
import Button from '../../components/common/Button'
import { BenefitTypeChoices, BenefitType } from '../../services/enrollment/enrollment-types'
import { RootState } from '../../redux-store'
import { useDispatch } from 'react-redux'
import { setSteps } from '../../redux-store/enrollment-reducer'

const FooterContentLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 24px;
  padding: 16px 24px;
  border: 1px solid #E5E5E5;

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .right {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  ${mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    .right {
      width: 100%;
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 8px;

  ${mobile} {
    flex-flow: column;
    row-gap: 8px;
  }
`
const SummaryPoint = styled(Row)`
  flex-wrap: wrap;
  > *:not(:last-child) {
    margin-right: 8px;
  }

  ${mobile} {
    margin-bottom: 16px;
  }
`
const ButtonGroup = styled.div`
  display: flex;
  column-gap: 16px;
`
const ButtonStyle = styled(Button)`
  width: 150px;
  height: 45px;
  align-self: flex-end;

  &:hover, focus, active {
    border-color: ${color.CHOICES_PRIMARY} !important;
  }

  ${mobile} {
    margin: auto;
    width: 100%;
  }
`
const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const ENROLLMENT_FORM_NAME = 'Enrollment'
const enchancer = reduxForm({ form: ENROLLMENT_FORM_NAME })
type EditSelfBenefitsFormValues = {
  [key: string]: BenefitTypeChoices[]
}

type FooterContentProps = {
  onSubmit: (next: () => void, type: BenefitType, id: number) => void
  isStep?: boolean
}

const FooterContent = (props: FooterContentProps) => {
  const { onSubmit, isStep = false } = props
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const dispatch = useDispatch()

  const steps = useSelector(
    (state: RootState) => state.enrollment.steps,
  )
  const { current, total } = steps

  const next = () => {
    dispatch(
      setSteps({
        ...steps,
        current: current + 1,
      }),
    )
  }

  const prev = () => {
    dispatch(
      setSteps({
        ...steps,
        current: current - 1,
      }),
    )
  }
  const handleSubmit = () => {
    if (selectedChoices && selectedChoices.choicesName) {
      onSubmit(next, selectedChoices.choicesName, selectedChoices.id)
    } else {
      message.error('Please select at least one benefit')
    }
  }

  const formValuesSelector = getFormValues(ENROLLMENT_FORM_NAME)
  const formValues = useSelector<any, EditSelfBenefitsFormValues>((state) => formValuesSelector(state))

  const selectedChoices = useMemo(() => {
    return Object.keys(formValues || {})
      .map((key) => formValues[key][0])
      .map((v, index) => (v))[0]
  }, [formValues])

  const totalSelectedBenefit = useMemo(() => {
    if (typeof formValues === 'undefined') return
    if (typeof formValues?.ChoiceTypeSelected === 'undefined') return
    // const obj = Object.entries(formValues || {}).map((value, index) => ({
    //   title: value[0],
    //   data: value[1],
    // }))
    const obj = formValues.ChoiceTypeSelected[0] || {}

    return obj
  }, [formValues])

  const totalSelectedPoint = useMemo(() => {
    if (typeof formValues === 'undefined') return 0
    if (typeof formValues?.ChoiceTypeSelected === "undefined") return 0
    // return formValues
    //   ? Object.entries(formValues).reduce((sumTotalPoint, cur) => {
    //     const [, value] = cur
    //     value.forEach((choiceSelected) => {
    //       const point = choiceSelected?.taggedPrice || 0
    //       sumTotalPoint += point
    //     })
    //     return sumTotalPoint
    //   }, 0)
    //   : 0
    return formValues?.ChoiceTypeSelected[0]?.taggedPrice || 0
  }, [formValues])


  if (!totalSelectedBenefit) return <>Loading...</>

  return (
    <FooterContentLayout>
      <div className="left">
        <Text size={14} type="Bold" style={{ textAlign: isMobile ? 'center' : 'start' }}>
          {/* {t('enrollment.editSelfBenefits.summary.title')} */}
          สรุปการเลือกสวัสดิการของคุณ
        </Text>
        <Row>
          <Text size={14} style={{ textAlign: isMobile ? 'center' : 'start' }}>
            {/* {t('enrollment.insurance')} : */}
            แผนสวัสดิการ :
          </Text>
          <Text size={14}>
            {totalSelectedBenefit?.choicesName}
          </Text>
        </Row>
      </div>
      <div className="right">
        <Text
          size={16}
          color={color.FONT_BLACK}
          style={{ textAlign: isMobile ? 'center' : 'end' }}
          type={isMobile ? 'Bold' : 'Regular'}
        >
          {/* {t('enrollment.editSelfBenefits.summary.totalCoin')} */}
          FLEXi Coins ที่ได้รับ
        </Text>
        {/* <Text
          size={14}
          color={color.FONT_BLACK}
          type={isMobile ? 'Bold' : 'Regular'}
          style={{ textAlign: isMobile ? 'center' : 'end' }}
        >
          (จำนวน coin นี้ยังไม่หักการเบิกสวัสดิการวันที่ 1 ม.ค. - 31 พ.ค. 66)
        </Text> */}
        <SummaryPoint
          style={{
            flexDirection: 'column',
            alignItems: isMobile ? 'center' : 'flex-end',
            marginBottom: 16,
          }}
        >
          <Text size={30} type="Bold" color={color.PRIMARY}>
            {numberWithCommas(totalSelectedPoint)}
          </Text>
          {/* <Text size={20} color={color.FONT_LIGHT_GRAY}>
            {remainPoint >= 0 ? (
              <>
                {`(${t('app.remain')} ${numberWithCommas(remainPoint)} ${t('app.coin')})`}
              </>
            ) : (
              <>
                {`(${t('app.payAdditional')} ${numberWithCommas(remainPoint * -1)} ${t('app.coin')})`}
              </>
            )}
          </Text> */}
        </SummaryPoint>
        {isStep ? (
          <ButtonGroup>
            {current > 0 && (
              <ButtonStyle
                background={color.WHITE}
                fontColor={color.CHOICES_PRIMARY}
                onClick={() => prev()}
              >
                Previous
              </ButtonStyle>
            )}
            {current < total - 1 && (

              <ButtonStyle
                onClick={handleSubmit}
                disabled={!selectedChoices}
                background={color.CHOICES_PRIMARY}
              >
                {t('app.continue')}
              </ButtonStyle>
              // <ButtonStyle onClick={() => onClickNext()}>Next</ButtonStyle>
            )}
            {current === total - 1 && (
              <ButtonStyle
                background={color.WHITE}
                fontColor={color.CHOICES_PRIMARY}
                onClick={() => message.success('Processing complete!')}
              >
                Done
              </ButtonStyle>
            )}
          </ButtonGroup>
        ) : (
          <ButtonStyle
            onClick={handleSubmit}
            disabled={!selectedChoices}
            background={color.CHOICES_PRIMARY}
          >
            {t('app.continue')}
          </ButtonStyle>
        )}

      </div>
    </FooterContentLayout>
  )
}

export default FooterContent