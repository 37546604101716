import React, { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { BenefitChoices } from '../../../services/enrollment/enrollment-types'
import colors from '../../../constant/colors'
import Text from '../../../components/common/Text'
import { useDispatch } from 'react-redux'
import { setSteps } from '../../../redux-store/enrollment-reducer'
import { RootState } from '../../../redux-store'
import Button from '../../../components/common/Button'
import { getFormValues } from 'redux-form'
import { useGetRelative } from '../../../services/relative/relative-query';
import { FamilyCard } from './component'
import Welfare from '../Welfare';
import { mobile } from '../../../utils/responsive-helper'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`
const ButtonGroup = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: flex-end;
`
const ButtonStyle = styled(Button)`
  width: fit-content;
  min-width: 120px;
`
const FamilyList = styled.div`
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  ${mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ConditonLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
const LayoutNotFamily = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: center;
  align-items: center;
`

const SummaryFamily = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { data } = useGetRelative();

  const filterData = useMemo(() => {
    return data?.filter((item) => ['มารดา', 'บิดา'].includes(item.relativeType.name));
  }, [data]);

  const steps = useSelector(
    (state: RootState) => state.enrollment.steps,
  )
  const { current, total } = steps

  const next = useCallback(() => {
    dispatch(
      setSteps({
        ...steps,
        current: current + 1,
      }),
    )
  }, [current, dispatch, steps])

  const prev = useCallback(() => {
    dispatch(
      setSteps({
        ...steps,
        current: current - 1,
      }),
    )
  }, [current, dispatch, steps])

  if (filterData?.length === 0) return (
    <LayoutNotFamily>
      <Welfare iconPath={'IconInsurance'} title={'ข้อมูลบิดามารดา (สำหรับกรณีไม่แลกบัตรประกันคู่สมรส-บุตร)'} descriptions={[]} />
      <Text>ไม่พบรายชื่อสมาชิกครอบครัว</Text>
      <ButtonGroup>
        <ButtonStyle onClick={prev} fontColor={colors.CHOICES_PRIMARY} style={{ background: colors.WHITE }}>ย้อนกลับ</ButtonStyle>
        <ButtonStyle onClick={next}>ถัดไป</ButtonStyle>
      </ButtonGroup>
    </LayoutNotFamily>
  );

  return (
    <Layout>
      <Welfare iconPath={'IconInsurance'} title={'ข้อมูลบิดามารดา (สำหรับกรณีไม่แลกบัตรประกันคู่สมรส-บุตร)'} descriptions={[]} />
      <FamilyList>
        {filterData?.map((item, index) => <FamilyCard key={index} relative={item} title='ข้อมูลครอบครัว' />)}
      </FamilyList>
      <ConditonLayout>
        <Text size={18} type='Bold'>เงื่อนไข</Text>
        <Text size={16}>1. ชื่อบิดา-มารดาของโดยชอบด้วยกฎหมายของพนักงานและยังมีชีวิตอยู่ ซึ่งได้แจ้งไว้กับบริษัทฯ</Text>
        <Text size={16}>2. กรณีพนักงานที่ไม่เคยลงทะเบียนชื่อคู่สมรส-บุตร หรือ ต้องการเพิ่มชื่อบุตรผู้เอาประกัน หรือ ปรับปรุงข้อมูลเดิมของผู้เอาประกัน สามารถลงทะเบียน หรือ แก้ไขได้ในข้อมูลประกันสุขภาพของพนักงานและคู่สมรส-บุตร</Text>
      </ConditonLayout>
      <ButtonGroup>
        <ButtonStyle onClick={prev} fontColor={colors.CHOICES_PRIMARY} style={{ background: colors.WHITE }}>ย้อนกลับ</ButtonStyle>
        <ButtonStyle onClick={next}>ถัดไป</ButtonStyle>
      </ButtonGroup>
    </Layout>
  )
}

export default SummaryFamily