import styled from '@emotion/styled'
import ImageForeground from '../../assets/images/base/FLEXi-cover.png'
import ImageBackground from '../../assets/images/base/free-i-phone-xr-fill-1-mask.svg'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import LoginForm from './LoginForm'
import LoginAD from './LoginAD'
import { mobile, iphoneXLandscape, tablet, useScreen } from '../../utils/responsive-helper'
import { useState, useCallback } from 'react'
import { UserLoginParam, UserLoginParamAD } from '../../services/auth/auth-types'
import { useLogin, useLoginAD, useLoginType } from '../../services/auth/auth-query'
import { useTranslation } from 'react-i18next'
import { useVisibility } from '../../utils/custom-hook'
import Modal from '../../components/common/Modal'
import Button from '../../components/common/Button'
import { AccountInfo } from '@azure/msal-browser'
import DebugLogin from './DebugLogin'
import config from '../../config'
import Loading from '../../components/common/Loading'

const ssoLogin: any = config.ssoLogin

const Layout = styled.div`
  display: flex;
  height: 100vh;
  > div {
    width: 50%;
  }

  ${mobile}, ${iphoneXLandscape} {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`
const FormLayout = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile}, ${iphoneXLandscape} {
    margin-top: 102px;
  }
`
const ImageLayout = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${ImageBackground});
  background-size: cover;
  ${mobile}, ${iphoneXLandscape} {
    background: unset;
    margin: 48px 0 102px;
  }
  ${tablet} {
    > .text {
      zoom: 0.8;
    }
  }
`
const Image = styled.img`
  width: 100%;
  max-width: 574px;
  margin-bottom: 48px;
`
const ButtonHide = styled.button`
  cursor: pointer;
  height: 10px;
  border: none;
  background: none;
`

// const Logo = styled.img`
//   width: calc(100% - 22px);
// `

const initialValues = {
  username: 'admin@beliveasia.com',
  password: '123456',
}

const Login = (props: any) => {
  const { isMobile, isIphoneXLandscape } = useScreen()
  const [modalVisible, modalAction] = useVisibility()
  const [modalLoginVisible, modalLoginAction] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const [currentUser, setCurrentUser] = useState<AccountInfo>()
  const [isLoadingAD, setIsLoadingAD] = useState(false)
  // const { data } = useLoginType()
  const { t } = useTranslation()

  const { mutate: login } = useLoginAD()

  // env http://52.237.99.252:8080

  const callMsGraph = useCallback(async (token: string) => {
    const headers = new Headers()
    const bearer = `Bearer ${token}`
    headers.append('Authorization', bearer)
    const options = {
      method: 'GET',
      headers: headers,
    }

    return fetch('https://graph.microsoft.com/v1.0/me', options)
      .then((response) => response.json())
      .catch((error) => console.log(error))
  }, [])

  const onSubmitAD = useCallback(async (token) => {
    const accountUser = await callMsGraph(token)
    const dataNew: UserLoginParamAD = {
      email: accountUser.userPrincipalName,
    }
    login(dataNew, {
      onSuccess: (data) => {
        setIsLoadingAD(false)
      },
      onError: (e) => {
        if (e.message) {
          setIsLoadingAD(false)
          setErrorMessage(e.message)
          modalAction.show()
        }
      }
    })
  },
    [login, callMsGraph, modalAction],
  )

  const { mutate: loginDebug, isLoading } = useLogin({
    onError: (e) => {
      if (e.message) {
        setErrorMessage(e.message)
        modalAction.show()
      }
    },
  })

  return (
    <Loading loading={isLoadingAD}>
      <Layout>
        <Modal visible={modalVisible} onCancel={modalAction.hide} hideFooter>
          <div
            style={{
              marginTop: 50,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text>{errorMessage}</Text>
            <Button style={{ width: 300, margin: '50px 0 0 0' }} onClick={modalAction.hide}>
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal visible={modalLoginVisible} onCancel={modalLoginAction.hide} hideFooter>
          <LoginForm
            onSubmit={(form: UserLoginParam) => {
              const { username, password } = form
              loginDebug({
                username,
                password,
              })
            }}
            loading={isLoading}
            // initialValues={['development'].includes(config.appMode) ? initialValues : undefined}
            error={{ password: 'error' }}
          />
        </Modal>


        <FormLayout>
          {['https://app-main-bl-bst-prod.azurewebsites.net'].includes(config.apiHost) && (
            <LoginAD loading={true} onSubmit={onSubmitAD} onLoading={setIsLoadingAD} />
          )}
          {/* <Button style={{ width: 300, margin: '50px 0 0 0' }} onClick={() => window.open(ssoLogin, "_self")}>
          {t('login.button.submitDentsu')}
        </Button> */}

          {
            ['https://app-main-bl-bst-uat.azurewebsites.net'].includes(config.apiHost) && (
              <LoginForm
                onSubmit={(form: UserLoginParam) => {
                  const { username, password } = form
                  loginDebug({
                    username,
                    password,
                  })
                }}
                loading={isLoading}
                // initialValues={['development'].includes(config.appMode) ? initialValues : undefined}
                error={{ password: 'error' }}
              />
            )
          }


          {/* {['development'].includes(config.appMode) && <DebugLogin />} */}
        </FormLayout>
        <ImageLayout>
          <Image src={ImageForeground} />
          {!isMobile && !isIphoneXLandscape && (
            <>
              <Text className="text" type="Bold" size={56} color={color.SECONDARY}>
                {t('login.content.flexBenefits')}
              </Text>
              <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
                {t('login.content.content1')}
              </Text>
              <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
                {t('login.content.content2')}
              </Text>
              <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
                {t('login.content.content3')}
              </Text>

              <ButtonHide onClick={modalLoginAction.show}>{' '}</ButtonHide>
            </>
          )}
        </ImageLayout>
      </Layout>
    </Loading>
  )
}

export default Login
