export type ServicePeriod = {
  code: StatusCode
  message: string
  data: ServicePeriodData | null
}

export type ServicePeriodData = {
  id: number,
  description: string,
  type: string,
  startDate: number,
  endDate: number,
  enable: boolean
}

export enum StatusCode {
  ONLINE_BY_CONDITION = '0000',
  ONLINE = '0001',
}