import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import {
  CheckCurrentProrate,
  CheckRelativeParams,
  CreateRelativeParams,
  DocListTypeList,
  EducationTypeList,
  Relative,
  RelativeBenefitList,
  RelativeList,
  RelativeTypeList,
  UploadFile
} from './relative-typed'

export const APP = '/app'
export const RELATIVE_URL = `${APP}/relative`
export const RELATIVE_BENEFIT_URL = `${RELATIVE_URL}/benefit`
export const RELATIVE_BENEFIT_BY_ID_URL = `${RELATIVE_BENEFIT_URL}`
export const RELATIVE_CHECK_URL = `${RELATIVE_URL}/benefitCheck`
export const EXCHANGE_BENEFIT = `${RELATIVE_URL}/exchangeBenefit`
export const CURRENT_PRORATE = `${RELATIVE_URL}/currentProrate`
export const RELATIVE_TYPE_URL = `${APP}/relative/relative-type-list`
export const EDUCATION_TYPE_URL = `${APP}/relative/educationList`

export const RELATIVE_UPLOAD_FILE = `${RELATIVE_URL}/upload`
export const DOCUMENT_TYPE_URL = `/documentType`
export const GET_DOCUMENT_TYPE_URL = `${RELATIVE_URL}/type`

export const useGetRelative = () => {
  return useQuery<RelativeList | undefined, Error>(
    RELATIVE_URL,
    () => {
      return api.belive.get<RelativeList>(RELATIVE_URL)
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  )
}

export const useGetRelativeHealthInsurance = (id: number) => {
  return useQuery<RelativeList | undefined, Error>(
    RELATIVE_BENEFIT_URL,
    () => {
      return api.belive.get<RelativeList>(`${RELATIVE_BENEFIT_URL}/${id}`)
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!id,
    },
  )
}

export const useGetDocumentType = (relativeTypeId: number) => {
  return useQuery<DocListTypeList, Error>(
    [GET_DOCUMENT_TYPE_URL, relativeTypeId],
    () => {
      return api.belive.get<DocListTypeList>(`${GET_DOCUMENT_TYPE_URL}/${relativeTypeId}${DOCUMENT_TYPE_URL}`)
    },
  )
}


export const useGetRelativeById = (id: number) => {
  return useQuery<Relative>(
    [RELATIVE_URL, id],
    () => {
      return api.belive.get<Relative>(`${RELATIVE_URL}/${id}`)
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!id,
    },
  )
}

export const useGetRelativeTypeList = () => {
  return useQuery(
    RELATIVE_TYPE_URL,
    async () => {
      const response = await api.belive.get<RelativeTypeList>(RELATIVE_TYPE_URL)
      const optionRelative: {
        label: string
        value: string | number
        disabled?: boolean
      }[] = []

      response.map((relative) => {
        const { enable, name, id } = relative
        optionRelative.push({ label: `${name}`, value: id })
      })

      return optionRelative
    }
  )
}

export const useGetEducationTypeList = () => {
  return useQuery(
    EDUCATION_TYPE_URL,
    async () => {
      const response = await api.belive.get<EducationTypeList>(EDUCATION_TYPE_URL)
      const optionEducation: {
        label: string
        value: string | number
        disabled?: boolean
      }[] = []

      response.map((education) => {
        const { enable, name, id } = education
        optionEducation.push({ label: `${name}`, value: id })
      })

      return optionEducation
    }
  )
}

export const useGetRelativeBenefit = (id?: number) => {
  return useQuery<RelativeBenefitList, Error>(
    [RELATIVE_BENEFIT_BY_ID_URL, id],
    () => {
      return api.belive.get<RelativeBenefitList>(`${RELATIVE_BENEFIT_BY_ID_URL}/${id}`)
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      enabled: !!id,
    },
  )
}

export const useExchangeBenefit = () => {
  return useMutation(
    [EXCHANGE_BENEFIT],
    (id: number) => {
      return api.belive.post(`${EXCHANGE_BENEFIT}/${id}`)
    }
  )
}

export const useCheckCurrentProrate = () => {
  return useQuery(
    CURRENT_PRORATE,
    async () => {
      return api.belive.get<CheckCurrentProrate>(`${CURRENT_PRORATE}`)
    }
  )
}

export const useDeleteRelativeById = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [RELATIVE_URL],
    (id: number) => {
      return api.belive.delete(`${RELATIVE_URL}/${id}`)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([RELATIVE_URL])
      },
    }
  )
}

export const useUpdateRelativeById = () => {
  return useMutation(
    RELATIVE_URL,
    (relative: CreateRelativeParams) => {
      return api.belive.post<Relative>(
        RELATIVE_URL,
        { ...relative },
        { contentType: ContentType.JSON }
      )
    },
  )
}

export const useCheckRelative = () => {
  return useMutation(
    RELATIVE_CHECK_URL,
    (relative: CreateRelativeParams) => {
      return api.belive.post<CheckRelativeParams>(
        RELATIVE_CHECK_URL,
        { ...relative },
        { contentType: ContentType.JSON }
      )
    }
  )
}

export const useCreateRelative = () => {
  const queryClient = useQueryClient()
  return useMutation(
    RELATIVE_URL,
    (relative: CreateRelativeParams) => {
      return api.belive.post<Relative>(
        RELATIVE_URL,
        { ...relative },
        { contentType: ContentType.JSON }
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([RELATIVE_URL])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useUploadFileRelative = () => {
  const mutate = useMutation(
    async ({
      files,
      documentTypeId
    }: {
      files: File,
      documentTypeId: number
    }
    ) => {
      return api.belive.post<UploadFile[]>(
        `${RELATIVE_UPLOAD_FILE}?documentTypeId=${documentTypeId}`,
        { files },
        { contentType: ContentType.FORMDATA },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Upload File Success', data)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}