import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import Text from '../../components/common/Text'
import { eClaimDetail } from '../../constant/path'
import { mobile, mobileVerySmall, useScreen } from '../../utils/responsive-helper'

import { Collapse } from 'antd'
import WellBeingCardScg from '../../components/WellBeingCardScg'
import { useGetEClaimListScg } from '../../services/e-claim/e-claim-query'
import CollapseWellbeing from './CollapeseWellbeing'

const WellBeingContainer = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 267px);
  grid-gap: 24px;
  justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
  ${mobile} {
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const EClaimListComponentScg = () => {
  const { Panel } = Collapse
  const { isMobile } = useScreen()

  const { data: eClaimListNewType } = useGetEClaimListScg()
  return (
    <CollapseWellbeing
      style={{
        margin: isMobile ? '0px 16px 0px' : '0px 0px 0px',
      }}
    >
      {/* {eClaimListNewType?.map((eClaimListNewType, index) => {
        const { claimTypeGroupName, blcpClaimTypeWrapperList } = eClaimListNewType
        return (
          <>
            {blcpClaimTypeWrapperList?.map((blcpClaimTypeWrapperList, index) => {
              const { claimName, blcpClaimWrapperList } = blcpClaimTypeWrapperList
              return (
                <Panel
                  header={
                    <Text type="Bold" size={26}>
                      {claimName}
                    </Text>
                  }
                  key={index}
                >
                  <div style={{ paddingBottom: 16, paddingTop: 16, paddingLeft: 16 }}> */}
                    {/* <Text
                      type="Bold"
                      size={20}
                      style={{ paddingBottom: 16, paddingTop: 16, paddingLeft: 16 }}
                    >
                      {claimName}
                    </Text> */}
                    {/* <WellBeingContainer isMobile={isMobile}>
                      {blcpClaimWrapperList?.map((item: any) => {
                        return (
                          <div>
                            <Link
                              key={`${index}-${item.claimId}-${item.claimName}`}
                              to={eClaimDetail({
                                routeParam: {
                                  eClaimId: item.claimId,
                                },
                              })}
                            >
                              <WellBeingCardScg
                                title={item.claimName}
                                icon={item.claimIcon}
                                maxClaimablePrice={item.maxClaimablePrice}
                                availableAmount={item.availableAmount}
                              />
                            </Link>
                          </div>
                        )
                      })}
                    </WellBeingContainer>
                  </div>
                </Panel>
              )
            })}
          </>
        )
      })} */}

    </CollapseWellbeing>
  )
}

export default EClaimListComponentScg
