import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import colors from '../../../constant/colors'
import Text from '../../../components/common/Text'
import { Relative, RelativeStatus } from '../../../services/relative/relative-typed';

const FamilyCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px;
  border: 1px solid ${colors.BORDER};
  border-radius: 8px;
`
const FamilyDetail = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`
const TitleText = styled(Text)`
  text-align: center;
`
const StatusWrapper = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  padding: 4px 8px;
  border-radius: 8px;
`

type RelativeProps = {
  relative: Relative
  title?: string
}
export const FamilyCard = (props: RelativeProps) => {
  const { relative, title = 'ชื่อผู้เอาประกัน' } = props
  const { firstname, lastname, relativeType, status } = relative

  const getStatus = useMemo(() => {
    switch (status) {
      case RelativeStatus.PENDING:
        return 'รอการอนุมัติ'
      case RelativeStatus.APPROVED:
        return 'อนุมัติแล้ว'
      default:
        return 'รอการอนุมัติ'
    }
  }, [status])

  const statusColorText = useMemo(() => {
    switch (status) {
      case RelativeStatus.PENDING:
        return colors.PRIMARY
      case RelativeStatus.APPROVED:
        return colors.WHITE
      default:
        return colors.PRIMARY
    }
  }, [status])
  const statusColorBackground = useMemo(() => {
    switch (status) {
      case RelativeStatus.PENDING:
        return colors.PENDING
      case RelativeStatus.APPROVED:
        return colors.APPROVED
      default:
        return colors.PENDING
    }
  }, [status])

  return (
    <FamilyCardLayout>
      <TitleText size={18} type="SemiBold">
        {title}
      </TitleText>
      <FamilyDetail>
        <Text>ชื่อ-นามสกุล :</Text>
        <Text>{`${firstname} ${lastname}`}</Text>
      </FamilyDetail>
      <FamilyDetail>
        <Text>ความสัมพันธ์ :</Text>
        <Text>{relativeType.name}</Text>
      </FamilyDetail>
      <FamilyDetail>
        <Text>สถานะการอนุมัติบัตร :</Text>
        <StatusWrapper backgroundColor={statusColorBackground}>
          <Text color={statusColorText}>{getStatus}</Text>
        </StatusWrapper>
      </FamilyDetail>
    </FamilyCardLayout>
  )
}