import { Link } from 'react-router-dom'
import { eClaimDetail } from '../../constant/path'
import { useGetEClaimList } from '../../services/e-claim/e-claim-query'
import { EClaimListItem } from '../../services/e-claim/e-claim-types'
import WellBeingCard from '../../components/WellBeingCard'
import styled from '@emotion/styled'
import { mobile, useScreen, mobileVerySmall } from '../../utils/responsive-helper'

const WellBeingContainer = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 267px);
  grid-gap: 24px;
  justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
  ${mobile} {
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const EClaimListComponent = () => {
  const { isMobile } = useScreen()
  const { data: eclaimList } = useGetEClaimList()

  return (
    <WellBeingContainer isMobile={isMobile}>
      {eclaimList?.map((item: EClaimListItem, index) => {
        return (
          <Link
            key={`${index}-${item.claimId}-${item.claimName}`}
            to={eClaimDetail({
              routeParam: {
                eClaimId: item.claimId,
              },
            })}
          >
            <WellBeingCard
              title={item.claimName}
              icon={item.claimIcon}
              maxClaimablePrice={item.maxClaimablePrice}
              availableAmount={item.availableAmount}
            />
          </Link>
        )
      })}
    </WellBeingContainer>
  )
}
export default EClaimListComponent
