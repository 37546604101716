import styled from '@emotion/styled'
import { useState } from 'react'
import { useHistory } from 'react-router'
import Button from '../../components/common/Button'
import Headbar from '../../components/common/HeadBar'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import colors from '../../constant/colors'
import * as paths from '../../constant/path'
import {
  useGetRelative
} from '../../services/relative/relative-query'
import { mobile } from '../../utils/responsive-helper'
import ItemRelative from './ItemRelative'

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
}

${mobile} {
  padding: 0px 16px 40px;
}
`

const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  min-height: 500px;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 4px;
  padding: 34px 20px;
  border-radius: 8px;
`
const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-bottom: 26px;

  div:first-of-type{
    display: flex;
    column-gap: 16px;
  }
`
const ContentRelativeList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const RelativeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`

const ContentEmptyList = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  div:first-of-type{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`

const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`
const CreateButton = styled(Button)`
  margin: 0 0 0 auto;
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const FlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  width: 768px;
  margin: 22px auto;
  
  ${mobile} {
    width: 100%;
  }
`

const Relative = () => {
  const history = useHistory()
  const { data: relativeList } = useGetRelative()

  return (
    <Layout>
      <BackgroundHeader />
      <FlexRow>
        <Headbar titleSize={16} title='ข้อมูลครอบครัว' backWard={paths.home()} />
        <ContentLayout>
          <ContentHeader>
            <div>
              <ImageStyle width={24} height={24} imageName={'iconFamilyInsurance'} />
              <Text size={16} type='Bold' color={colors.BLACK}>ข้อมูลครอบครัว</Text>
            </div>
            <Text style={{ marginLeft: '40px' }} size={12} type='Medium' color={colors.BLACK}>รายชื่อคู่สมรส-บุตร, บิดา-มารดา ของพนักงานำหรับเบิกสวัสดิการ FLEXi Item</Text>
          </ContentHeader>
          {relativeList && relativeList.length > 0 ?
            <ContentRelativeList>
              <RelativeList>
                {relativeList?.map((val) =>
                  <ItemRelative key={val.id} data={val} />
                )}
              </RelativeList>
              <CreateButton ghost fontColor={colors.GREEN} onClick={() => history.push(paths.relativeAdd())}>เพิ่มรายชื่อสมาชิกในครอบครัว</CreateButton>
            </ContentRelativeList>
            :
            <ContentEmptyList>
              <div>
                <ImageStyle width={24} height={24} imageName={'iconPin'} />
                <Text size={16} type='Medium' color={colors.BLACK}>ไม่พบรายชื่อสมาชิกในครอบครัว</Text>
              </div>
              <CreateButton ghost fontColor={colors.GREEN} onClick={() => history.push(paths.relativeAdd())}>เพิ่มรายชื่อสมาชิกในครอบครัว</CreateButton>
            </ContentEmptyList>
          }
        </ContentLayout>
      </FlexRow>
    </Layout >
  )
}

export default Relative