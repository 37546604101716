import { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import Breadcrumb from '../../../components/common/Breadcrumb'
import Text from '../../../components/common/Text'
import Loading from '../../../components/common/Loading'
import Button from '../../../components/common/Button'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import BenefitsCard from './BenefitsCard'
import Image from '../../../components/common/Image'
import SelfBenefits from './SelfBenefits'
import RelativeBenefits from './RelativeBenefits'
import LifeStyleBenefits from './LifeStyleBenefits'
import SummaryExchangeBenefits from './SummaryExchangeBenefits'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { enrollmentInsuranceFamily, enrollmentLeave } from '../../../constant/path'
import color from '../../../constant/colors'
import { useGetUser } from '../../../services/user/user-query'
import {
  useGetAllSelectedHealthCheckupBenefits,
} from '../../../services/health-checkup/health-checkup-query'

import {
  useGetGroupNumber,
} from '../../../services/enrollment/enrollment-query'
import dayjs from 'dayjs'
import { pullAt } from 'lodash'
import {
  getShowModalDownloadHealthCheckupDetail,
  setShowModalDownloadHealthCheckupDetail,
  useCanEditable,
  useExpat,
  useProbation,
} from '../../../utils/helper'
import { Modal } from '../../../components/common/Modal'
import { useVisibility } from '../../../utils/custom-hook'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Footer } from '../ModalAcceptPrivacy'
import { useEffect } from 'react'
import { useGetEnrollmentYear } from '..'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux-store'

const Layout = styled.div`
  margin-bottom: 40px;
`

const BreadcrumbLayout = styled.div`
  margin-top: 24px;

  ${mobile} {
    margin: 24px 16px;
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row;
`

const PaddingTextTop = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  align-self: center;
`

const LayoutTitle = styled(Row)`
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 27px;
`

const LayoutContentModal = styled.div`
  width: 500px;
  padding: 12px;
  ${mobile} {
    width: auto;
  }
`

const FooterModal = styled(Footer)`
  .check-box {
    margin-bottom: 0px;
  }
`

const ButtonStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${color.WHITE};
  border-radius: 5px;
  padding: 16px 32px 16px 32px;
`

const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  min-width: 165px;
`
const ContentSubmitInsuranceModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`
const FooterSubmitInsuranceModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  padding: 24px;
`

const Benefits = () => {
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const history = useHistory()
  const { benefitYearId, startDate, endDate } = useSelector(
    (state: RootState) => state.enrollment.enrollment,
  )
  const canEditable = useCanEditable(startDate, endDate)
  const isProbation = useProbation()
  const isExpat = useExpat()
  const { data: user } = useGetUser()
  const { data: groupNumber } = useGetGroupNumber()
  const { data: selectedHealthCheckupBenefits, isLoading } = useGetAllSelectedHealthCheckupBenefits(benefitYearId)

  const age = user?.age ? `${user.age}` : '-'
  const name = `${user?.firstName} ${user?.lastName} (${age})`

  // const [initialModal, setInitialModal] = useState(true)
  const [modalInsuranceDetail, setModaInsuranceDetail] = useVisibility()
  const [hideModalInsuranceDetailAgain, setHideModalInsuranceDetailAgain] = useState(
    getShowModalDownloadHealthCheckupDetail() === 'hide' ? true : false,
  )
  const [modalInsuranceSubmit, setModalInsuranceSubmit] = useState(false)

  const onClickToExchangeLeave = useCallback(() => {
    if (benefitYearId) {
      history.push(enrollmentLeave({ routeParam: { benefitYearId } }))
    }
  }, [benefitYearId, history])

  useEffect(() => {
    if (
      (getShowModalDownloadHealthCheckupDetail() === 'show' ||
        !getShowModalDownloadHealthCheckupDetail()) &&
      !isProbation && !isExpat
    ) {
      setModaInsuranceDetail.show()
    }
  }, [])

  const onCloseModalInsuranceDetail = useCallback(() => {
    setModaInsuranceDetail.hide()
  }, [setModaInsuranceDetail])

  console.log(groupNumber)
  const onClickDownloadInsuranceDetail = useCallback(() => {
    window.open(`https://beliveshop.blob.core.windows.net/product/dentsu/2023%20Annual%20Health%20check%20up_Flex%20Ben%20for%20belive-${groupNumber?.healthCheckupGroup?.substring(2, 3)}.pdf`)
  }, [groupNumber])

  const onClickShowModalInsuranceDetail = useCallback(() => {
    setModalInsuranceSubmit(true)
  }, [])
  const onClickHideModalInsuranceDetail = useCallback(() => {
    setModalInsuranceSubmit(false)
  }, [])

  const onChangeInsurance = useCallback(() => { }, [])

  return (
    <Layout>
      <Modal visible={modalInsuranceDetail} onCancel={onCloseModalInsuranceDetail} hideFooter>
        <LayoutContentModal>
          <Text style={{ textAlign: 'center' }}>
            {t('healthCheckup.selfBenefits.modalInsuranceDetail.detail')}
          </Text>
          <Row style={{ marginTop: '36px', marginBottom: '18px' }}>
            <Image
              imageName="iconPDF"
              width={73}
              height={73}
              style={{ margin: 'auto', cursor: 'pointer' }}
              onClick={onClickDownloadInsuranceDetail}
            />
          </Row>
          <Text style={{ textAlign: 'center', marginBottom: '36px' }}>
            {/* {t('healthCheckup.selfBenefits.modalInsuranceDetail.title')} */}
          </Text>

          <FooterModal>
            <Checkbox
              className="check-box"
              onChange={(v) => {
                var isHide = !hideModalInsuranceDetailAgain
                setHideModalInsuranceDetailAgain((prev) => !prev)
                if (isHide) {
                  setShowModalDownloadHealthCheckupDetail('hide')
                } else {
                  setShowModalDownloadHealthCheckupDetail('show')
                }
              }}
              defaultChecked={hideModalInsuranceDetailAgain}
              checked={hideModalInsuranceDetailAgain}
              autoFocus={true}
            >
              <Text size={18} color={color.FONT_BLACK}>
                {t('healthCheckup.selfBenefits.modalInsuranceDetail.notShowAgain')}
              </Text>
            </Checkbox>
          </FooterModal>
        </LayoutContentModal>
      </Modal>
      {/* {!isMobile && ( */}
      <BreadcrumbLayout>
        <Breadcrumb
          overide={(paths) => {
            paths.unshift({ label: 'Home', path: '/' })
            paths[1].label = t('app.breadcrumb.myWelFare')
            paths[2].label = t('app.breadcrumb.myHealthCheckup')
            pullAt(paths, 4)
            return paths
          }}
        />
      </BreadcrumbLayout>
      {/* )} */}
      {/* <LayoutTitle>
        <Text size={28} type="Bold" color={color.FONT_BLACK}>
          แผนประกันของคุณประจำปี {benefitYear}
        </Text>
      </LayoutTitle> */}
      {/* <Row style={{ justifyContent: 'space-between' }}>
        <PaddingTextTop></PaddingTextTop>
        <PaddingTextTop>
          <div
            style={{
              height: '18px',
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            onClick={setModaInsuranceDetail.show}
          >
            <Row>
              <Image
                imageName="iconEye"
                width={20}
                height={15}
                style={{ margin: 'auto', marginRight: '8px' }}
              />
              <div hidden={isMobile}>
                <Text size={18}>ดูรายละเอียดกรมธรรม์</Text>
              </div>
            </Row>
          </div>
        </PaddingTextTop>
      </Row> */}
      {/* <BenefitsCard icon={'iconUserWhite'} title={'ประกันตัวเอง'} desc={name}> */}

      <Loading loading={isLoading}>
        {selectedHealthCheckupBenefits && (
          <SelfBenefits
            selectedHealthCheckupBenefits={selectedHealthCheckupBenefits}
            canEditable={canEditable}
            endDate={endDate}
            onClickShowModalInsuranceDetail={onClickShowModalInsuranceDetail}
          />
        )}
      </Loading>
      {/* </BenefitsCard> */}
      {/* <Loading loading={isLoading}>
        {relativeBenefits && (
          <RelativeBenefits relativeBenefits={relativeBenefits} canEditable={canEditable} />
        )}
      </Loading> */}
      <Row style={{ marginBottom: 16 }}>
        {/* <Image imageName={'iconCoin'} width={32} height={32} />
        <Text size={24} type={'Bold'} style={{ marginLeft: 16 }}>
          แลกเป็นเหรียญ{' '}
        </Text> */}
      </Row>
      <SummaryExchangeBenefits
        // totalAvailableCoins={totalAvailableCoins}
        // totalUsedCoins={totalUsedCoins}
        // totalEarnedCoins={totalEarnedCoins}
        totalRemainingCoins={selectedHealthCheckupBenefits?.totalCost}
      />

      <Modal
        visible={modalInsuranceSubmit}
        onCancel={onClickHideModalInsuranceDetail}
        footerComponent={
          <FooterSubmitInsuranceModal>
            <BenefitSelectButton
              fontColor={color.PRIMARY}
              style={{ background: color.WHITE }}
              onClick={onClickHideModalInsuranceDetail}
            >
              ยกเลิก
            </BenefitSelectButton>
            <BenefitSelectButton onClick={onChangeInsurance}>ยืนยัน</BenefitSelectButton>
          </FooterSubmitInsuranceModal>
        }
      >
        <ContentSubmitInsuranceModal>
          <Text size={32} type="Bold">
            คุณยืนยันแผนประกันภัยสำหรับปี 2566 หรือไม่ ?
          </Text>
          <div>
            <Text>แผนประกันชีวิต: แผน 1</Text>
            <Text>แผนประกันสุขภาพ: แบบผู้ป่วยในแผน 1 , แบบผู้ป่วยนอกแผน 1, แบบทันตกรรมแผน 1</Text>
          </div>
        </ContentSubmitInsuranceModal>
      </Modal>

      {/* {endDate ? (
        <Row
          style={{
            width: '100%',
            justifyContent: 'center',
            marginTop: 16,
            // marginBottom: 40,
          }}
        >
          <Text size={20} color={color.FONT_BLACK}>
            {`(คุณยังสามารถแก้ไขได้ถึง วันที่ ${dayjs(Number(endDate))
              .add(543, 'year')
              .format('DD MMM YYYY')})`}
          </Text>
        </Row>
      ) : null} */}
    </Layout>
  )
}

// type BenefitsCategoryListProp = {
//   children: ReactNode
// }
// export const BenefitsCategoryList = (props: BenefitsCategoryListProp) => {
//   const { children } = props
//   return (
//     <>
//       <div
//         style={{
//           minHeight: 75,
//           borderStyle: 'solid',
//           borderColor: '#f5f7fa',
//           borderWidth: '0 1px 1px 1px',
//           padding: 24,
//         }}
//       >
//         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//           <Text size={22} type={'Bold'}>
//             เสียชีวิตรับเงิน 12 เท่า (แผน A)
//           </Text>

//           <Text size={22} type={'Bold'} color={INPUT_ERROR} style={{ whiteSpace: 'nowrap' }}>
//             -200
//           </Text>
//         </div>
//       </div>

//       <div
//         style={{
//           minHeight: 109,
//           borderStyle: 'solid',
//           borderColor: '#f5f7fa',
//           borderWidth: '0 1px 1px 1px',
//           padding: 32,
//         }}
//       >
//         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
//           {children}
//         </div>
//       </div>
//     </>
//   )
// }

export default Benefits
