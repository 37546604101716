import styled from '@emotion/styled'

import Category from './Category'
import Header from './Header'

import Headbar from '../../components/common/HeadBar'
import Point from '../../components/common/Point'
import * as paths from '../../constant/path'
import { mobile } from '../../utils/responsive-helper'
import colors from '../../constant/colors'

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
}
`
const FlexRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  width: 791px;
  margin: 22px auto;
  
  ${mobile} {
    width: 100%;
  }
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  min-height: 500px;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 4px;
  padding: 34px 20px;
  border-radius: 8px;
`

const News = () => {
  return (
    <Layout>
      <BackgroundHeader />
      <FlexRow>
        <Headbar titleSize={16} title='ข่าวสาร' backWard={paths.home()} />
        <ContentLayout>
          {/* <Point /> */}
          <Header />
          <Category />
        </ContentLayout>
      </FlexRow>
    </Layout>
  )
}

export default News
