import styled from '@emotion/styled'
import { InputProps as AntdInputProps } from 'antd/lib/input'
import { Input as AntdInput } from 'antd'
import { CSSProperties, useCallback, useMemo } from 'react'
import color from '../../constant/colors'
import Text from './Text'
import { normalizeRemoveCommaNoDeciaml, numberWithCommas } from '../../utils/helper'
import { isNaN } from 'lodash'
const InputLayout = styled.div``

export const InputLabel = styled(Text)`
  line-height: normal;
  margin-bottom: 3px;
  color: ${color.INPUT_LABLE};
`
const CustomAntdInput = styled(AntdInput)`
  padding: 8px 8px;
  border-radius: 4px;
  border-color: ${color.DIM_GRAY};
  font-family: IBMPlexSansThai-Medium;
  font-size: 14px;
  line-height: 18px;
  height: 100%;
`
const CustomAntdInputPassword = styled(AntdInput.Password)`
  padding: 13px 17px;
  border-radius: 4px;
  border-color: ${color.DIM_GRAY};
  font-family: IBMPlexSansThai-Medium;
  font-size: 16px;
  line-height: 18px;
`
type Value = string | number | undefined | ''
type InputTypeOption = 'default' | 'password' | 'number'
type LabelType = "Medium" | "Bold" | "SemiBold" | "Text" | "Regular" | undefined

export type InputProps<InputType extends InputTypeOption = InputTypeOption> = {
  label?: string
  labelType?: LabelType
  style?: CSSProperties
  inputStyle?: CSSProperties
  inputType?: InputType
  onChange?: (value: Value) => void
  onBlur?: (value: Value) => void
  labelSize?: number
} & Pick<
  AntdInputProps,
  | 'id'
  | 'value'
  | 'defaultValue'
  | 'placeholder'
  | 'onPressEnter'
  | 'maxLength'
  | 'disabled'
  | 'bordered'
>

const Input = <InputType extends InputTypeOption>(props: InputProps<InputType>) => {
  const {
    value,
    onChange,
    onBlur,
    label,
    labelType,
    style,
    inputStyle = {},
    inputType = 'default',
    labelSize = 14,
    ...restProps
  } = props
  const actuallyValue = useMemo(() => {
    const valueDeleteComma = `${value}`.replaceAll(',', '')
    const isNumberValue =
      valueDeleteComma !== '0' && valueDeleteComma !== '' && !isNaN(Number(valueDeleteComma))
    const _actuallyValue = isNumberValue ? numberWithCommas(Number(valueDeleteComma)) : value
    return _actuallyValue
  }, [value])

  const parseValueToCallback = useCallback(
    <T extends unknown>(value: T, callback?: (value: Value) => void) => {
      const testOnlyNumber = /^[0-9\b]+$/
      if (inputType === 'number') {
        const nesValue = normalizeRemoveCommaNoDeciaml(`${value}`)

        if (testOnlyNumber.test(`${nesValue}`) || (nesValue === '' && !isNaN(nesValue))) {
          callback?.(nesValue)
        }
      } else {
        callback?.(value as Value)
      }
    },
    [inputType],
  )

  const customProps = useMemo(() => {
    return {
      value: inputType === 'number' ? actuallyValue : value,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        parseValueToCallback(event.target.value, onChange)
      },
      onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
        parseValueToCallback(event.target.value, onBlur)
      },
      style: { ...inputStyle },
      ...restProps,
    }
  }, [
    inputType,
    actuallyValue,
    value,
    inputStyle,
    restProps,
    parseValueToCallback,
    onChange,
    onBlur,
  ])

  return (
    <InputLayout style={{ ...style }} {...restProps}>
      {label && <InputLabel size={labelSize} type={labelType}>{label}</InputLabel>}
      {inputType === 'password' ? (
        <CustomAntdInputPassword {...customProps} />
      ) : (
        <CustomAntdInput {...customProps} />
      )}
    </InputLayout>
  )
}

export default Input
