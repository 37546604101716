import styled from '@emotion/styled'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import config from '../../config'
import { RootState } from '../../redux-store'
import { useGetRelativeById } from '../../services/relative/relative-query'
import { FileType } from '../../services/relative/relative-typed'
import { useRouter } from '../../utils/helper'
import { mobile } from '../../utils/responsive-helper'
import RelativeForm from '../RelativeCreate/RelativeForm'

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 24px;
  }

  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }

  ${mobile} {
    padding: 0px 16px 40px;
  }
`

const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`

const RelativeDetail = () => {
  const { query } = useRouter<{ relativeId: number }>()
  const { data: relativeItem } = useGetRelativeById(query.relativeId)
  const relativeFormValue = useSelector((state: RootState) => state.relative.relativeInfo)

  const initialValues = useMemo(() => {
    return {
      id: relativeItem?.id,
      name: `${relativeItem?.firstname} ${relativeItem?.lastname}`,
      idCard: relativeItem?.idCard,
      relativeTypeId: relativeItem?.relativeType?.id || 0,
      birthDate: moment(relativeItem?.birthday),
      educationId: relativeItem?.education?.id || 0,
      files: relativeItem?.documentTypes?.map((val: FileType) => val.files[0]?.name ? `${config.apiHost}/files${val.files[0]?.name}` : val.files[0]?.name),
    }
  }, [relativeItem?.birthday, relativeItem?.documentTypes, relativeItem?.education?.id, relativeItem?.firstname, relativeItem?.id, relativeItem?.idCard, relativeItem?.lastname, relativeItem?.relativeType?.id])

  return (
    <Layout>
      <BackgroundHeader />
      <RelativeForm
        initialValues={initialValues}
        relativeFormValue={relativeFormValue}
        isEditData={true}
      />
    </Layout>
  )
}

export default RelativeDetail