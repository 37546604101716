import { useCallback } from 'react'
import styled from '@emotion/styled'
import { isNumber } from 'lodash'
import { useGetUserCoin, useGetUserCoinBenefitYear } from '../services/user/user-query'
import { CoinBenefitName } from '../services/user/user-typed'
import Card from './common/Card'
import Image, { ImageNameKeys } from './common/Image'
import Text from './common/Text'
import iconCoin from '../assets/images/icons-coin-green-32-px.svg'
import { numberWithCommas, useRouter } from '../utils/helper'
import color from '../constant/colors'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { mobile } from '../utils/responsive-helper'
import { useGetEnrollmentActivePeriod } from '../services/enrollment/enrollment-query'
import { CheckBenefit } from '../pages/Home'

const CardPoint = styled(Card)`
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 16px;
`
const FlexRowBaseLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
`

const ImageStyle = styled(Image)`
  object-fit: none;
  margin-left: 8px;
  align-self: center;
`

const TextPaddingLeft = styled(Text)`
  padding-left: 8px;
`

const TextCenter = styled(Text)`
  text-align: center;
`


const PointGroup = styled.div<{ isTraditional?: boolean }>`
  display: grid;
  grid-template-columns: repeat(${props => props.isTraditional ? '1' : '2'}, 1fr);
  grid-gap: 16px;
  z-index: inherit;

  ${mobile} {
    grid-template-columns: 1fr;
  }
`
const PointContainer = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);
  background-color: ${color.WHITE};
  padding: 24px;
`
const PointLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
  width: 100%;
`
const PointWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
`
const PointLabel = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 4px;
`

type PointCardProps = {
  benefitYearId?: number
  isFlexi?: boolean
  checkBenefit?: CheckBenefit
  isHomePage?: boolean
}

export const PointCardBenefit = (props: PointCardProps) => {
  // const { query } = useRouter()
  // const { benefitYearId = query?.benefityearId } = props
  // const { data: userCoinById } = useGetUserCoinBenefitYear(benefitYearId)
  const { t } = useTranslation()
  const { data: userCoinById } = useGetUserCoin()

  return (
    <CardPoint>
      <FlexRowBaseLine>
        <ImageStyle width={32} height={32} imageName={'iconCoin'} />

        <TextPaddingLeft type="Bold" size={32} color={color.PRIMARY}>
          {' '}
          {userCoinById && isNumber(userCoinById) ? numberWithCommas(Number(userCoinById)) : 0}{' '}
        </TextPaddingLeft>
        <TextPaddingLeft type="SemiBold" size={18}>
          {t('app.coin')}
        </TextPaddingLeft>
      </FlexRowBaseLine>
      <TextCenter type="SemiBold" size={18}>
        {`(1 ${t('app.coin')} = 1 ${t('app.baht')})`}
      </TextCenter>
    </CardPoint>
  )
}

type PointsItems = {
  title: string;
  subTitle?: string;
  userCoin: number | undefined;
}
type PointsItemProps = {
  items: PointsItems;
  iconName?: ImageNameKeys;
  currency?: string;
}
const PointsItem = (props: PointsItemProps) => {
  const { t } = useTranslation()
  const { items, iconName = 'iconPoints', currency = t('app.point') } = props
  return (
    <PointContainer>
      <PointLayout>
        <div>
          <Text className="point-label" size={12} type='SemiBold'>
            {items.title}
          </Text>
          <Text className="point-label" size={12} type={'Medium'}>
            {items.subTitle}
          </Text>
        </div>
        <PointWrapper>
          <Image imageName={iconName} width={48} height={48} />
          <PointLabel>
            <Text size={32} type='Bold'>
              {items.userCoin ? items.userCoin.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}
            </Text>
            <Text size={12}>
              {currency}
            </Text>
          </PointLabel>
        </PointWrapper>
      </PointLayout>
    </PointContainer>
  )
}

export const PointCard = (props: PointCardProps) => {
  const { checkBenefit, isFlexi, isHomePage } = props
  const { t } = useTranslation()
  const { data: activePeriod } = useGetEnrollmentActivePeriod()
  const { data: userCoin } = useGetUserCoinBenefitYear(activePeriod?.benefitYearId || 0)

  const getPoint = useCallback((point) => {
    if (!point) return 0

    return point >= 0 ? point : 0
  }, [])

  if (!userCoin) return <>Loading...</>

  return (
    <>
      {isHomePage ? (
        <>
          {
            checkBenefit?.isSelect && (
              <PointGroup isTraditional={checkBenefit?.isTraditional}>
                {checkBenefit?.isFlexi && (
                  <PointsItem
                    items={{
                      title: 'FLEXi Item',
                      subTitle: 'สำหรับเบิกสวัสดิการ FLEXi Item',
                      userCoin: getPoint(userCoin.find(item => item.name === CoinBenefitName.FlexiCoin)?.balance)
                    }}
                    iconName='iconPoints'
                    currency='coins'
                  />
                )}
                <PointsItem
                  items={{
                    title: 'FLEXi Insurance + FLEXi Rewards',
                    subTitle: 'สำหรับเบิกสวัสดิการ FLEXi Item และ Marketplace',
                    userCoin: getPoint(userCoin.find(item => item.name === CoinBenefitName.FlexiReward)?.balance)
                  }}
                  iconName='iconPointsBlue'
                  currency='coins'
                />
              </PointGroup>
            )
          }
        </>
      ) : (
        <PointGroup isTraditional={!isFlexi}>
          {isFlexi && (
            <PointsItem
              items={{
                title: 'FLEXi Item',
                subTitle: 'สำหรับเบิกสวัสดิการ FLEXi Item',
                userCoin: getPoint(userCoin.find(item => item.name === CoinBenefitName.FlexiCoin)?.balance)
              }}
              iconName='iconPoints'
              currency='coins'
            />
          )}
          <PointsItem
            items={{
              title: 'FLEXi Insurance + FLEXi Rewards',
              subTitle: 'สำหรับเบิกสวัสดิการ FLEXi Item และ Marketplace',
              userCoin: getPoint(userCoin.find(item => item.name === CoinBenefitName.FlexiReward)?.balance)
            }}
            iconName='iconPointsBlue'
            currency='coins'
          />
        </PointGroup>
      )}
    </>
  )
}

export const PointCardOpd = (props: PointCardProps) => {
  const { t } = useTranslation()
  const { data: activePeriod } = useGetEnrollmentActivePeriod()
  const { data: userCoin } = useGetUserCoinBenefitYear(activePeriod?.benefitYearId || 0)

  const getPoint = useCallback((point) => {
    if (!point) return 0

    return point >= 0 ? point : 0
  }, [])

  if (!userCoin) return <>Loading...</>

  return (
    <PointGroup>
      <PointsItem
        items={{
          title: 'วงเงินเบิกส่วนเกินค่ารักษาพยาบาล OPD 6,000 บาทแรก',
          subTitle: 'เบิกได้ 100% ของค่าใช้จ่ายจริง',
          userCoin: getPoint(userCoin.find(item => item.name === CoinBenefitName.FullMedicalCoin)?.balance)

        }}
        iconName='iconOPD'
        currency='บาท'
      />
      <PointsItem
        items={{
          title: 'วงเงินเบิกส่วนเกินค่ารักษาพยาบาล OPD 6,000 บาทหลัง',
          subTitle: 'เบิกได้ 50% ของค่าใช้จ่ายจริง',
          userCoin: getPoint(userCoin.find(item => item.name === CoinBenefitName.HalfMedicalCoin)?.balance)

        }}
        iconName='iconOPD'
        currency='บาท'
      />
    </PointGroup>
  )
}