import { useCallback } from 'react'
import styled from '@emotion/styled'
import Card from '../../components/common/Card'
import Text from '../../components/common/Text'
import Button from '../../components/common/Button'
import FooterContent from '../../components/FooterContent'
import Image from '../../components/common/Image'
import IconSuccess from '../../assets/images/base/infographic-eclaim-success.svg'
import { numberWithCommas, useRouter } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { useHistory } from 'react-router'
import color from '../../constant/colors'
import { eClaim } from '../../constant/path'
import { Divider } from 'antd'
import { useGetEClaimHistoryById } from '../../services/e-claim/e-claim-query'
import config from '../../config'

const Layout = styled.div`
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${mobile} {
    width: 100%;
  }
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: calc(100% - 32px);
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    width: 100%;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const RowCenter = styled(Row)`
  text-align: center;
  justify-content: center;
`

const RowSpaceBetween = styled(Row)`
  margin-top: 24px;
  justify-content: space-between;
`

const LayoutImage = styled(RowCenter)`
  width: 100%;
  height: 135px;

  ${mobile} {
    height: 343px;
  }
`

const LayoutCard = styled(Card)`
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;

  ${mobile} {
    padding: 16px;
    margin-bottom: 40px;
  }
`

const PaddingText = styled.div`
  padding-top: 8px;
`

const PaddingImage = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`

const TextNotWrap = styled(Text)`
  white-space: nowrap;
`

const MarginTop = styled.div`
  margin-top: 64px;

  ${mobile} {
    margin-top: 24px;
  }
`

const ButtonStyle = styled(Button)`
  width: 267px;
  align-self: center;
`
const RemarkLayout = styled.div`

`
const HeaderTextLayout = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`

const DescriptionCard = styled(Card)`
  background-color: #FAF9F6;
  border: 1px solid #E7E2D8;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  width: fit-content;
  align-self: center;
`

const EClaimComplete = () => {
  const { userClaimId } = useRouter().query
  const { isMobile } = useScreen()
  const history = useHistory()
  const { data } = useGetEClaimHistoryById({ id: userClaimId })
  const onClickBackToEnrollment = useCallback(() => {
    history.push(eClaim())
  }, [history])

  if (!data) return null

  return (
    <>
      <BackgroundHeader />
      <Layout>
        <LayoutCard style={{ padding: 50, rowGap: 24 }}>
          <PaddingImage>
            <LayoutImage>
              <Image src={IconSuccess} />
            </LayoutImage>
          </PaddingImage>
          <Text size={24} type='Bold' style={{ textAlign: 'center' }}>ส่งเรื่องเบิกเรียบร้อยแล้ว</Text>
          <DescriptionCard>
            <Text>รายการหมายเลข {data?.userClaimNo}</Text>
            <Text>รายการเบิกอยู่ระหว่างรอรับเอกสารประกอบการเบิกตัวจริง</Text>
            <Text>HR Admin จะตรวจสอบและอนุมัติการจ่ายหลังจากได้รับเอกสารตัวจริงแล้วเท่านั้น</Text>
          </DescriptionCard>
          <RemarkLayout>
            <Text>หมายเหตุ :</Text>
            <ul>
              <li>โปรดส่งเอกสารประกอบการเบิกฉบับจริง RYG: Somchoke (จินลี่) HRA3 /BKK: Pavitra (บี) HRA3</li>
              <li>สามารถตรวจเอกสารเบิกสวัสดิการได้ที่เมนู ‘ประวัติการเบิก’</li>
            </ul>
          </RemarkLayout>
          <ButtonStyle onClick={onClickBackToEnrollment}>กลับไปหน้าแรก</ButtonStyle>
        </LayoutCard>
        <Text size={24} type='Bold'>รายละเอียดการเบิกสวัสดิการ</Text>
        <LayoutCard>
          <HeaderTextLayout>
            <Image src={`${config.apiHost}/files/${data.claim.icon}`} width={40} height={40} />
            <Text size={16} type={'Medium'} color={color.FONT_BLACK}>
              {data.claim.name}{' '}
            </Text>
          </HeaderTextLayout>
          <Divider />
          <RowSpaceBetween>
            <Text size={16} color={color.FONT_BLACK}>
              จำนวนเงินตามใบเสร็จรับเงิน
            </Text>
            <TextNotWrap size={16} type={'Medium'} color={color.FONT_BLACK}>
              {numberWithCommas(data?.userClaimAmount)} บาท
            </TextNotWrap>
          </RowSpaceBetween>
          <RowSpaceBetween>
            <Text size={16} color={color.FONT_BLACK}>
              จำนวนเงินที่สามารถเบิกได้จริง
            </Text>
            <TextNotWrap size={16} type={'Medium'} color={color.FONT_BLACK}>
              {numberWithCommas(data?.userClaimAmountApprove)} บาท
            </TextNotWrap>
          </RowSpaceBetween>
        </LayoutCard>
      </Layout>
    </>
  )
}

export default EClaimComplete
