import { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import Text from '../../components/common/Text'
import { EClaimHistoryItem, EClaimStatus } from '../../services/e-claim/e-claim-types'
import { useCancelEClaim } from '../../services/e-claim/e-claim-query'
import color from '../../constant/colors'
import dayjs from 'dayjs'
import { Divider } from 'antd'
import Button from '../../components/common/Button'
import OutlineButton from '../../components/common/OutlineButton'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import { useTranslation } from 'react-i18next'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import Image from '../../components/common/Image'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: ${color.WHITE};
  padding: 16px;
  border-radius: 8px;
`
const OpdInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
const OpdInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
const EClaimInfoItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
const OpdStatusLayout = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
`
const GroupButtonContainer = styled.div`
  display: flex;
  column-gap: 16px;
`
const TextModal = styled(Text)`
  text-align: center;
`

type OpdInfoProps = {
  id: number
  opdItem?: EClaimHistoryItem
  onCreateNewEClaim: () => void
}
const OpdInfo = (prop: OpdInfoProps) => {
  const { id, opdItem, onCreateNewEClaim } = prop
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const [visible, modalAction] = useVisibility()
  const { mutate: cancelEClaim } = useCancelEClaim()

  const date = useMemo(() => {
    return dayjs(opdItem?.createdDate).locale(language).format('DD MMM YYYY')
  }, [opdItem?.createdDate])

  const statusText = useMemo(() => {
    let statusText = ''
    switch (opdItem?.status) {
      case EClaimStatus.UNAPPROVED:
        statusText = t('eClaim.history.status.pending')
        break;
      case EClaimStatus.APPROVED:
        statusText = t('eClaim.history.status.approved')
        break;
      case EClaimStatus.REJECTED:
        statusText = t('eClaim.history.status.rejected')
        break;
      case EClaimStatus.CANCELED:
        statusText = t('eClaim.history.status.cancel')
        break;
      default: statusText = t('eClaim.history.status.pending')
        break;
    }

    return statusText
  }, [opdItem?.status])

  const statusColor = useMemo(() => {
    let textColor = ''
    switch (opdItem?.status) {
      case EClaimStatus.UNAPPROVED:
        textColor = color.PENDING
        break;
      case EClaimStatus.APPROVED:
        textColor = color.APPROVED
        break;
      case EClaimStatus.REJECTED:
        textColor = color.REJECT
        break;
      case EClaimStatus.CANCELED:
        textColor = color.CANCELED
        break;
      default: textColor = color.PENDING
        break;
    }

    return textColor
  }, [opdItem?.status])

  const backgroundColor = useMemo(() => {
    let backgroundColor = ''
    switch (opdItem?.status) {
      case EClaimStatus.UNAPPROVED:
        backgroundColor = color.PENDING_LIGHT
        break;
      case EClaimStatus.APPROVED:
        backgroundColor = color.APPROVED_LIGHT
        break;
      case EClaimStatus.REJECTED:
        backgroundColor = color.REJECT_LIGHT
        break;
      case EClaimStatus.CANCELED:
        backgroundColor = color.CANCELED_LIGHT
        break;
      default: backgroundColor = color.PENDING_LIGHT
        break;
    }

    return backgroundColor
  }, [opdItem?.status])


  const onBackPath = useCallback(() => {
    history.push(paths.opdHistory())
  }, [history])

  const onCancleButtonPress = useCallback(() => {
    cancelEClaim(id, {
      onSuccess: () => {
        alert('ยกเลิกเรียบร้อย')
        onBackPath()
      },
      onError: () => {
        alert('ไม่สามารถยกเลิกได้')
        onBackPath()
      }
    })
  }, [id, cancelEClaim, onBackPath])



  return (
    <Layout>
      <Modal
        visible={visible}
        hideFooter
        onCancel={() => { modalAction.hide() }}
      >
        <div style={{ margin: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: 32 }}>
          <Image imageName='iconRedBin' width={72} />
          <TextModal>คุณต้องการยกเลิกการเบิกสวัสดิการ ?</TextModal>
          <GroupButtonContainer>
            <Button size="large" background={color.DARK_RED_PRIMARY} borderColor={color.DARK_RED_PRIMARY} onClick={onCancleButtonPress}>ต้องการยกเลิก</Button>
            <OutlineButton size="large" borderColor={color.DARK_RED_PRIMARY} fontColor={color.DARK_RED_PRIMARY} onClick={() => { modalAction.hide() }}>ไม่, เบิกต่อ</OutlineButton>
          </GroupButtonContainer>
        </div>
      </Modal>
      <Text size={24} type="Bold">{t('eClaim.history.detail')}</Text>
      <OpdInfoContainer>
        <OpdInfoWrapper>
          <EClaimInfoItems>
            <Text size={18} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.docNo')}</Text>
            <Text size={16} type="SemiBold">{opdItem?.userClaimNo}</Text>
          </EClaimInfoItems>
          {/* <EClaimInfoItems>
            <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.status.title')}</Text>
            <EClaimStatusLayout backgroundColor={backgroundColor}>
              <Text size={18} type="SemiBold" color={statusColor}>{statusText}</Text>
            </EClaimStatusLayout>
          </EClaimInfoItems> */}
          {opdItem?.status === EClaimStatus.REJECTED && (
            <EClaimInfoItems>
              <Text size={18} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.remarkByReject')}</Text>
              <Text size={16} type="SemiBold">{opdItem?.rejectedReason}</Text>
            </EClaimInfoItems>
          )}
        </OpdInfoWrapper>
      </OpdInfoContainer>
      <OpdInfoContainer>
        <OpdInfoWrapper>
          <EClaimInfoItems>
            <Text size={18} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateClaim')}</Text>
            <Text size={16} type="SemiBold">{date}</Text>
          </EClaimInfoItems>
          {opdItem?.status === EClaimStatus.REJECTED && (
            <EClaimInfoItems>
              <Text size={18} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateApproveReject')}</Text>
              <Text size={16} type="SemiBold">{opdItem?.updatedDate ? dayjs(opdItem?.updatedDate).locale(language).format('DD MMM YYYY') : '-'}</Text>
            </EClaimInfoItems>
          )}
          {opdItem?.status === EClaimStatus.APPROVED && (
            <>
              <EClaimInfoItems>
                <Text size={18} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateApproveReject')}</Text>
                <Text size={16} type="SemiBold">{opdItem?.updatedDate ? dayjs(opdItem?.updatedDate).locale(language).format('DD MMM YYYY') : '-'}</Text>
              </EClaimInfoItems>
              <EClaimInfoItems>
                <Text size={18} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateReceiveMoney')}</Text>
                <Text size={16} type="SemiBold">{opdItem?.paymentDate ? dayjs(opdItem?.paymentDate).locale(language).format('DD MMM YYYY') : '-'}</Text>
              </EClaimInfoItems>
            </>
          )}
        </OpdInfoWrapper>
      </OpdInfoContainer>
      <OpdInfoContainer>
        <OpdInfoWrapper>
          <EClaimInfoItems>
            <Text size={18} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.status.title')}</Text>
            <OpdStatusLayout backgroundColor={backgroundColor}>
              <Text size={16} type="SemiBold" color={statusColor}>{statusText}</Text>
            </OpdStatusLayout>
          </EClaimInfoItems>
        </OpdInfoWrapper>
      </OpdInfoContainer>
      <Divider />
      {opdItem?.status === EClaimStatus.UNAPPROVED && (
        <GroupButtonContainer>
          <Button background={color.DARK_RED} fontColor={color.WHITE} onClick={modalAction.show}>
            {t('eClaim.history.cancelClaim')}
          </Button>
        </GroupButtonContainer>
      )}
      {(opdItem?.status === EClaimStatus.REJECTED || opdItem?.status === EClaimStatus.CANCELED) && (
        <GroupButtonContainer>
          <Button background={color.WHITE} fontColor={color.CHOICES_PRIMARY} onClick={onCreateNewEClaim}>
            {t('eClaim.history.claimAgain')}
          </Button>
        </GroupButtonContainer>
      )}
    </Layout>
  )
}

export default OpdInfo