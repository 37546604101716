import { useTranslation } from 'react-i18next'
import Breadcrumb from '../../../../components/common/Breadcrumb'
import Text from '../../../../components/common/Text'
import color from '../../../../constant/colors'
import {
  useGetUser,
  useGetUserCoin,
  useGetUserCoinBenefitYear,
} from '../../../../services/user/user-query'
import IconUser from '../../../../assets/images/icons-user.svg'
import Image from '../../../../components/common/Image'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useGetAllHealthCheckupSelfBenefits,
  useHealthCheckupSelfBenefitsSend,
} from '../../../../services/health-checkup/health-checkup-query'
import Loading from '../../../../components/common/Loading'
import { useScreen } from '../../../../utils/responsive-helper'
import { numberWithCommas, useCanEditable, useRouter } from '../../../../utils/helper'
import {
  BenefitChoices,
  BenefitsCategoryWrapperList,
  SelfBenefitsSendParams,
} from '../../../../services/enrollment/enrollment-types'
import {
  Layout,
  BreadcrumbLayout,
  Header,
  UserName,
  RedeemButton,
  SummaryPoint,
  FooterContentLayout,
  Row,
  ContentSpace,
  PeriodTabsLayout,
  PeriodTabButton,
} from './shared'
import { notification, Space } from 'antd'
import ModalAcceptPrivacy from '../../ModalAcceptPrivacy'
import ModalAcceptPrivacy2 from '../../ModalAcceptPrivacy2'
import { useVisibility } from '../../../../utils/custom-hook'
import dayjs from 'dayjs'
import { reduxForm, InjectedFormProps, getFormValues, formValues } from 'redux-form'
import { SubGroupBenefitsField } from './SubGroupBenefits'
import { concat } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { healthCheckupBenefits } from '../../../../constant/path'
import { useGetEnrollmentYear } from '../..'
import SelfBenefits from '../SelfBenefits'
import { RootState } from '../../../../redux-store'

// export type SelectedChoices = Partial<BenefitChoices> &
// Pick<BenefitsCategoryWrapperList, 'benefitsCategoryId' | 'benefitsCategoryName'>
export type SelectedChoices = Partial<BenefitChoices>

type EditSelfBenefitsFormValues = {
  [key: string]: SelectedChoices[]
}
const EDIT_SELF_BENEFITS_FORM_NAME = 'EditSelfBenefits'
const enchancer = reduxForm({ form: EDIT_SELF_BENEFITS_FORM_NAME })

type EditSelfBenefitsProps = InjectedFormProps<EditSelfBenefitsFormValues>
const EditSelfBenefits = (props: EditSelfBenefitsProps) => {
  // const { handleSubmit, initialize, dirty } = props
  // const [allBenefits, setAllBenefits] = useState<Array<any>>([])
  // const { t } = useTranslation()
  // const { query } = useRouter()
  // const { isMobile } = useScreen()
  // const [visible, modalAction] = useVisibility()
  // const [visible2, modalAction2] = useVisibility()

  // const { benefitYearId, benefitYear, startDate, endDate } = useSelector(
  //   (state: RootState) => state.enrollment.enrollment,
  // )

  // const { data: user, isLoading: isUserLoading } = useGetUser()
  // const { data: userPoint = 0, isStale: isUserCoinStale } = useGetUserCoin()
  // // const { data: userPointNextYear = 0, isStale: isUserCoinNextYearStale } =
  // //   useGetUserCoinBenefitYear(benefitYearNextId)
  // const { data: allSelfBenefits, isStale: isAllSelfBenefitsStale } =
  //   useGetAllHealthCheckupSelfBenefits(benefitYearId)
  // const { mutate: selfBenefitsSend, isLoading: isSubmitting } = useHealthCheckupSelfBenefitsSend()
  // const canEditable = useCanEditable(startDate, endDate)
  // const history = useHistory()

  // const formValuesSelector = getFormValues(EDIT_SELF_BENEFITS_FORM_NAME)
  // const formValues = useSelector<any, EditSelfBenefitsFormValues>((state) =>
  //   formValuesSelector(state),
  // )

  // useEffect(() => {
  //   let benefits: any[] = []

  //   allSelfBenefits?.map((SelfBenefit) => {
  //     benefits.push(SelfBenefit)
  //     return setAllBenefits(benefits)
  //   })
  // }, [allSelfBenefits])

  // useEffect(() => {
  //   const initailValues = allSelfBenefits
  //     ? allSelfBenefits.reduce((initialValues: EditSelfBenefitsFormValues, cur) => {
  //       // const selected = cur.benefitsCategoryWrapperList.reduce((acc: SelectedChoices[], v) => {
  //       //   const choiceSelected = v.choices.find((k) => k.selected === true)
  //       //   if (choiceSelected)
  //       //     acc.push({
  //       //       benefitsCategoryId: v.benefitsCategoryId,
  //       //       benefitsCategoryName: v.benefitsCategoryName,
  //       //       ...choiceSelected,
  //       //     })
  //       //   return acc
  //       // }, [])
  //       // initialValues[cur.benefitName] = selected

  //       return initialValues

  //     }, {})
  //     : {}
  //   initialize(initailValues)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [allSelfBenefits])

  // const initialPoint = useMemo(() => {
  //   return allSelfBenefits
  //     ? allSelfBenefits[0].choices.reduce((sumTotalPoint, cur) => {
  //       if (cur?.selected === true) {
  //         const point = cur?.taggedPrice || 0
  //         sumTotalPoint += point
  //       }

  //       return sumTotalPoint
  //     }, 0)
  //     : 0

  // }, [allSelfBenefits])

  // const totalSelectedPoint = useMemo(() => {
  //   return formValues
  //     ? Object.entries(formValues).reduce((sumTotalPoint, cur) => {
  //       const [, value] = cur
  //       value.forEach((choiceSelected) => {
  //         const point = choiceSelected?.taggedPrice || 0
  //         sumTotalPoint += point
  //       })
  //       return sumTotalPoint
  //     }, 0)
  //     : 0
  //   // return 0
  // }, [formValues])

  // const remainPoint = useMemo(() => {
  //   return userPoint + initialPoint - totalSelectedPoint
  // }, [initialPoint, totalSelectedPoint, userPoint])

  // const userAge = useMemo(() => {
  //   return user?.age ? user?.age : '-'
  // }, [user])

  // const isSelectAll = useMemo(() => {
  //   const objects = Object.entries(formValues || {})
  //   let selected: any[] = []

  //   objects?.map((object) => (selected = object[1].concat(selected)))

  //   if (selected.length === allBenefits.length) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }, [formValues, allBenefits])
  // //   for (const obj of objects) {
  // //     const [key, value] = obj
  // //     if (value.length === 0) {
  // //       return false
  // //     }
  // //   }
  // //   return true
  // // }, [formValues])
  // const isAvailable = user?.lastEnrollDate > Date.now()

  // const isApiFetched = useMemo(() => {
  //   return !isUserCoinStale && !isAllSelfBenefitsStale && !isSubmitting
  // }, [isAllSelfBenefitsStale, isSubmitting, isUserCoinStale])
  // const canSubmit = useMemo(() => {
  //   return (
  //     isAvailable && allSelfBenefits
  //   )
  // }, [allSelfBenefits, isAvailable, dirty, isSelectAll, remainPoint])

  // const totalSelectedBenefit = useMemo(() => {
  //   const obj = Object.entries(formValues || {}).map((value, index) => ({
  //     title: value[0],
  //     data: value[1],
  //   }))

  //   return obj
  // }, [formValues])

  // const onConfirmSelectedBenefits = useCallback(
  //   (formValues: EditSelfBenefitsFormValues) => {
  //     if (allSelfBenefits) {
  //       const selectedChoices =
  //         Object.keys(formValues)
  //           .map((key) => formValues[key][0])
  //           .map((v, index) => ({ selfBenefitsCategoryId: allSelfBenefits[index].benefitsCategoryId, selfBenefitsChoicesId: v.id, userConsent: true }))

  //       const params: SelfBenefitsSendParams = {
  //         benefitYearId: benefitYearId!,
  //         selectedChoices: selectedChoices,
  //       }
  //       if (selectedChoices) {
  //         selfBenefitsSend(params, {
  //           onSuccess: () => {
  //             notification.success({
  //               message: 'Success',
  //             })
  //             history.push(
  //               healthCheckupBenefits({
  //                 routeParam: {
  //                   benefitYearId: benefitYearId,
  //                 },
  //               }),
  //             )
  //           },
  //           onError: (response: any) => {
  //             if (response.message === 'No update on selected choices') {
  //               notification.error({
  //                 message: t('enrollment.editSelfBenefits.notification.error.noChange'),
  //               })
  //             } else {
  //               notification.error({
  //                 message: t('enrollment.editSelfBenefits.notification.error.detail'),
  //               })
  //             }
  //           },
  //         })
  //       }
  //       modalAction2.hide()
  //     }
  //     // const selectedChoices = 2

  //     // const selectedChoices = Object.entries(formValues).reduce(
  //     //   (acc: SelfBenefitsSendParams, [, value]) => {
  //     //     const temp = value.map((v) => ({
  //     //       selectedChoices: v.id!,
  //     //     }))
  //     //     acc = concat(acc, temp)
  //     //     return acc
  //     //   },
  //     //   [],
  //     // )


  //   },
  //   [benefitYearId, modalAction, selfBenefitsSend, history, allSelfBenefits],
  // )


  // function onConfirmSelectedBenefits2() {
  //   modalAction.hide()
  //   modalAction2.show()
  // }

  // return (
  //   <Layout>
  //     <ModalAcceptPrivacy
  //       visible={visible}
  //       onConfirmClick={
  //         remainPoint >= 0 ? handleSubmit(onConfirmSelectedBenefits) : onConfirmSelectedBenefits2
  //       }
  //       onCloseClick={modalAction.hide}
  //     />

  //     <ModalAcceptPrivacy2
  //       visible={visible2}
  //       onConfirmClick={handleSubmit(onConfirmSelectedBenefits)}
  //       onCloseClick={modalAction2.hide}
  //     />

  //     {/* {!isMobile && ( */}
  //     <BreadcrumbLayout>
  //       <Breadcrumb
  //         overide={(paths) => {
  //           paths.unshift({ label: 'Home', path: '/' })
  //           paths[1].label = t('app.breadcrumb.myWelFare')
  //           paths[2].label = t('app.breadcrumb.myHealthCheckup')
  //           paths[3].label = t('app.breadcrumb.changeHealthCheckup')
  //           return paths
  //         }}
  //       />
  //     </BreadcrumbLayout>
  //     {/* )} */}
  //     {/* <Header>
  //       <Text size={28} type="Bold" color={color.FONT_BLACK}>
  //         {t('healthCheckup.editSelfBenefits.header')}
  //       </Text>
  //     </Header> */}
  //     {/* <UserName>
  //       <div>
  //         <Image imageName="iconUser" />
  //       </div>
  //       <Loading loading={isUserLoading}>
  //         <Row>
  //           <Space className="username" direction="horizontal" size={8}>
  //             <Text size={26} type="Bold" color={color.FONT_BLACK}>
  //               {user?.firstName}
  //             </Text>
  //             <Text size={26} type="Bold" color={color.FONT_BLACK}>
  //               {user?.lastName}
  //             </Text>
  //           </Space>
  //           <Text size={26} type="Bold" color={color.FONT_BLACK}>{`(${userAge} ปี)`}</Text>
  //         </Row>
  //       </Loading>
  //     </UserName> */}
  //     <ContentSpace direction="vertical" size={10}>
  //       {allSelfBenefits?.map((benefit, index) => {
  //         return (
  //           <SubGroupBenefitsField
  //             key={`${benefit.benefitsCategoryId}`}
  //             name={benefit.benefitsCategoryName}
  //             benefit={benefit}
  //           />
  //         )
  //       })}
  //       {/* <SubGroupBenefitsField
  //         name={""}
  //         benefit={allSelfBenefits || []}
  //       /> */}
  //     </ContentSpace>
  //     <FooterContentLayout
  //       style={{
  //         display: 'flex',
  //         flexDirection: isMobile ? 'column' : 'row',
  //         justifyContent: isMobile ? 'center' : 'space-between',
  //         alignItems: isMobile ? 'center' : 'flex-start',
  //         rowGap: 24,
  //         padding: '16px 24px',
  //         border: '1px solid #E5E5E5',
  //       }}
  //     >
  //       <div className="left">
  //         <Text size={14} type="Bold" style={{ textAlign: isMobile ? 'center' : 'start' }}>
  //           {t('healthCheckup.editSelfBenefits.summary.title')}
  //         </Text>
  //         <div>
  //           {totalSelectedBenefit
  //             ? totalSelectedBenefit.map((benefit, index) => (
  //               <Row
  //                 key={index}
  //                 style={{
  //                   flexDirection: isMobile ? 'column' : 'row',
  //                 }}
  //               >
  //                 <Text size={14} style={{ textAlign: isMobile ? 'center' : 'start' }}>
  //                   {t('healthCheckup.healthCheckup')} :
  //                 </Text>
  //                 <Row
  //                   style={{
  //                     flexWrap: 'wrap',
  //                   }}
  //                 >
  //                   {benefit.data.map((data, index) => (
  //                     <Text key={data.id} size={14}>
  //                       {`${data.choicesName?.replace('<br>', '')} ${benefit.data.length === index + 1 ? '' : ','
  //                         } `}
  //                     </Text>
  //                   ))}
  //                 </Row>
  //               </Row>
  //             ))
  //             : null}
  //         </div>
  //       </div>
  //       <div className="right">
  //         <Text
  //           size={16}
  //           color={color.FONT_BLACK}
  //           style={{ textAlign: isMobile ? 'center' : 'end', marginBottom: 8 }}
  //           type={isMobile ? 'Bold' : 'Regular'}
  //         >
  //           {t('healthCheckup.editSelfBenefits.summary.totalCoin')}
  //         </Text>
  //         <SummaryPoint
  //           style={{
  //             flexDirection: 'column',
  //             alignItems: isMobile ? 'center' : 'flex-end',
  //             marginBottom: 24,
  //           }}
  //         >
  //           <Text size={30} type="Bold" color={color.PRIMARY}>
  //             {numberWithCommas(totalSelectedPoint)}
  //           </Text>
  //           {/* <Text size={20} color={color.FONT_BLACK}>
  //             เหรียญ
  //           </Text> */}
  //           <Text size={20} color={color.FONT_LIGHT_GRAY}>
  //             {`(${t('app.remain')} ${numberWithCommas(remainPoint)} ${t('app.coin')})`}
  //           </Text>
  //         </SummaryPoint>

  //         <RedeemButton
  //           onClick={modalAction.show}
  //           disabled={!canSubmit || !isApiFetched}
  //           background={color.PRIMARY}
  //         >
  //           {t('app.continue')}
  //         </RedeemButton>
  //       </div>
  //     </FooterContentLayout>
  //   </Layout>
  // )
  return <></>
}
export default enchancer(EditSelfBenefits)
