import styled from '@emotion/styled'
import color from '../../../constant/colors'
import { useRouter } from '../../../utils/helper'
import Menu from './Menu'
import Noti from './Noti'
import * as paths from '../../../constant/path'
import Profile from './Profile'
import BSTLogo from '../../../assets/images/base/BST_S.png'
import FLEXiLogo from '../../../assets/images/base/FLEXi-logo.png'
import HambergerMenu from './HambergerMenu'
import ShopMenu from './ShopMenu'
import { useScreen, mobile } from '../../../utils/responsive-helper'
import { useGetNotificationCount, useReadNotificationAll } from '../../../services/notification/notification-query'
import { Link } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { useGetAllSelectedBenefits, useGetAllSelfBenefitsType, useGetEnrollmentActivePeriod } from '../../../services/enrollment/enrollment-query'
import { BenefitType } from '../../../services/enrollment/enrollment-types'

const Layout = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${color.WHITE};
  height: 80px;
  border-bottom: 1px solid ${color.BORDER};
  ${mobile} {
    height: 90px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1172px;
  padding: 0 16px;
`

const MenuGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const LogoGroup = styled(Link)`
  display: flex;
  align-items: center;
  column-gap: 16px;
`

const Logo = styled.img`
  height: 96px;
  ${mobile} {
    width: 100px;
  }
`

const AppHeader = () => {
  const { pathname } = useRouter()
  const { isMobile } = useScreen()
  const { data: notiCount = 0, refetch: getNotificationCount } = useGetNotificationCount()
  const { mutate: setReadNotificationAll } = useReadNotificationAll()

  const isHighlightShop = useMemo(() => {
    const inShopBrands = pathname.includes(paths.shopBrand().split('/')[1])
    const inShopCategory = pathname.includes(paths.shopCategory().split('/')[1])
    const inEnrollment = pathname.includes(paths.enrollment().split('/')[1])
    return inShopBrands || inShopCategory || inEnrollment
  }, [pathname])

  // const isShopSection = useMemo(() => {
  //   return (
  //     pathname.startsWith(paths.coupon()) ||
  //     pathname.startsWith(paths.order()) ||
  //     pathname.startsWith(paths.product()) ||
  //     pathname.startsWith(paths.promotion()) ||
  //     pathname.startsWith(paths.promotionCategory()) ||
  //     pathname.startsWith(paths.shop())
  //   )
  // }, [pathname])

  const isShopSection = false
  const { data: period } = useGetEnrollmentActivePeriod()
  const { benefitYearId = 0 } = period || {}
  const { data } = useGetAllSelfBenefitsType(benefitYearId)
  const { data: selectedBenefits } = useGetAllSelectedBenefits(benefitYearId)

  const isFlexi = useMemo(() => {
    return data?.find(item => item.selfBenefitsName === BenefitType.FLEXi)?.id === selectedBenefits?.selectedSelfBenefitId
  }, [data, selectedBenefits?.selectedSelfBenefitId])

  useEffect(() => {
    if (pathname === paths.notification()) {
      setReadNotificationAll()
      getNotificationCount()
    }
  }, [pathname, getNotificationCount, setReadNotificationAll])

  const menuListItem = useMemo(() => {
    return [
      {
        title: 'Home', path: paths.home(),
        isActive: pathname.startsWith(paths.home())
      },
      // { title: 'News', path: paths.news(), isActive: pathname.startsWith(paths.news()) },
      // { title: 'Play', path: paths.play(), isActive: pathname.startsWith(paths.play()) },
      // {
      //   title: 'Well-Being',
      //   path: paths.wellBeing(),
      //   isActive: pathname.startsWith(paths.wellBeing()),
      // },
      {
        title: 'Insurance',
        path: paths.enrollment(),
        isActive: pathname.startsWith(
          paths.enrollment(),
        ),
      },
      // {
      //   title: 'Health Checkup',
      //   path: paths.healthCheckup(),
      //   isActive: pathname.startsWith(
      //     paths.healthCheckup(),
      //   ),
      // },
      {
        title: 'E-Claims',
        path: paths.eClaim(),
        isHidden: !isFlexi,
        isActive: pathname === paths.eClaim(),
      },
      {
        title: 'Marketplace',
        path: paths.shop(),
        isActive: pathname.startsWith(paths.shop())
      },
    ]
  }, [isFlexi, pathname])

  return (
    <>
      {isShopSection ? (
        <ShopMenu />
      ) : (
        <Layout>
          <Header>
            <LogoGroup to={paths.home()}>
              <Logo
                src={BSTLogo}
              />
              <Logo
                src={FLEXiLogo}
                style={{ height: '48px' }}
              />
            </LogoGroup>
            <MenuGroup>
              {!isMobile ? (
                <>
                  {menuListItem.map((menu, index) => (
                    <Menu key={index} path={menu.path} isActive={menu.isActive} isHidden={menu.isHidden}>
                      {menu.title}
                    </Menu>
                  ))}
                  {/* <Link to={paths.notification()}>
                    <Noti count={notiCount} />
                  </Link> */}
                  <Profile />
                </>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <Link to={paths.notification()}>
                    <Noti count={notiCount} />
                  </Link> */}
                  <HambergerMenu count={notiCount} menuList={menuListItem} />
                </div>
              )}
            </MenuGroup>
          </Header>
        </Layout>
      )}
    </>
  )
}

export default AppHeader
