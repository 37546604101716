import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import Text from '../../components/common/Text'
import { useRouter } from '../../utils/helper'

import Image from '../../components/common/Image'
import { useGetTipsDetail } from '../../services/well-being/well-being-query'
import Loading from '../../components/common/Loading'
import config from '../../config'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`
const CardPoint = styled.div`
  display: inline-block;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const CustomStyleImage = styled(Image)`
  width: 100%;
  height: 400px;
`

const FlexRowBaseLineWithBorder = styled(Text)`
  padding-top: 16px;
  margin: 0 20%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 2px #f0f0f0 solid;
  padding-bottom: 22px;
`

const TitleHeader = styled(Text)`
  padding-left: 8px;
`
const HealthTipsDetail = () => {
  const { query } = useRouter<{ health_tip_id: string }>()

  const { data, isLoading } = useGetTipsDetail({ health_tip_id: query.health_tip_id })

  return (
    <Loading loading={isLoading}>
      <Layout>
        <CardPoint>
          <CustomStyleImage src={`${config.apiHost}${data?.thumbnailImage}`} />

          <FlexRowBaseLineWithBorder>
            <TitleHeader size={18}>
              {data?.name}
            </TitleHeader>
          </FlexRowBaseLineWithBorder>
          <FlexRowBaseLineWithBorder>
            <TitleHeader size={14}>
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </TitleHeader>
          </FlexRowBaseLineWithBorder>
        </CardPoint>
      </Layout>
    </Loading>

  )
}

export default HealthTipsDetail
