import Text from '../../components/common/Text'
import color from '../../constant/colors'
import Image from '../../components/common/Image'
import { useEffect, useMemo } from 'react'
import {
  BenefitChoices,
  BenefitsCategoryWrapperList,
  SelfBenefit,
} from '../../services/enrollment/enrollment-types'
import { Content, ContentHeader, useSelectBenefitsHelper, BenefitsCategoryStyled } from './Benefits/EditBenefits/shared'
import ChoicesRadio from './ChoicesRadio'
import { makeField } from '../../components/common/fields/tools'

export type SelectedChoices = Partial<BenefitChoices>

type SubGroupBenefitsProps = {
  benefit: BenefitsCategoryWrapperList
  price?: number
  value?: SelectedChoices[]
  onChange?: (value: SelectedChoices[]) => void
  numberGroupShow?: number
  textButtonSelect?: string
  textSelected?: string
  disabled?: boolean
}
const SubGroupBenefits = (props: SubGroupBenefitsProps) => {
  const { value, onChange, benefit, price = 0, numberGroupShow, textButtonSelect, textSelected, disabled } = props

  const { selectedChoices, onSelectedChoices } = useSelectBenefitsHelper(benefit, price, true)

  const selectedChoicesIds = selectedChoices.map((v) => v.id)[0]

  useEffect(() => {
    if (disabled) {
      onSelectedChoices(benefit.choices.find((k) => k.taggedPrice === 0)!)
      onChange?.(benefit.choices.filter((k) => k.taggedPrice === 0))
    }
  }, [disabled, onChange, benefit, onSelectedChoices])

  useEffect(() => {
    onChange?.(selectedChoices)
  }, [onChange, selectedChoices])
  return (
    <>
      <BenefitsCategoryStyled
        // src={benefitsCategoryIcon}
        style={{
          padding: 0,
          marginBottom: 0
        }}
      >
        <ChoicesRadio
          value={selectedChoicesIds}
          options={benefit.choices.map((v) => ({
            value: v.id,
            label: v.choicesName,
            description: v.choicesDescription,
            price: v?.taggedPrice,
          }))}
          onChange={(v) => {
            onSelectedChoices(benefit.choices.find((k) => k.id === v)!)
          }}
          numberGroupShow={numberGroupShow}
          textButtonSelect={textButtonSelect}
          textSelected={textSelected}
          disabled={disabled}
        />
      </BenefitsCategoryStyled >
    </>
  )
}

export const SubGroupBenefitsField = makeField<SubGroupBenefitsProps>(SubGroupBenefits)
export default SubGroupBenefits
