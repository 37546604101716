import styled from '@emotion/styled'
import { Select } from 'antd'
import { CSSProperties, useMemo } from 'react'
import color from '../../constant/colors'

import { useCallback } from 'react'
import Text from './Text'

const SelectContainer = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
  border-color: ${color.DIM_GRAY};
  }
`
const StyledSelect = styled(Select)`
  width: 100%;
`

export type BaseSelectProps = {
  options: { label: string; value: string | number, disabled?: boolean }[]
  placeholder?: string
  value?: string | number | undefined
  onSelect?: any
  onChange?: any
  disabled?: boolean
  style?: CSSProperties
  defaultValue?: string | number
}
const BaseSelect = (props: BaseSelectProps) => {
  const { options, placeholder, value, onChange, onSelect, disabled = false, style, defaultValue } = props

  const handleChange = useCallback(
    (value) => {
      onChange && onChange(value)
    },
    [onChange],
  )

  const placeholderNode = useMemo(() => {
    return (
      <Text size={14} color="#bfbfbf">
        {placeholder}
      </Text>
    )
  }, [placeholder])

  return (
    <SelectContainer style={style}>
      <StyledSelect
        onChange={handleChange}
        placeholder={placeholderNode}
        value={value}
        size="large"
        onSelect={onSelect ? onSelect : null}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value} disabled={option.disabled}>
            <Text size={14} type="Medium" color={option.disabled ? 'rgba(0,0,0,.25)' : ''}>
              {option.label}
            </Text>
          </Select.Option>
        ))}
      </StyledSelect>
    </SelectContainer>
  )
}

export default BaseSelect
