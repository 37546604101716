import styled from '@emotion/styled'
import c from '../../constant/colors'
import { mobile } from '../../utils/responsive-helper'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import config from '../../config'

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
`

const LayoutImage = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 24px;
  margin-right: 24px;

  .ant-badge-dot {
    top: 8px;
    right: 8px;
  }

  ${mobile} {
    margin-top: 32px;
  }
`

const LayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  margin-top: 22px;

  ${mobile} {
    display: flex;
    flex-direction: column;
  }
`

const LayoutBody = styled.div`
  flex: 1;
`

const LayoutCoin = styled.div`
  justify-content: flex-end;
  margin-bottom: 16px;

  ${mobile} {
    margin-top: 16px;
  }
`

const CustomStyleLastLine = styled.div`
  display: flex;
  flex-direction: row;
`

const CustomStyleImage = styled(Image)`
  width: 107px;
  height: 107px;
`

const CustomStyleTextPoint = styled(Text)`
  margin-left: 16px;
`

const CustomStyleTextPointLabel = styled(Text)`
  margin-top: 4px;
`

type PointHistoryCardProps = {
  imagePath: string
  type: string
  coin: number
  isAdd: number
  createdDate: string
}

const PointHistoryCard = (props: PointHistoryCardProps) => {
  const { t } = useTranslation()
  const { imagePath, type, coin, isAdd, createdDate } = props
  const coinString =
    coin !== undefined ? coin.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0
  const point = isAdd !== 1 ? `-${coinString}` : coinString
  const color = isAdd !== 1 ? c.INPUT_ERROR : c.PRIMARY
  const icon = isAdd !== 1 ? 'iconCoinRedWithBg' : 'iconCoinPrimaryWithBg'

  return (
    <CustomStyleCard>
      <LayoutImage>
        <CustomStyleImage src={`${config.apiHost}${imagePath}`} />
      </LayoutImage>
      <LayoutContent>
        <LayoutBody>
          <Text size={20} type="Medium">
            {type}
          </Text>
          <Text size={16} type="Medium" color={c.LIGHT_GRAY}>
            {t('pointHistory.dealDate') + ' '}
            {createdDate && dayjs(createdDate).isValid()
              ? dayjs(createdDate).add(543, 'year').format('DD MMM YYYY')
              : '-'}
          </Text>
        </LayoutBody>
        <LayoutCoin>
          <CustomStyleLastLine>
            <Image imageName={icon} />
            <CustomStyleTextPoint size={20} type={'Bold'} color={color}>
              {point}
            </CustomStyleTextPoint>
            <CustomStyleTextPointLabel size={16} type={'Medium'} color={color}>
              &nbsp;{t('pointHistory.coin')}
            </CustomStyleTextPointLabel>
          </CustomStyleLastLine>
        </LayoutCoin>
      </LayoutContent>
    </CustomStyleCard>
  )
}

export default PointHistoryCard
