import styled from '@emotion/styled'
import { Collapse } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import WellBeingCardScg from '../../components/WellBeingCardScg'
import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import config from '../../config'
import { default as colors } from '../../constant/colors'
import { opdDetail } from '../../constant/path'
import { useGetOPD } from '../../services/opd/opd-query'
import { EClaimListResponse } from '../../services/e-claim/e-claim-types'
import { useVisibility } from '../../utils/custom-hook'
import { mobile, mobileVerySmall, tablet, useScreen } from '../../utils/responsive-helper'
import { CollapseWellbeing } from './component'
import { OPDItem } from '../../services/opd/opd-types'

const { Panel } = Collapse

const WellBeingContainer = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
  ${mobile} {
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const LayoutHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  user-select: none;
  background-color: ${colors.WHITE}

  :hover {
    cursor: pointer;
  }

  div:first-of-type {
    display: flex;
    column-gap: 10px;
  }

  ${tablet} {
    width: 100%;
  }
`
const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`

const OpdListComponent = () => {
  const { isMobile } = useScreen()
  const history = useHistory()
  const [visible, modalAction] = useVisibility()
  const [OpdItem, setOpdItem] = useState<OPDItem>()

  const { data: OPDResponse } = useGetOPD()

  const OPDData = useMemo(() => {
    if (!OPDResponse) return null

    const data: EClaimListResponse = [
      {
        id: 1,
        name: "เบิกส่วนเกินค่ารักษาพยาบาลผู้ป่วนนอก (OPD)",
        createdDate: 1686675600000,
        createdBy: "Admin",
        updatedDate: 1686675600000,
        updatedBy: "Admin",
        claimTypeGroupId: 0,
        claimList: []
      }
    ]

    data[0]?.claimList.push(OPDResponse)

    return data
  }, [OPDResponse])

  const onClickItems = (OPDItem: OPDItem) => () => {
    setOpdItem(OPDItem)
    modalAction.show()
  }

  const onSubmit = useCallback(() => {
    if (OpdItem?.id)
      history.push(opdDetail({
        routeParam: {
          opdId: OpdItem.id,
        }
      }))
  }, [OpdItem?.id, history])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        hideFooter
        style={{ maxWidth: 750 }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 24,
        }}>
          <Image imageName='iconAlertPrivacy' />
          <Text size={24}>
            {OpdItem?.showDialogMessage?.includes('<!DOCTYPE html>') ? <div dangerouslySetInnerHTML={{ __html: OpdItem?.showDialogMessage }}></div> : OpdItem?.showDialogMessage}
          </Text>
          <Button onClick={onSubmit}>ตกลง</Button>
        </div>
      </Modal>

      <CollapseWellbeing
        style={{
          margin: isMobile ? '0px 16px 0px' : '0px 0px 0px',
        }}
      >
        {OPDData?.map((val, index) => {
          const { name, claimList } = val
          return (
            <Panel
              key={index}
              style={{ marginBottom: '14px' }}
              header={
                <LayoutHeader>
                  <div>
                    <ImageStyle width={24} height={24} imageName={'IconInsurance'} />
                    <Text type="Bold" size={14}>
                      {name}
                    </Text>
                  </div>
                  <Text size={10} type='Medium' color={'#666666'}>ใช้วงเงิน 6,000 บาทแรก และ 6,000 บาทหลังในการเบิก</Text>
                </LayoutHeader>
              }
            >
              <div style={{ backgroundColor: '#ECECEC', padding: 16 }}>
                <WellBeingContainer isMobile={isMobile}>
                  {claimList?.map((item: OPDItem) => {
                    return (
                      <WellBeingCardScg
                        title={item.nameEn}
                        description={item.name}
                        icon={item.icon && `${config.apiHost}/files${item.icon}`}
                        maxClaimablePrice={item.maxClaimableLimit}
                        availableAmount={item.allowedAmount}
                        onClick={onClickItems(item)}
                      />
                    )
                  })}
                </WellBeingContainer>
              </div>
            </Panel >

          )
        })}
      </CollapseWellbeing >
    </>
  )
}

export default OpdListComponent