import styled from '@emotion/styled'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Image, { ImageNameKeys } from '../../components/common/Image'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { useGetUserCoinBenefitYear } from '../../services/user/user-query'
import { mobile } from '../../utils/responsive-helper'

const PointGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  z-index: inherit;

  ${mobile} {
    grid-template-columns: 1fr;
  }
`
const PointCard = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);
  background-color: ${color.WHITE};
  padding: 24px;
`
const PointLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
  width: 100%;
`
const PointWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
`
const PointLabel = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 4px;
`
const PointDivider = styled.div`
  border: 1px solid ${color.BORDER};
  height: 100%;
`
type ArrayMax<T, X extends number, A extends T[] = []> =
  A | ([T, ...A]['length'] extends X ? never : ArrayMax<T, X, [T, ...A]>);
type ArrayRanged<T, N extends number, X extends number> = Exclude<ArrayMax<T, X>, ArrayMax<T, N>>;

type PointsItems = {
  title: string;
  subTitle: string;
  userCoin: number | undefined;
}
type PointsItemProps = {
  items: PointsItems;
  iconName?: ImageNameKeys;
}
const PointsItem = (props: PointsItemProps) => {
  const { items, iconName = 'iconPoints' } = props
  const { t } = useTranslation()
  return (
    <PointCard>
      <PointLayout>
        <div>
          <Text className="point-label" size={12} type={'Medium'}>
            {items.title}
          </Text>
          <Text className="point-label" size={12} type={'Medium'}>
            {items.subTitle}
          </Text>
        </div>
        <PointWrapper>
          <Image imageName={iconName} width={48} height={48} />
          <PointLabel>
            <Text size={32} type='Bold'>
              {items.userCoin ? items.userCoin.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}
            </Text>
            <Text size={12}>
              {t('app.point')}
            </Text>
          </PointLabel>
        </PointWrapper>
      </PointLayout>
    </PointCard>
  )
}

const Points = () => {
  const { t } = useTranslation()
  const { data: userCoin } = useGetUserCoinBenefitYear(1)

  const getPoint = useCallback((point) => {
    if (!point) return 0

    return point >= 0 ? point : 0
  }, [])

  if (!userCoin) return <>Loading...</>

  return (
    <PointGroup>
      <PointsItem
        items={{
          title: userCoin.find(item => item.name === "Flexi Coin") ? 'FLEXi Item' : 'FLEXi Insurance + FLEXi Rewards',
          subTitle: userCoin.find(item => item.name === "Flexi Coin") ? 'สำหรับเบิกสวัสดิการ FLEXi Item' : 'สำหรับเบิกสวัสดิการ FLEXi Insurance + FLEXi Rewards',
          userCoin: userCoin.find(item => item.name === "Flexi Coin") ? getPoint(userCoin.find(item => item.name === "Flexi Coin")?.balance) : 0

        }}
        iconName={userCoin.find(item => item.name === "Flexi Coin") ? 'iconPoints' : 'iconPointsBlue'}
      />
      <PointsItem
        items={{
          title: userCoin.find(item => item.name === "Flexi Rewward") ? 'FLEXi Insurance + FLEXi Rewards' : 'FLEXi Item',
          subTitle: userCoin.find(item => item.name === "Flexi Rewward") ? 'สำหรับเบิกสวัสดิการ FLEXi Item และ Marketplace' : 'สำหรับเบิกสวัสดิการ FLEXi Item',
          userCoin: userCoin.find(item => item.name === "Flexi Rewward") ? getPoint(userCoin.find(item => item.name === "Flexi Rewward")?.balance) : 0

        }}
        iconName={userCoin.find(item => item.name === "Flexi Rewward") ? 'iconPointsBlue' : 'iconPoints'}
      />
    </PointGroup>
  )
}

export default Points
