import React, { useEffect } from 'react'
import './App.css'
import { Routes } from './routes'
import 'antd/dist/antd.css'
import { useLocation } from 'react-router'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import isSameAfter from 'dayjs/plugin/isSameOrAfter'
import isSameBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.locale('th')
dayjs.extend(isSameAfter)
dayjs.extend(isSameBefore)
dayjs.extend(isBetween)

export const App: React.FC<{ id: string }> = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    document.getElementById('content-body')?.scrollTo(0, 0)
  }, [pathname])

  return <Routes />
}
