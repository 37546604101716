import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import Text, { TextProps } from './Text'
import color from '../../constant/colors'

type LinkProps = {
  path: string
} & TextProps

const CustomText = styled(Text)`
  cursor: pointer;
  :hover {
    color: ${color.FONT_LIGHT_GRAY};
  }
`

const TextLink = (props: LinkProps) => {
  const { path, ...rest } = props
  return (
    <Link to={path}>
      <CustomText {...rest} />
    </Link>
  )
}

export default TextLink
