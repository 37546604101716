import React, { useMemo, useEffect, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Steps } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import colors from '../../../constant/colors'
import Card from '../../../components/common/Card'
import { useDispatch } from 'react-redux'
import { setSteps } from '../../../redux-store/enrollment-reducer'
import { RootState } from '../../../redux-store'
import { reduxForm, InjectedFormProps, getFormValues, formValues, change } from 'redux-form'
import { BenefitChoices } from '../../../services/enrollment/enrollment-types'
import SelectBenefit from './SelectBenefit'
import SummaryFamily from './SummaryFamily'
import SummarySelect from './SummarySelect'
import Headbar from '../../../components/common/HeadBar'
import * as paths from '../../../constant/path'
import { useScreen, mobile } from '../../../utils/responsive-helper'
import { useSelectedSelfBenefitTypePresent } from '../../../utils/helper'

const { Step } = Steps

const Layout = styled.div`
  padding: 24px 0 0;
  background-color: #E7E2D8;

`
const HeadBarLayout = styled.div`
  max-width: 950px;
  margin: 0 auto;
`
const CustomStyleCard = styled(Card)`
  width: 100%;
  padding: 32px 0;
`
const CustomMaxWidthCard = styled.div`
  max-width: 950px;
  margin: 0 auto 24px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  ${mobile} {
    padding: 0 16px;
  }
`
const CustomSteps = styled(Steps)`
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${colors.CHOICES_PRIMARY};
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${colors.CHOICES_PRIMARY};
    border-color: ${colors.CHOICES_PRIMARY};
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${colors.WHITE};
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: ${colors.CHOICES_SECONDARY};
    border-color: ${colors.CHOICES_SECONDARY};
  }
  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: ${colors.CHOICES_PRIMARY};
  }
  .ant-steps-item-title {
    font-size: 14px;
    line-height: 20px;
  }
`

const ENROLLMENT_FORM_NAME = 'Enrollment'
const enchancer = reduxForm({ form: ENROLLMENT_FORM_NAME })
type EditSelfBenefitsFormValues = {
  [key: string]: Partial<BenefitChoices>[]
}

const Benefit = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { isFlexi } = useSelectedSelfBenefitTypePresent()

  const { current } = useSelector(
    (state: RootState) => state.enrollment.steps,
  )

  const formValuesSelector = getFormValues(ENROLLMENT_FORM_NAME)
  const formValues = useSelector<any, EditSelfBenefitsFormValues>((state) => formValuesSelector(state))

  useEffect(() => {
    dispatch(
      setSteps({
        current: current,
        total: items.length,
      }),
    )
    setIsLoading(true)
  }, [current, dispatch])

  const items = useMemo(() => ([
    {
      title: 'เลือกแผนประกันสุขภาพของคุณ และ แลกบัตรประกันสุขภาพคู่สมรส-บุตร',
      content: <SelectBenefit />
    },
    {
      title: 'ข้อมูลครอบครัว',
      content: <SummaryFamily />
    },
    {
      title: 'ยืนยันสวัสดิการ',
      content: <SummarySelect />
    }
  ]), []);

  return (
    <Layout>
      <HeadBarLayout>
        <Headbar
          title='เลือกแผนประกันสุขภาพของคุณ (FLEXi Insurance) และ แลกบัตรประกันสุขภาพคู่สมรส-บุตร'
          backWard={paths.enrollment()}
          isBackWard={!isFlexi}
          titleSize={20}
        />
      </HeadBarLayout>
      <CustomStyleCard>
        <CustomMaxWidthCard>
          <CustomSteps
            current={current}
            labelPlacement="vertical"
          >
            {items.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </CustomSteps>
          {items[current].content}
        </CustomMaxWidthCard>
      </CustomStyleCard>
    </Layout>
  )
}

export default enchancer(Benefit)