import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export type RelativeInf = {
  relativeInfo: {
    id: number,
    name: string
    relativeTypeId: number
    birthDate: moment.Moment
    idCard: string
    educationId: number
    files?: string[]
  }
}

export const initState = {
  relativeInfo: {
    id: 0,
    name: '',
    relativeTypeId: 0,
    educationId: 0,
    birthDate: moment(),
    idCard: '',
  },
}

const relativeSlice = createSlice({
  name: 'relative',
  initialState: initState,
  reducers: {
    setRelativeInfo: (state, { payload }: PayloadWithType<RelativeInf['relativeInfo']>) => {
      state.relativeInfo = payload
    },
    clearRelativeInfo: (state) => {
      state.relativeInfo = initState.relativeInfo
    },
  },
})

export const relativeReducer = relativeSlice.reducer

export const { setRelativeInfo, clearRelativeInfo } = relativeSlice.actions
