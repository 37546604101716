import { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import {
  useGetNotification,
  useReadNotification,
} from '../../services/notification/notification-query'

import { useGetUser } from '../../services/user/user-query'
import NotificationCard from './NotificationCard'
import Loading from '../../components/common/Loading'
import Button from '../../components/common/Button'
import { useTranslation } from 'react-i18next'
import * as paths from '../../constant/path'
import { useHistory } from 'react-router'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const LoadingStyle = styled.div`
  display: flex;
  justify-content: center;
`

const CustomerStyleButton = styled(Button)`
  margin-bottom: 10px;
`

const List = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: user } = useGetUser()

  const { userId } = user || {}
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch: refetchNotificationList,
  } = useGetNotification(userId)

  const onClickLoading = useCallback(() => {
    fetchNextPage()
  }, [fetchNextPage])

  const { mutate: setReadNotification } = useReadNotification()

  const onClickCard = useCallback(
    async (notificationId: number, newsId: number, title: string) => {
      try {
        await setReadNotification(notificationId)
        if (title === 'News') history.push(paths.newsDetails({ routeParam: { newsId } }))
        refetchNotificationList()
      } catch { }
    },
    [setReadNotification, refetchNotificationList],
  )

  const contents = useMemo(() => {
    return data?.pages.map(({ data: contents }) => {
      return contents.map((content: any, index: number) => {
        const { id, imagePath, isReadDate, type, content: description, createDate, refId } = content
        return (
          <NotificationCard
            id={id}
            key={index}
            badge={isReadDate}
            imagePath={imagePath}
            title={type}
            description={description}
            date={createDate}
            newsId={refId}
            onCardClick={onClickCard.bind(null, id, refId, type)}
          />
        )
      })
    })
  }, [data])

  return (
    <Loading style={{ height: 100 }} loading={isLoading}>
      <Layout>{contents}</Layout>
      <LoadingStyle>
        <Loading loading={!isLoading && isFetching}>
          <CustomerStyleButton disabled={!hasNextPage} onClick={onClickLoading}>
            {t('notification.seeMore')}
          </CustomerStyleButton>
        </Loading>
      </LoadingStyle>
    </Loading>
  )
}

export default List
