import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Image from '../../components/common/Image';
import Text from '../../components/common/Text';
import colors from '../../constant/colors';
import * as paths from '../../constant/path';
import { SelectedChoice } from '../../services/enrollment/enrollment-types';

const ContentList = styled.div`
  padding: 10px 10px 10px 0;
  width: 50%;
  cursor: pointer;
`
const ItemList = styled.div`
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);
  background: ${colors.WHITE};
  border: 1px solid #C6CEDB;
  border-radius: 8px;
  padding: 16px;
  flex: 1;

  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 8px;
  
  div:nth-of-type(2) {
    flex: 1;
  }
`
const Items = styled.div`
  padding: 2px 4px;
`
const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`
const CardStatus = styled.div`
  padding: 4px;
  width: 100%;
  height: 24px;
  border-radius: 16px;
  background: #F8F2DB;
  border: 1px solid #DFC35F;
`

type HealthInsuranceItemType = {
  data: SelectedChoice
}

const HealthInsuranceItem = (props: HealthInsuranceItemType) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data } = props;

  const onClickItem = () => {
    history.push(paths.healthInsurancePlan({ routeParam: { selfBenefitsChoiceId: data?.selfBenefitsChoiceId || 0 } }))
  }

  return (
    <ContentList onClick={onClickItem}>
      <ItemList>
        <Items>
          <ImageStyle width={40} height={40} imageName={'iconUserItem'} />
        </Items>
        <Items>
          <Text size={16} type='Bold'>{`${data.selfBenefitsChoice.choicesName}`}</Text>
        </Items>
      </ItemList>
    </ContentList >
  )
}

export default HealthInsuranceItem;