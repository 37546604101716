import { createSlice } from '@reduxjs/toolkit'

export type EnrollmentType = {
  enrollment: {
    benefitYearId: number
    startDate: number
    endDate: number
    enrollmentYear: string
  },
  steps: {
    current: number
    total: number
  }
}

export const initState = {
  enrollment: {
    benefitYearId: 0,
    startDate: 0,
    endDate: 0,
    enrollmentYear: '',
  },
  steps: {
    current: 0,
    total: 0,
  }
}

const EnrollmentSlice = createSlice({
  name: 'eClaim',
  initialState: initState,
  reducers: {
    setEnrollment: (state, { payload }: PayloadWithType<EnrollmentType['enrollment']>) => {
      state.enrollment = payload
    },
    clearEnrollment: (state) => {
      state.enrollment = initState.enrollment
    },
    setSteps: (state, { payload }: PayloadWithType<EnrollmentType['steps']>) => {
      state.steps = payload
    },
    clearSteps: (state) => {
      state.steps = initState.steps
    }
  },
})

export const enrollmentReducer = EnrollmentSlice.reducer

export const { setEnrollment, clearEnrollment, setSteps, clearSteps } = EnrollmentSlice.actions
