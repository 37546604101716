import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import Card from '../../components/common/Card'
import Text from '../../components/common/Text'
import Button from '../../components/common/Button'
import FooterContent from '../../components/FooterContent'
import Image from '../../components/common/Image'
import IconSuccess from '../../assets/images/base/infographic-eclaim-success.svg'
import { numberWithCommas, useRouter } from '../../utils/helper'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { useHistory } from 'react-router'
import color from '../../constant/colors'
import { opd, eClaimDetail } from '../../constant/path'
import { Divider } from 'antd'
import config from '../../config'
import { useGetEClaimHistoryById } from '../../services/e-claim/e-claim-query'
import { FileType } from '../../services/e-claim/e-claim-types'
import { useGetEnrollmentActivePeriod, useGetAllSelfBenefitsType, useGetAllSelectedBenefits } from '../../services/enrollment/enrollment-query'
import { BenefitType } from '../../services/enrollment/enrollment-types'
import { Button as AntdButton } from 'antd'
import { useDispatch } from 'react-redux'
import { setClaimInfo } from '../../redux-store/e-claim-reducer'

const Layout = styled.div`
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${mobile} {
    width: 100%;
  }
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: calc(100% - 32px);
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    width: 100%;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const RowCenter = styled(Row)`
  text-align: center;
  justify-content: center;
`

const RowSpaceBetween = styled(Row)`
  margin-top: 24px;
  justify-content: space-between;
`

const LayoutImage = styled(RowCenter)`
  width: 100%;
  height: 135px;

  ${mobile} {
    height: 343px;
  }
`

const LayoutCard = styled(Card)`
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;

  ${mobile} {
    padding: 16px;
    margin-bottom: 40px;
  }
`

const PaddingText = styled.div`
  padding-top: 8px;
`

const PaddingImage = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`

const TextNotWrap = styled(Text)`
  white-space: nowrap;
`

const MarginTop = styled.div`
  margin-top: 64px;

  ${mobile} {
    margin-top: 24px;
  }
`

const ButtonStyle = styled(Button)`
  width: 267px;
  align-self: center;
`
const RemarkLayout = styled.div`

`
const HeaderTextLayout = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`

const DescriptionCard = styled(Card)`
  background-color: #FAF9F6;
  border: 1px solid #E7E2D8;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  width: fit-content;
  align-self: center;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 16px;
`

const ButtonGroupItem = styled(AntdButton) <{
  background: string
  bordercolor: string
  backgroundhover: string
}>`
  height: auto;
  background: ${({ background }) => background};
  border-radius: 8px;
  border-color: ${({ bordercolor }) => bordercolor};

  :hover,
  :active,
  :focus {
    background: ${({ backgroundhover }) => backgroundhover};
  }
`

const TextButton = styled(Text) <{
  color: string
}>`
  color: ${({ color }) => color};

  :hover,
  :active,
  :focus {
    color: ${({ color }) => color};
  }
`

const EClaimComplete = () => {
  const { userClaimId } = useRouter().query
  const { isMobile } = useScreen()
  const history = useHistory()
  const dispatch = useDispatch()

  const { data } = useGetEClaimHistoryById({ id: userClaimId })
  const { data: activePeriod } = useGetEnrollmentActivePeriod()
  const { data: selfBenefitType } = useGetAllSelfBenefitsType(activePeriod?.benefitYearId || 1)
  const { data: selectedBenefits } = useGetAllSelectedBenefits(activePeriod?.benefitYearId || 1)
  const onClickBackToEnrollment = useCallback(() => {
    history.push(opd())
  }, [history])

  const isFlexi = useMemo(() => {
    return selfBenefitType?.find(item => item.selfBenefitsName === BenefitType.FLEXi)?.id === selectedBenefits?.selectedSelfBenefitId || false

  }, [selfBenefitType, selectedBenefits])

  const onClickBackToOPD = useCallback(() => {
    if (!data) return null

    dispatch(
      setClaimInfo({
        name: '',
        claimName: '',
        amountReceipt: data.userClaimAmount - data.userClaimAmountApprove,
        amountClaim: 0,
        receiptFile: data.userClaimDocumentList.find(item => item.documentType.name === FileType.ReceiptFile)?.filePath || '',
        optionalFileUrl: data.userClaimDocumentList.find(item => item.documentType.name === FileType.OptionalFileUrl)?.filePath || '',
        relativeTypeId: '0',
        receiptDate: data.slipDate,
      }))
    history.push(eClaimDetail({
      routeParam: {
        eClaimId: 9,
      },
      queryParam: {
        isCreateNew: "true",
      }
    }))
  }, [data, dispatch, history])

  if (!data) return null

  return (
    <>
      <BackgroundHeader />
      <Layout>
        <LayoutCard style={{ padding: 50, rowGap: 24 }}>
          <PaddingImage>
            <LayoutImage>
              <Image src={IconSuccess} />
            </LayoutImage>
          </PaddingImage>
          <Text size={24} type='Bold' style={{ textAlign: 'center' }}>ส่งเรื่องเบิกเรียบร้อยแล้ว</Text>
          <DescriptionCard>
            <Text>รายการหมายเลข {data?.userClaimNo}</Text>
            <Text>รายการเบิกอยู่ระหว่างรอรับเอกสารประกอบการเบิกตัวจริง</Text>
            <Text>HR Admin จะตรวจสอบและอนุมัติการจ่ายหลังจากได้รับเอกสารตัวจริงแล้วเท่านั้น</Text>
          </DescriptionCard>
          <RemarkLayout>
            <Text>หมายเหตุ :</Text>
            <ul>
              <li><Text size={14}>โปรดส่งเอกสารประกอบการเบิกฉบับจริง RYG: Somchoke (จินลี่) HRA3 /BKK: Pavitra (บี) HRA3</Text></li>
              <li><Text size={14}>สามารถตรวจเอกสารเบิกสวัสดิการได้ที่เมนู ‘ประวัติการเบิก’</Text></li>
            </ul>
          </RemarkLayout>
          {isFlexi && (data.userClaimAmount - data.userClaimAmountApprove) > 0 ? (
            <ButtonGroup>
              <ButtonGroupItem
                bordercolor={color.CHOICES_PRIMARY}
                background={color.CHOICES_PRIMARY}
                backgroundhover={color.LIGHT_GRAY}
                onClick={onClickBackToOPD}
              >
                <TextButton
                  color={color.WHITE}
                  size={16}>
                  ประสงค์เบิกส่วนขยายวงเงินค่ารักษาพยาบาล<br />
                  ส่วนเกิน OPD ตามจำนวน Flexi coin
                </TextButton>
              </ButtonGroupItem>
              <ButtonGroupItem
                bordercolor={color.CHOICES_PRIMARY}
                background={color.WHITE}
                backgroundhover={color.LIGHT_GRAY}
                onClick={onClickBackToEnrollment}
              >
                <TextButton
                  color={color.CHOICES_PRIMARY}
                  size={16}>
                  ไม่ประสงค์เบิกส่วนขยายวงเงินค่ารักษาพยาบาล<br />
                  ส่วนเกิน OPD ตามจำนวน Flexi coin
                </TextButton>
              </ButtonGroupItem>
            </ButtonGroup>
          ) : (
            <ButtonStyle onClick={onClickBackToEnrollment}>กลับไปหน้าแรก</ButtonStyle>
          )}

        </LayoutCard>
        <Text size={24} type='Bold'>รายละเอียดการเบิกสวัสดิการ</Text>
        <LayoutCard>
          <HeaderTextLayout>
            <Image src={`${config.apiHost}/files/${data.claim.icon}`} width={40} height={40} />
            <Text size={16} type={'Medium'} color={color.FONT_BLACK}>
              {data.claim.name}{' '}
            </Text>
          </HeaderTextLayout>
          <Divider />
          <RowSpaceBetween>
            <Text size={16} color={color.FONT_BLACK}>
              จำนวนเงินตามใบเสร็จรับเงิน
            </Text>
            <TextNotWrap size={16} type={'Medium'} color={color.FONT_BLACK}>
              {numberWithCommas(data?.userClaimAmount)} บาท
            </TextNotWrap>
          </RowSpaceBetween>
          <RowSpaceBetween>
            <Text size={16} color={color.FONT_BLACK}>
              จำนวนเงินที่สามารถเบิกได้จริง
            </Text>
            <TextNotWrap size={16} type={'Medium'} color={color.FONT_BLACK}>
              {numberWithCommas(data?.userClaimAmountApprove)} บาท
            </TextNotWrap>
          </RowSpaceBetween>
        </LayoutCard>
      </Layout>
    </>
  )
}

export default EClaimComplete
