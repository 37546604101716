export type UserType = {
  userId: number
  empId?: number
  idCard: string
  email: string
  profileImagePath?: string
  position: string
  firstName: string
  lastName: string
  firstNameTH: string
  lastNameTH: string,
  lastEnrollDate: any
  phoneNumber: string
  point: number
  wellBeingPoint: number
  topPoint: number
  unavailable: false
  isRequirePassword: false
  coverImagePath?: string
  departments: {
    departmentId: number
    companyId: number
    departmentName: string
    parentDepartmentId?: number
    level: string
    company: {
      companyId: number
      companyName: string
      acronym: string
      unavailable: false
    }
    parentDepartment?: string
    unavailable: false
  }[]
  roles: {
    roleId: number
    roleName: string
    accessControlItems: {
      accessControlItemId: number
      authority: string
      description?: string
    }[]
    unavailable: false
  }[]
  name: string
  phoneNo: string
  salary: number
  leaveDay: number
  birthDay?: number
  requirePassword: false
  age: number,
  probationDate: any,
  insurancePlan: string,
  healthPlanVip: string,
  selectedRelativeBenefitId: number | null,
  selectedSelfBenefitId: number | null,
  gender: string,
}

export type EditUserFormValues = {
  phoneNumber: string
  mail: string
  address: string
}

export type UploadFile = {
  image: string
}

export type EditUserAddressFormValues = {
  id: number | any,
  mail: string,
  phoneNumber: string,
  address: string,
  userId: number | any,
  street: string | any,
  subDistrict: string | any,
  district: string | any,
  province: string | any,
  postcode: number | any,
  country: string | any,
  provinceId: number | any,
  districtId: number | any,
  subDistrictId: number | any,
  countryCode: string | any,
  provinceCode: number | any,
  amphoeCode: number | any,
  subdistrictCode: number | any,
  countryId: number | any,
  createdDate: number | any,
  createdBy: string | any,
  updatedDate: number | any,
  updatedBy: string | any,
  isComfirm: boolean | any
}

export type AddressListResponse = Address[]

export type Address = {
  id: number,
  userId: number,
  street: string,
  subDistrict: string,
  district: string,
  province: string,
  postcode: number,
  country: string,
  provinceId: number,
  districtId: number,
  subDistrictId: number,
  countryCode: string,
  provinceCode: number,
  amphoeCode: number,
  subdistrictCode: number,
  countryId: number,
  createdDate: number,
  createdBy: string,
  updatedDate: number,
  updatedBy: string
}

export enum CoinBenefitName {
  FlexiCoin = 'Flexi Coin',
  FlexiReward = 'Flexi Rewward',
  FullMedicalCoin = 'Full Medical Coin',
  HalfMedicalCoin = 'Half Medical Coin'
}

export type CoinBenefit = {
  name: CoinBenefitName
  balance: number
}

export type CoinBenefitYearResponse = CoinBenefit[]