import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'

import UpcomingEvents from './UpcomingEvents'
import MyWork from './MyWork'
import ProfileCard from '../../components/ProfileCard'
import News from './News'
import LifeStyle from './LifeStyle'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'
import Welfare from './Welfare'
import { useGetAllSelfBenefitsType, useGetAllSelectedBenefits, useGetEnrollmentActivePeriod } from '../../services/enrollment/enrollment-query'
import { BenefitType } from '../../services/enrollment/enrollment-types'
import dayjs from 'dayjs'
import MyApp from './MyApp'
import Profile from './Profile'
import NewsSlide from './NewsSlide'
import Points from './Points'
import { PointCardOpd, PointCard } from '../../components/PointCard'

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  
  ${mobile} {
    width: 100%;
  }
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
  > *:not(:first-of-type) {
    margin-top: 24px;
  }
  ${tablet} {
    max-width: calc(100% - 324px);
  }
  ${mobile} {
    max-width: unset;
  }
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: calc(100% - 32px);
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`

export type CheckBenefit = {
  isFlexi: boolean
  isTraditional: boolean
  isSelect: boolean
}

const Home = () => {
  const { isMobile } = useScreen()
  const { data: { benefitYearId } = {} } = useGetEnrollmentActivePeriod()
  const { data } = useGetAllSelfBenefitsType(benefitYearId)
  const { data: selectedBenefits } = useGetAllSelectedBenefits(benefitYearId)

  const checkBenefit: CheckBenefit = useMemo(() => {
    const isFlexi = data?.find(item => item.selfBenefitsName === BenefitType.FLEXi)?.id === selectedBenefits?.selectedSelfBenefitId || false
    const isTraditional = data?.find(item => item.selfBenefitsName === BenefitType.Traditional)?.id === selectedBenefits?.selectedSelfBenefitId || false
    const isSelect = Boolean(selectedBenefits?.selectedSelfBenefitId) || false

    return { isFlexi, isTraditional, isSelect }
  }, [data, selectedBenefits])

  return (
    <Layout>
      <BackgroundHeader />
      {/* {!isMobile && canEditEnrollment && <Welfare />} */}
      <FlexRow style={{ marginTop: 22 }}>
        {/* <ProfileCard /> */}
        <Profile />
        <PointCard checkBenefit={checkBenefit} isHomePage />
        <PointCardOpd />
        {/* <NewsSlide /> */}
        <MyApp isFlexi={checkBenefit.isFlexi} />
        {/* <News /> */}
      </FlexRow>
      {/* <UpcomingEvents /> */}
    </Layout>
  )
}

export default Home
