import styled from '@emotion/styled'
import { useCallback, useMemo } from 'react'
import BST_S from '../../assets/images/base/BST_S.png'

import {
  Calendar,
  CalendarIcon,
  ChartIcon,
  CloseIcon,
  CloseIcon18,
  FitnessImage,
  GroupImage,
  HamburgerIcon,
  IconAdd,
  IconAlertPrivacy,
  IconAlertSign,
  IconAll,
  IconBack,
  IconBenefit,
  IconChange,
  IconChangeWhite,
  IconClaim,
  IconCoin,
  IconCoinBlack,
  IconCoinBlue,
  IconCoinPrimaryWithBg,
  IconCoinRed,
  IconCoinRedWithBg,
  IconCoinWhite,
  IconCoinWithBackground,
  IconCoinsGold,
  IconComment,
  IconDelete,
  IconDentalInsurance,
  IconEclaimList,
  IconEdit,
  IconEditGreen,
  IconEditWhite,
  IconEnroll,
  IconEnrollBag,
  IconEnrollWhite,
  IconEye,
  IconFamilyInsurance,
  IconGreenBin,
  IconHistory,
  IconIPD,
  IconIdCard,
  IconInsurance,
  IconLocation,
  IconMail,
  IconMenuClaim,
  IconMenuPackage,
  IconMenuShop,
  IconNews,
  IconNext,
  IconNotification,
  IconOPD,
  IconPDF,
  IconPhoneNumber,
  IconPicture,
  IconPin,
  IconPoints,
  IconPointsBlue,
  IconRadio,
  IconRadioMarked,
  IconRedBin,
  IconSync,
  IconTrip,
  IconTripWhite,
  IconUser,
  IconUserGroup,
  IconUserGroupWhite,
  IconUserItem,
  IconUserWhite,
  LogoutIcon,
  MaskImage,
  ReceiptIcon,
  ThaiFlag,
  TrainIcon,
  UsaFlag
} from '../../assets/images/images'

export const ImageName = {
  iconPin: IconPin,
  iconPicture: IconPicture,
  maskImage: MaskImage,
  calendar: Calendar,
  iconCoin: IconCoin,
  calendarIcon: CalendarIcon,
  chartIcon: ChartIcon,
  iconPDF: IconPDF,
  iconEye: IconEye,
  iconHistory: IconHistory,
  iconCoinWithBackground: IconCoinWithBackground,
  iconNews: IconNews,
  closeIcon: CloseIcon,
  hamburgerIcon: HamburgerIcon,
  logoutIcon: LogoutIcon,
  thaiFlag: ThaiFlag,
  usaFlag: UsaFlag,
  closeIcon18: CloseIcon18,
  trainIcon: TrainIcon,
  receiptIcon: ReceiptIcon,
  idCardIcon: IconIdCard,
  iconUser: IconUser,
  iconUserGroup: IconUserGroup,
  iconTrip: IconTrip,
  iconEnroll: IconEnroll,
  iconCoinBlue: IconCoinBlue,
  iconCoinRed: IconCoinRed,
  iconCoinBlack: IconCoinBlack,
  iconEdit: IconEdit,
  iconDelete: IconDelete,
  iconAdd: IconAdd,
  iconEnrollBag: IconEnrollBag,
  iconChange: IconChange,
  iconCoinsGold: IconCoinsGold,
  iconCoinRedWithBg: IconCoinRedWithBg,
  iconCoinPrimaryWithBg: IconCoinPrimaryWithBg,
  fitnessImage: FitnessImage,
  iconAll: IconAll,
  iconUserWhite: IconUserWhite,
  iconUserGroupWhite: IconUserGroupWhite,
  iconTripWhite: IconTripWhite,
  iconEnrollWhite: IconEnrollWhite,
  iconChangeWhite: IconChangeWhite,
  iconCoinWhite: IconCoinWhite,
  IconMenuClaim,
  IconMenuPackage,
  IconMenuShop,
  iconPoints: IconPoints,
  iconNext: IconNext,
  iconComment: IconComment,
  iconRadio: IconRadio,
  iconRadioMarked: IconRadioMarked,
  iconBenefit: IconBenefit,
  iconIPD: IconIPD,
  iconOPD: IconOPD,
  iconDentalInsurance: IconDentalInsurance,
  IconLocation: IconLocation,
  IconMail: IconMail,
  IconPhoneNumber: IconPhoneNumber,
  IconNotification: IconNotification,
  IconSync: IconSync,
  IconBack,
  IconInsurance,
  iconEditGreen: IconEditGreen,
  iconEditWhite: IconEditWhite,
  GroupImage,
  iconPointsBlue: IconPointsBlue,
  iconAlertSign: IconAlertSign,
  iconUserItem: IconUserItem,
  iconFamilyInsurance: IconFamilyInsurance,
  iconGreenBin: IconGreenBin,
  iconRedBin: IconRedBin,
  iconEclaimList: IconEclaimList,
  iconAlertPrivacy: IconAlertPrivacy,
  iconClaim: IconClaim,
}
const currentIcon = ImageName

export type ImageType = typeof currentIcon
export type ImageNameKeys = keyof ImageType

type ImageProps<T extends ImageNameKeys | undefined> =
  | { src: string; fallBack?: string; imageName?: T }
  | { src?: string; fallBack?: string; imageName: T }

const StyleImage = styled.img`
  object-fit: cover;
`

const Image = <T extends ImageNameKeys>(
  props: ImageProps<T> &
    Omit<
      React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
      'src'
    >,
) => {
  const { fallBack, imageName, src, ...rest } = props

  const onError = useCallback((e) => {
    e.target.src = BST_S
  }, [])

  const ImageNameCard = useMemo(() => {
    return imageName ? currentIcon[imageName] : src
  }, [imageName, src])

  return <StyleImage onError={onError} src={ImageNameCard} {...rest} />
}

export default Image
