import styled from '@emotion/styled'
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadfileField,
} from '../../components/common/fields'
import Card from '../../components/common/Card'
import Text from '../../components/common/Text'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'
import moment from 'moment'
import Button from '../../components/common/Button'
import { ComponentType, useEffect, useMemo, useState } from 'react'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OpdInf, setOpdInfo } from '../../redux-store/opd-reducer'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import Input, { InputLabel } from '../../components/common/Input'
import color from '../../constant/colors'
import { useGetUser, useGetUserCoin, useGetUserCoinBenefitYear } from '../../services/user/user-query'
import { CoinBenefitName } from '../../services/user/user-typed'
import {
  useUploadFile,
  useGetRelativeLists,
} from '../../services/e-claim/e-claim-query'
import { UserClaimDocumentList, FileType } from '../../services/e-claim/e-claim-types'
import { Moment } from 'moment'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import { convertBase64ToFile, getToken, numberWithCommas, useRelativeTypeName } from '../../utils/helper'
import { Divider, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import OutlineButton from '../../components/common/OutlineButton'
import Image from '../../components/common/Image'
import config from '../../config'
import { useCreateOpdClaim, useGetClinicLists, useGetRelativeOption } from '../../services/opd/opd-query'
import { OpdFormParams, OPDItem, OPDHistoryItem } from '../../services/opd/opd-types'

const ClaimFormContainer = styled(Card)`
  padding: 16px;
  .layout-upload {
    ${mobile} {
      width: 100%;
    }
    ${mobileVerySmall} {
      width: 100%;
    }
  }
  ${mobile} {
    padding: 16px;
  }
  ${mobileVerySmall} {
    padding: 16px;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  ${mobile} {
    flex-direction: column;
  }
`

const SubmitButton = styled(Button)`
  width: 267px;
`
const TextModal = styled(Text)`
  text-align: center;
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  /* border: 1px solid; */
  margin-bottom: 8px;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`

const RelativeField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 8px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const OPD_FORM_NAME = 'OPD_FORM'
type OPDItemType = OPDItem & OPDHistoryItem
type OPDForm = {
  opdItem?: Partial<OPDItemType>
  editable?: boolean
  opdFormValue?: OpdInf['OpdInfo']
  isPreview?: boolean
  allpayStatus?: boolean
  isHistory?: boolean
  opdId?: number
  isCreateNew?: 'true' | 'false'
}
const required = (value: string) => (value ? undefined : 'error.required')
const requiredDate = (value: Moment) => {
  const x = moment(value).isValid() ? undefined : 'error.required'
  return x
}
const OpdForm: ComponentType<
  OPDForm & InjectedFormProps<OpdFormParams, OPDForm, string>
> = (props) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    invalid,
    opdItem,
    editable = true,
    opdFormValue,
    isPreview = false,
    allpayStatus,
    isHistory = false,
    opdId,
    initialize,
    isCreateNew,
    change,
  } = props

  const formValuesSelector = getFormValues(OPD_FORM_NAME)
  const formValues = useSelector<any, OpdFormParams>(
    (state) => formValuesSelector(state) as OpdFormParams,
  )
  const { t } = useTranslation()
  const [visible, modalAction] = useVisibility()
  const [visibleError, setVisibleError] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const [isClaimCheck, setIsClaimCheck] = useState(false)
  const [claimAmountCheck, setClaimAmountCheck] = useState(0)
  const [claimOpdAmount, setClaimOpdAmount] = useState({
    fullCoinClaimableAmount: 0,
    halfCoinClaimableAmount: 0,
  })
  const [relativeId, setRelativeId] = useState(0)
  const [relativeTypeId, setRelativeTypeId] = useState(formValues?.relativeTypeId || 0)
  const { data: user } = useGetUser()
  const { firstName, lastName } = user || {}

  const fullname = `${firstName} ${lastName}`
  const history = useHistory()
  const { data: userCoin = 0 } = useGetUserCoin()
  const { data: relativesOptions, refetch: refetchRelative } = useGetRelativeOption(opdId)
  const { data: relativeLists, refetch: refetchRelativeLists } = useGetRelativeLists(opdId)
  const { data: clinicLists, refetch: refetchClinicLists } = useGetClinicLists(relativeTypeId)
  const { data: userCoinBenefit } = useGetUserCoinBenefitYear(1)
  const { mutate: createUserOpdClaim, isLoading: isSubmitting } = useCreateOpdClaim()
  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile()
  // const { mutateAsync: uploadReceiptImage, isLoading: isUploadingReceipt, isError: isErrorUplaodReceiptImage } = useUploadReceiptImage()
  const relativeTypeName = useRelativeTypeName()

  useEffect(() => {
    if (isCreateNew) {
      initialize({
        amountReceipt: opdFormValue?.amountReceipt ? opdFormValue?.amountReceipt : undefined,
        amountClaim: opdFormValue?.amountClaim ? opdFormValue?.amountClaim : undefined,
        receiptFile: opdFormValue?.receiptFile ? opdFormValue?.receiptFile : '',
        optionalFileUrl: opdFormValue?.optionalFileUrl ? opdFormValue?.optionalFileUrl : '',
        relativeTypeId: opdFormValue?.relativeTypeId ? opdFormValue?.relativeTypeId : '0',
        receiptDate: opdFormValue?.receiptDate ? opdFormValue?.receiptDate : undefined,
        clinicType: opdFormValue?.clinicType ? opdFormValue?.clinicType : undefined,
      })
    } else {
      initialize({
        amountReceipt: formValues?.amountReceipt ? formValues?.amountReceipt : undefined,
        amountClaim: formValues?.amountClaim ? formValues?.amountClaim : undefined,
        receiptFile: formValues?.receiptFile ? formValues?.receiptFile : undefined,
        optionalFileUrl: formValues?.optionalFileUrl ? formValues?.optionalFileUrl : undefined,
        relativeTypeId: formValues?.relativeTypeId ? formValues?.relativeTypeId : '0',
        receiptDate: formValues?.receiptDate ? formValues?.receiptDate : undefined,
        clinicType: formValues?.clinicType ? formValues?.clinicType : undefined,
      })
    }
  }, [])

  useEffect(() => {
    const relativeTypeId = relativeLists?.find((item) => item.typeId === Number(formValues?.relativeTypeId))
    setRelativeTypeId(relativeTypeId?.typeId || 0)
    setRelativeId(relativeTypeId?.id || 0)
  }, [formValues?.relativeTypeId, relativeLists])

  useEffect(() => {
    change('clinicType', '')
    refetchClinicLists()
    setIsClaimCheck(false)
  }, [relativeTypeId])

  // const amountClaimFormated = useMemo(() => {
  //   return opdFormValue?.amountClaim.toLocaleString(undefined, { maximumFractionDigits: 2 })
  // }, [opdFormValue?.amountClaim])

  const onSubmit = useCallback(
    (form: OpdFormParams) => {

      dispatch(
        setOpdInfo({
          id: opdItem?.id,
          name: form.name,
          amountReceipt: form.amountReceipt,
          amountClaim: claimAmountCheck,
          receiptFile: form.receiptFile,
          optionalFileUrl: form.optionalFileUrl,
          relativeTypeId: form.relativeTypeId,
          receiptDate: form.receiptDate,
          claimName: form.claimName,
          clinicType: form.clinicType,
        }),
      )
      if (editable === true) {
        history.push(paths.opdSummary({ routeParam: { opdId: opdItem?.id || 0 } }))
      }
    },
    [dispatch, opdItem?.id, editable, history, claimAmountCheck],
  )

  const getImageUploadUrl = useCallback(async (image, documentTypeId) => {
    try {
      const imageFile = await convertBase64ToFile(image || '')
      const imageFileUrl = await uploadFile({ files: imageFile, documentTypeId })

      return imageFileUrl[0].name
    } catch (error) {
      console.log("error", error)

      return ''
    }
  }, [uploadFile])

  const onSubmitApplication = useCallback(async () => {
    //uploadfile
    const optionalFileUrl = await getImageUploadUrl(opdFormValue?.optionalFileUrl || '', 9)
    const receiptImageURL = await getImageUploadUrl(opdFormValue?.receiptFile || '', 8)

    const userClaimDocumentList: UserClaimDocumentList[] = []
    if (receiptImageURL) {
      userClaimDocumentList.push({
        documentTypeId: 8,
        documentPath: receiptImageURL,
      })
    }
    if (optionalFileUrl) {
      userClaimDocumentList.push({
        documentTypeId: 9,
        documentPath: optionalFileUrl,
      })
    }
    createUserOpdClaim(
      {
        claimId: opdItem?.id || 0,
        receiptAmount: opdFormValue?.amountReceipt!,
        relativeId: opdFormValue?.relativeTypeId === '0' ? 0 : Number(opdFormValue?.relativeTypeId),
        receiptDate: dayjs(opdFormValue?.receiptDate).toISOString() || '',
        clinicType: opdFormValue?.clinicType || 'hospital',
        userClaimDocumentList: userClaimDocumentList
      },
      {
        onSuccess: (props) => {
          history.push(
            paths.opdCompleted({
              routeParam: { opdId: opdItem?.id || 0 },
              queryParam: {
                userClaimId: props?.id || 0,
              },
            }),
          )
        },
        onError: (error) => {
          setErrorMessage(error.message)
          setVisibleError.show()
        },
      },
    )
  }, [
    getImageUploadUrl,
    createUserOpdClaim,
    opdItem?.id,
    opdFormValue?.amountReceipt,
    opdFormValue?.relativeTypeId,
    opdFormValue?.receiptDate,
    opdFormValue?.receiptFile,
    opdFormValue?.optionalFileUrl,
    opdFormValue?.clinicType,
    history,
    setVisibleError,
  ])

  const onBackToEdit = useCallback(() => {
    history.push(paths.opdDetail({ routeParam: { opdId: opdItem?.id || 0 } }))
  }, [opdItem?.id, history])

  const onCheckCoin = useCallback(() => {
    if (!formValues?.amountReceipt || !formValues?.clinicType || !formValues.relativeTypeId) return

    fetch(`${config.apiHost}/app/userClaim/claimableAmount/OPD?requestAmount=${formValues?.amountReceipt}&clinicType=${formValues?.clinicType}&relativeTypeId=${formValues?.relativeTypeId}`, {
      method: 'GET',
      headers: {
        'X-Auth-Token': `${getToken()}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.fullCoinClaimableAmount === 0 && res.halfCoinClaimableAmount === 0) {
          setErrorMessage('วงเงินเบิกสิทธิ์ OPD ของคุณเต็มแล้ว')
          setVisibleError.show()
          return
        }

        setClaimAmountCheck(res.fullCoinClaimableAmount + res.halfCoinClaimableAmount)
        setIsClaimCheck(true)
        setClaimOpdAmount({
          fullCoinClaimableAmount: res.fullCoinClaimableAmount,
          halfCoinClaimableAmount: res.halfCoinClaimableAmount
        })
        // if (res.claimableAmount === 0 || formValues?.amountReceipt > res.claimableAmount) {
        //   modalAction.show()
        // }
        modalAction.show()
      }).catch((error) => {
        setErrorMessage(error.message)
        setVisibleError.show()
      })
  }, [modalAction, formValues, setVisibleError])

  const validateImage = useCallback((name: 'receiptFile' | 'optionalFileUrl', isRequired) => {
    if (isPreview === false && (!formValues[name]) && isRequired) {
      return [required]
    } else {
      return undefined
    }
  }, [formValues, isPreview])

  const validateClaimAmount = useCallback(
    (value) => {
      if (opdItem?.claimPrice && value > opdItem?.claimPrice) {
        return 'eClaim.claimPriceError'
      }
    },
    [opdItem?.claimPrice],
  )

  const isEnoughCoin = useCallback(
    (value) => {
      if (value > userCoin) {
        return 'eClaim.coinError'
      }
    },
    [userCoin])

  const isNotZero = useCallback(
    (value) => {
      if (value === '0') {
        return 'error.required'
      }
    }
    , [])

  const nameRelative = useCallback(
    (relativeId: number | string) => {
      const data = relativesOptions?.map((relativesOption) => {
        const { value, label } = relativesOption
        if (relativeId.toString() === value.id) {
          return label
        }
      })
      return data?.length === 0 ? 'ตัวเอง' : data
    },

    [relativesOptions],
  )

  const fullNameSummary = useMemo(() => {
    if (!opdFormValue?.relativeTypeId) return fullname

    return opdFormValue?.relativeTypeId === '0' ? fullname : nameRelative(opdFormValue?.relativeTypeId)
  }, [opdFormValue?.relativeTypeId, fullname, nameRelative])

  const clinicListsOption = useMemo(() => {
    const option = [{
      value: '',
      label: 'โปรดเลือก',
      disabled: true,
    }]

    clinicLists?.forEach((clinicList) => {
      const temp = {
        value: `${clinicList.value}`,
        label: clinicList.name,
        disabled: false,
      }

      option.push(temp)
    })

    return option
  }, [clinicLists])

  const userHalfMedicalCoin = useMemo(() => {
    return userCoinBenefit?.find(item => item.name === CoinBenefitName.HalfMedicalCoin)?.balance || 0
  }, [userCoinBenefit])

  useEffect(() => {
    setIsClaimCheck(false)
  }, [formValues?.clinicType, formValues?.amountReceipt])

  const relativesOptionsList = useMemo(() => {
    const relativesOptionsList = relativesOptions?.map((relative) => {
      return {
        label: relative.label,
        value: relative.value.id,
      }
    })

    return relativesOptionsList
  }, [relativesOptions])

  const disbleField = allpayStatus ? true : false

  if (editable && !formValues) return null

  return (
    <>
      <Modal
        visible={visibleError}
        onCancel={() => {
          setVisibleError.hide()
        }}
        onCancelText="ตกลง"
      >
        <div style={{ margin: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: 16 }}>
          <Image imageName='iconAlertPrivacy' width={72} />
          <TextModal size={24} type="Bold">
            {errorMessage}
          </TextModal>
          <TextModal>กรุณาตรวจสอบอีกครั้ง</TextModal>
        </div>
      </Modal>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        onCancelText="ตกลง"
      >
        <div style={{ margin: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: 8 }}>
          <Image imageName='iconAlertPrivacy' width={72} />
          <TextModal>วงเงิน 6,000 บาทแรก และ 6,000 บาทหลังของคุณที่สามารถเบิกได้</TextModal>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 8,
            marginTop: 16,
            marginBottom: 16,
            width: '100%',
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
              <TextModal>วงเงิน 6,000 บาทแรก (เบิกได้เต็มจำนวน)</TextModal>
              <TextModal style={{ minWidth: 60, textAlign: 'left' }}>:เบิกได้ {numberWithCommas(claimOpdAmount.fullCoinClaimableAmount)} บาท</TextModal>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
              <TextModal>วงเงิน 6,000 บาทหลัง (เบิกได้ 50% ของค่าใช้จ่าย)</TextModal>
              <TextModal style={{ minWidth: 60, textAlign: 'left' }}>:เบิกได้ {numberWithCommas(claimOpdAmount.halfCoinClaimableAmount)} บาท</TextModal>
            </div>
          </div>
          {userHalfMedicalCoin >= claimOpdAmount.halfCoinClaimableAmount && claimOpdAmount.halfCoinClaimableAmount > 0 ? (
            <>
              <TextModal>
                หมายเหตุ: *คุณมีส่วนต่างค่ารักษาพยาบาลที่ไม่สามารถเบิกในวงเงินส่วนเกิน OPD 6,000 บาทหลังได้
              </TextModal>
              <TextModal>
                สามารถนำส่วนต่างดังกล่าวมาเบิกต่อในวงเงิน FLEXi ได้ในรายการ
              </TextModal>
              <TextModal>
                <strong>'วงเงินส่วนขยายจากวงเงินค่ารักษาพยาบาลส่วนเกิน OPD'</strong> ตามจำนวน coin ที่คุณมีอยู่
              </TextModal>
              <TextModal>
                **หากมีเศษสตางค์ระบบจะปัดเศษสตางค์ทิ้ง
              </TextModal>
            </>
          ) : (
            <TextModal>
              *หากมีเศษสตางค์ระบบจะปัดเศษสตางค์ทิ้ง
            </TextModal>
          )}
        </div>
      </Modal>
      <ClaimFormContainer>
        <Text size={24} type="Bold">
          {editable ? 'กรอกรายละเอียดการเบิกสวัสดิการ' : 'ตรวจสอบรายละเอียด '}
        </Text>
        <InputContainer>
          {editable && (
            <FlexCol>
              <InputLabel size={18}>ชื่อ - นามสกุล ผู้ขอเบิก</InputLabel>
              <Text type="Bold" size={16}>{fullname}</Text>
            </FlexCol>
          )}
          {editable && (
            <FlexCol>
              <InputLabel size={18}>
                {t('eClaim.detail.form.claimFor')}{' '}
              </InputLabel>
              <RelativeField>
                <SelectField
                  name="relativeTypeId"
                  placeholder={t('eClaim.detail.form.claimFor')}
                  validate={[required]}
                  options={relativesOptionsList || []}
                  disabled={disbleField}
                  style={{ width: '100%' }}
                />
                {/* <CreateRelativeButton onClick={setVisibleCreate.show}>{t('family.addTitle')}</CreateRelativeButton> */}
              </RelativeField>
            </FlexCol>
          )}
          {isHistory && (
            <>
              <FlexCol>
                <InputLabel size={18}>{t('eClaim.detail.form.claimFor')}</InputLabel>
                <Text size={16} type='Bold'>{opdItem?.relativeId ? nameRelative(opdItem?.relativeId) : fullname}</Text>
              </FlexCol>
            </>
          )}
          {isPreview && (
            <FlexCol>
              <InputLabel size={18}>{t('eClaim.detail.form.claimFor')}</InputLabel>
              <Text size={16} type='Bold'>{fullNameSummary}</Text>
            </FlexCol>
          )}
        </InputContainer>
        <InputContainer>
          <FlexCol>
            {editable && (
              <DatePickerField
                name="receiptDate"
                label="วันที่ในใบเสร็จ"
                validate={[required, requiredDate]}
                disabled={disbleField}
                labelSize={18}
                maxDate={dayjs().endOf('day')}
                minDate={dayjs('07/01/2023')}
              />
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>วันที่ในใบเสร็จ</InputLabel>
                <Text type="Bold">{dayjs(opdFormValue?.receiptDate).format('DD/MM/YYYY')}</Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>วันที่ในใบเสร็จ</InputLabel>
                <Text type="Bold">{dayjs(opdItem?.slipDate).format('DD/MM/YYYY')}</Text>
              </>
            )}
          </FlexCol>
          {!editable && (
            <FlexCol>{' '}</FlexCol>
          )}
          <FlexCol>
            {editable && (
              <>
                <InputLabel size={18}>
                  สถานพยาบาล{' '}
                </InputLabel>
                <SelectField
                  name="clinicType"
                  placeholder={'เลือกสถานที่รักษา'}
                  validate={[required]}
                  options={clinicListsOption}
                  disabled={disbleField}
                  style={{ width: '100%' }}
                />
              </>
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>สถานพยาบาล</InputLabel>
                <Text type="Bold">{opdFormValue?.clinicType ? `${opdFormValue?.clinicType.charAt(0).toUpperCase() + opdFormValue.clinicType.slice(1)}` : ''}</Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>สถานพยาบาล</InputLabel>
                <Text type="Bold">{opdItem?.remark ? `${opdItem?.remark.charAt(0).toUpperCase() + opdItem.remark.slice(1)}` : ''}</Text>
              </>
            )}
          </FlexCol>
        </InputContainer>
        <InputContainer>
          <FlexCol>
            {editable && (
              <InputField
                name="amountReceipt"
                label={t('eClaim.detail.form.amountReceipt')}
                placeholder={t('eClaim.detail.form.inputAmount')}
                validate={[required, validateClaimAmount, isNotZero]}
                inputType="number"
                disabled={disbleField}
                labelSize={18}
              />
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.amountReceipt')}</InputLabel>
                <Text type="Bold" size={16}>
                  {opdFormValue?.amountReceipt ? numberWithCommas(Number(opdFormValue?.amountReceipt)) : 0}
                </Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.amountReceipt')}</InputLabel>
                <Text type="Bold" size={16}>
                  {opdItem?.userClaimAmount ? numberWithCommas(Number(opdItem?.userClaimAmount)) : 0}
                </Text>
              </>
            )}
          </FlexCol>
          <FlexCol style={{ marginBottom: 0 }}>
            <InputLabel size={18}>{' '}</InputLabel>
            {editable && (<OutlineButton onClick={onCheckCoin}>ตรวจสอบสิทธิ</OutlineButton>)}
          </FlexCol>
          <FlexCol>
            {isClaimCheck && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.claimAmount')}</InputLabel>
                <Text type="Bold" size={16}>{numberWithCommas(claimAmountCheck)}</Text>
              </>
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.claimAmount')}</InputLabel>
                <Text type="Bold" size={16}>
                  {opdFormValue?.amountClaim ? numberWithCommas(Number(opdFormValue?.amountClaim)) : 0}
                </Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.claimAmount')}</InputLabel>
                <Text type="Bold" size={16}>
                  {opdItem?.userClaimAmountApprove ? numberWithCommas(Number(opdItem?.userClaimAmountApprove)) : 0}
                </Text>
              </>
            )}
          </FlexCol>
        </InputContainer>
        <Text size={24} type="Bold">
          {t('eClaim.detail.form.attachDocuments')}
        </Text>
        <FileUploadContainer>
          {!isHistory && opdItem && opdItem?.claimDocumentTypes?.map((item, index) => {
            return (
              <UploadfileField
                key={index}
                name={item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl'}
                icon={'receiptIcon'}
                title={`${index + 1}. ${item.documentType.name}`}
                desc={item.documentType.description || ''}
                value={item.documentType.name === FileType.ReceiptFile ? opdFormValue?.receiptFile : opdFormValue?.optionalFileUrl}
                isDelete={isPreview}
                validate={validateImage(item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl', item.isRequired)}
                maximumFileSize={3}
              // disabled={disbleField}
              />
            )
          })
          }
          {isHistory && opdItem && opdItem?.userClaimDocumentList?.map((item, index) => {
            return (
              <UploadfileField
                key={index}
                name={item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl'}
                icon={'receiptIcon'}
                title={`${index + 1}. ${item.documentType.name}`}
                desc={item.documentType.description || ''}
                // value={opdFormValue?.userClaimDocumentList[index]?.documentPath}
                isDelete={isHistory}
                // validate={validateImage(item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl', item.isRequired)}
                maximumFileSize={3}
                disabled={true}
              />
            )
          })
          }
        </FileUploadContainer>
        {editable && (
          <ButtonWrapper>
            <SubmitButton onClick={handleSubmit(onSubmit)} disabled={invalid || !isClaimCheck}>
              {t('eClaim.detail.form.next')}
            </SubmitButton>
          </ButtonWrapper>
        )}
        {isPreview && (
          <ButtonWrapper>
            <SubmitButton onClick={onSubmitApplication} disabled={isSubmitting || isUploading}>
              {t('eClaim.detail.form.submit')}
            </SubmitButton>
          </ButtonWrapper>
        )}
      </ClaimFormContainer>
    </>
  )
}

export default reduxForm<OpdFormParams, OPDForm, string>({
  form: OPD_FORM_NAME,
  // initialValues: {
  //   relativeTypeId: 'ตัวเอง',
  // },
})(OpdForm)