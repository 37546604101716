import React, { ReactNode, CSSProperties } from 'react'
import styled from '@emotion/styled'
import color from '../../constant/colors'

const CustomAntCard = styled.div`
  background-color: ${color.WHITE};
  border-radius: 8px;
  // box-shadow: 0px 0px 6px 6px rgba(242, 222, 223, 0.4);
  :hover {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }
`

type CustomCardProps = {
  children?: ReactNode
  onClick?: () => void
  className?: string
  style?: CSSProperties
}

const Card = (props: CustomCardProps) => {
  const { children, style, onClick, ...restProps } = props

  return (
    <CustomAntCard style={style} onClick={onClick} {...restProps}>
      {children}
    </CustomAntCard>
  )
}

export default Card
