import React from 'react'
import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'
import { home, pointHistory } from '../../constant/path'
import Text from '../../components/common/Text'
import List from './List'
import { useTranslation } from 'react-i18next'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }
  text-align: center;
`
const PointHistory = () => {
  const { t } = useTranslation()
  const title = t('pointHistory.title')

  return (
    <Layout>
      <Breadcrumb
        overide={(paths) => {
          paths[0] = {
            label: 'Home',
            path: home(),
          }
          paths[1] = {
            label: title,
            path: pointHistory(),
          }
          return paths
        }}
      />

      <Text className="title-font" size={28} type="Bold">
        {title}
      </Text>
      <List />
    </Layout>
  )
}

export default PointHistory
