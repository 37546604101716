import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import {
  ClaimAmountCheckResponse,
  CreateEclaimUser,
  CreateEclaimUserResponse,
  CreateUserClaimEnrollments,
  EClaimEnllomentItemList,
  EClaimHistoryItem,
  EClaimItem,
  EClaimList,
  EClaimListResponse,
  EClaimsHistoryListResponse,
  GetCurrentBenefitYear,
  GetEClaimByIdProps,
  GetEClaimHistoryByIdProps,
  GetRelativeResponse,
  RelativeScgResponse,
  RelativeTypeResponse,
  UploadFile,
} from './e-claim-types'
import { GET_USER_COIN_URL } from '../user/user-query'
import { OPD_HISTORY_LIST } from '../opd/opd-query'

const APP = '/app'
export const USER_CLAIM = 'userClaim'
export const E_CLAIM_LIST = `${APP}/${USER_CLAIM}/getClaimList`
export const E_CLAIM_BY_ID = `${APP}/${USER_CLAIM}/getClaim`

export const E_CLAIM_LIST_SCG = `${APP}/${USER_CLAIM}/getAllUserClaimByUser`

export const E_CLAIM_HISTORY_BY_ID = `${APP}/${USER_CLAIM}/getUserClaimById`

export const CREATE_USER_CLAIM = `${APP}/${USER_CLAIM}/createUserClaim`
export const UPLOADFILE = `${APP}/${USER_CLAIM}/upload`
export const E_CLAIM_ENROLLMENT = `${APP}/${USER_CLAIM}/enrollment`
export const CREATE_USER_CLAIM_ENROLLMENT = `${APP}/${USER_CLAIM}/enrollment`


export const CURRENT_BENEFIT_YEAR = `${APP}/enrollments/period`
export const GET_RELATIVE = `${APP}/enrollments/relative`
export const GET_RELATIVE_SCG = `${APP}/${USER_CLAIM}/relativeList`
export const GET_RELATIVE_TYPE_SCG = `${APP}/enrollments/relative-type`
export const GET_RELATIVE_TYPE_LIST_SCG = `${APP}/enrollments/relative-type-list`
export const CANCEL_E_CLAIM = `${APP}/${USER_CLAIM}/cancel`

export const CLAIM_AMOUNT_CHECK = `${APP}/${USER_CLAIM}/claimableAmount`

export const useGetEClaimList = () => {
  return useQuery([E_CLAIM_LIST], () => {
    return api.belive.get<EClaimList>(`${E_CLAIM_LIST}`)
  })
}

export const useGetEClaimListScg = () => {
  return useQuery([E_CLAIM_LIST], () => {
    return api.belive.get<EClaimListResponse>(`${E_CLAIM_LIST}`)
  })
}

export const useGetEClaimListHistory = () => {
  return useQuery([E_CLAIM_LIST_SCG], () => {
    return api.belive.get<EClaimsHistoryListResponse>(`${E_CLAIM_LIST_SCG}`)
  })
}

export const useGetEClaimById = (props: GetEClaimByIdProps) => {
  const { claimId } = props

  return useQuery([E_CLAIM_BY_ID, claimId], () => {
    return api.belive.get<EClaimItem>(`${E_CLAIM_BY_ID}/${claimId}`)
  })
}

export const useGetEClaimHistoryById = (props: GetEClaimHistoryByIdProps) => {
  const { id } = props

  return useQuery([E_CLAIM_HISTORY_BY_ID, id], () => {
    return api.belive.get<EClaimHistoryItem>(`${E_CLAIM_HISTORY_BY_ID}/${id}`)
  })
}

export const useGetCurrentBenefitYear = () => {
  return useQuery([CURRENT_BENEFIT_YEAR], () => {
    return api.belive.get<GetCurrentBenefitYear>(`${CURRENT_BENEFIT_YEAR}`)
  })
}

export const useCreateUserClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateEclaimUser) => {
      const {
        claimId,
        receiptAmount,
        receiptDate,
        relativeId,
        userClaimDocumentList
      } = data
      return api.belive.post<CreateEclaimUserResponse>(
        CREATE_USER_CLAIM,
        JSON.stringify({
          claimId,
          receiptAmount,
          receiptDate,
          relativeId,
          userClaimDocumentList
        }),
        { contentType: ContentType.JSON },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_LIST])
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
        queryClient.invalidateQueries([E_CLAIM_LIST_SCG])
        queryClient.invalidateQueries([GET_USER_COIN_URL])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useUploadFile = () => {
  const mutate = useMutation(
    async ({ files, documentTypeId }: { files: File, documentTypeId: number }) => {
      return api.belive.post<UploadFile>(
        `${UPLOADFILE}?documentTypeId=${documentTypeId}`,
        { files },
        { contentType: ContentType.FORMDATA },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Upload File Success', data)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}

export const useGetEClaimEnrollment = (id: number | string) => {
  return useQuery([E_CLAIM_ENROLLMENT, id], () => {
    return api.belive.get<EClaimEnllomentItemList>(`${E_CLAIM_ENROLLMENT}`, { id: id })
  })
}

export const useCreateUserClaimEnrollment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateUserClaimEnrollments) => {
      return api.belive.post(CREATE_USER_CLAIM_ENROLLMENT, data, { contentType: ContentType.JSON })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_ENROLLMENT])
        queryClient.invalidateQueries([E_CLAIM_LIST])
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useGetRelative = () => {
  return useQuery([GET_RELATIVE], () => {
    return api.belive.get<GetRelativeResponse>(`${GET_RELATIVE}`)
  })
}

// export const useGetRelativeOptions = () => {
//   return useQuery([GET_RELATIVE, 'options'], async () => {
//     const data = await api.belive.get<GetRelativeResponse>(`${GET_RELATIVE}`)
//     const optionRelative: {
//       label: string
//       value: string | number
//       disabled?: boolean
//     }[] = []
//     optionRelative.push({ label: 'ตัวเอง', value: '0', disabled: true })

//     data?.map((relativesGroup) => {
//       return relativesGroup.relativeList.map((relative) => {
//         const { firstname, lastname, relativeTypeId } = relative
//         const label = `${firstname} ${lastname}`
//         optionRelative.push({ label: `${firstname} ${lastname}`, value: relativeTypeId })
//       })
//     })
//     return optionRelative
//   })
// }

export const useGetRelativeLists = (claimId?: number) => {
  return useQuery([GET_RELATIVE_SCG], () => {
    return api.belive.get<RelativeScgResponse>(`${GET_RELATIVE_SCG}/${claimId}`)
  })
}

export const useGetRelativeOption = (claimId?: number) => {
  const { t } = useTranslation()

  return useQuery([GET_RELATIVE_SCG, 'options', claimId], async () => {
    const data = await api.belive.get<RelativeScgResponse>(`${GET_RELATIVE_SCG}/${claimId}`)
    const optionRelative: {
      label: string
      value: string | number
      disabled?: boolean
    }[] = []
    // optionRelative.push({ label: t('eClaim.detail.form.pleaseSelect'), value: '', disabled: true })

    // if (relativeTypeId) {
    //   data.filter(items => items.typeId === relativeTypeId).map((relative) => {
    //     const { firstname, lastname, id, typeName } = relative
    //     optionRelative.push({ label: `${typeName}: ${firstname} ${lastname}`, value: id.toString() })
    //   })
    // } else {
    data.map((relative) => {
      const { firstname, lastname, id, typeName } = relative
      optionRelative.push({ label: `${typeName}: ${firstname} ${lastname}`, value: id.toString() })
    })
    // }

    return optionRelative
  })
}


export const useGetRelativeType = (id: number | string) => {
  return useQuery([GET_RELATIVE_TYPE_SCG, id], () => {
    return api.belive.get<RelativeTypeResponse>(`${GET_RELATIVE_TYPE_SCG}`, { typeId: id }),
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      enabled: false,
    }
  })
}

export const useGetRelativeTypeOption = () => {
  return useQuery([GET_RELATIVE_TYPE_LIST_SCG, 'options'], async () => {
    const data = await api.belive.get<RelativeTypeResponse[]>(`${GET_RELATIVE_TYPE_LIST_SCG}`)
    const optionRelativeType: {
      label: string
      value: string | number
    }[] = []

    data.map((relativeType) => {
      const { name, id } = relativeType
      optionRelativeType.push({ label: `${name}`, value: id })
    })

    return optionRelativeType
  })
}

export const useClaimAmountCheck = (amount: number) => {
  return useQuery([CLAIM_AMOUNT_CHECK], () => {
    return api.belive.get<ClaimAmountCheckResponse>(`${CLAIM_AMOUNT_CHECK}`, {
      requestAmount: amount
    })
  })
}

export const useCancelEClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      return api.belive.post(`${CANCEL_E_CLAIM}/${id}`, { contentType: ContentType.JSON })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_LIST_SCG])
        queryClient.invalidateQueries([E_CLAIM_HISTORY_BY_ID])
        queryClient.invalidateQueries([GET_USER_COIN_URL])
        queryClient.invalidateQueries([OPD_HISTORY_LIST])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}