import styled from '@emotion/styled'
import { ModalProps } from 'antd/lib/modal'
import { useCallback, useState } from 'react'
import Button from '../../components/common/Button'
import Modal from '../../components/common/Modal'
import RadioComp from '../../components/common/Radio'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { useGetUser } from '../../services/user/user-query'
import { mobile } from '../../utils/responsive-helper'
import { Footer } from '../Enrollment/ModalAcceptPrivacy'

const Layout = styled.div`
    padding: 16px;

  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    padding-top: 24px;

    .header {
      padding-right: 32px;
    }
  }
`
const Header = styled.div`
  text-align: center;
  margin-bottom: 16px;
`
const Content = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  
  ${mobile} {
    padding: 16px;
  }
`
const ContentScroll = styled.div`
  max-height: calc(65vh - 48px);
  max-width: 784px;
  overflow-y: auto;

  ${mobile} {
    max-width: 100%;
  }
`
const TextCenter = styled(Text)`
  text-align: center;
`
const TextUser = styled.strong`
  color: ${color.FREE_SPEECH_RED};
`
const ContentDetail = styled.div`
  padding: 0 14px;

  ${mobile} {
    padding: 0;
  }
`
const ConfirmButton = styled(Button)`
  width: 200px;
`


type ModalRelativeConsentProps = {
  name: string
  idCard: string
  relativeTypeName: string
  onConfirmClick: () => void
  onCloseClick: () => void
} & Required<Pick<ModalProps, 'visible'>>

const ModalRelativeConsent = (props: ModalRelativeConsentProps) => {
  const { name, idCard, relativeTypeName, visible, onConfirmClick, onCloseClick } = props
  const [isAccept, setIsAccept] = useState('1')
  const { data: User } = useGetUser()
  // const { mutate: sendConsent } = useSendConsent()

  const onCancel = useCallback(() => {
    onCloseClick()
    setIsAccept('')
  }, [onCloseClick])

  const onConfirm = useCallback(() => {
    // sendConsent({
    //   isConsent: isAccept === '1',
    //   benefitYearId: 1
    // }, {
    //   onSuccess: () => {
    onConfirmClick()
    setIsAccept('')
    //   },
    //   onError: (error: any) => {
    //     console.error(error.message)
    //     message.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล (Consent)')
    //   }
    // })
  }, [onConfirmClick])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      style={{
        padding: 0,
      }}
      hideFooter
      hideCloseIcon
    >
      <Layout>
        <Header>
          <TextCenter size={16} type="Bold">การให้ความยินยอมในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของครอบครัวของพนักงาน</TextCenter>
          <TextCenter size={16} type="Bold">บริษัท กรุงเทพ ซินธิติกส์ จำกัด / บริษัท บีเอสที อิลาสโตเมอร์ส จำกัด</TextCenter>
        </Header>
        <Content>
          <ContentScroll>
            <Text size={14}>
              เพื่อปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
            </Text>
            <Text size={14}>
              <strong>ข้าพเจ้า</strong> <TextUser>{name}</TextUser>
            </Text>
            <Text size={14}>
              <strong>เลขประจำตัวประชาชน</strong> <TextUser>{idCard || '-'}</TextUser>
            </Text>
            <br />
            <Text size={14}>
              <strong>มีฐานะเป็น</strong> <TextUser>{relativeTypeName || '-'}</TextUser> ของพนักงาน <TextUser>{`${User?.firstNameTH} ${User?.lastNameTH}`}</TextUser> รหัสพนักงาน <TextUser>{User?.empId || '-'}</TextUser>
            </Text>
            <Text size={14} style={{ marginBottom: 4 }}>
              ขอแสดงความประสงค์ในการให้ความยินยอมแก่ บริษัท กรุงเทพ ซินธิติกส์ จำกัด / บริษัท บีเอสที อิลาสโตเมอร์ส จำกัด (“บริษัทฯ”)
            </Text>
            <ContentDetail >
              <Text size={14} style={{ textIndent: 14 }}>
                1. เพื่อการเก็บ ใช้ และเปิดเผย ข้อมูลประกันสุขภาพ/ประกันอุบัติเหตุ/ประกันชีวิต ข้อมูลการรับวัคซีนป้องกันโรคต่างๆ
                ข้อมูลสุขภาพ ประวัติการรักษา รวมถึงใบรับรองแพทย์และข้อมูลเกี่ยวกับสุขภาพของข้าพเจ้าให้แก่บริษัทฯ เพื่อการดำเนินการใดๆ
                ที่เกี่ยวข้องกับการเบิกสวัสดิการต่างๆ และค่ารักษาพยาบาลตามสิทธิที่เบิกตรงกับบริษัทฯ อันเป็นสวัสดิการที่บริษัทฯ มอบให้แก่บิดา มารดา คู่สมรส บุตรของพนักงานตามข้อกำหนดของบริษัทฯ
              </Text>
              <Text size={14} style={{ textIndent: 14 }}>
                2.เพื่อการเก็บ ใช้ และเปิดเผย ข้อมูลสุขภาพ ประวัติการรักษา
                รวมถึงใบรับรองแพทย์และข้อมูลเกี่ยวกับประกันของข้าพเจ้าให้แก่บริษัทนายหน้าประกันภัยและบริษัทประกันผู้เป็นคู่สัญญากับบริษัทฯ
                เพื่อการดำเนินการใดๆ ที่เกี่ยวข้องกับการทำประกันสุขภาพ ประกันชีวิต ประกันอุบัติเหตุ และ/หรือ ทุพพลภาพ
                ค่ารักษาพยาบาลส่วนเกินที่เบิกตรงกับบริษัทฯ อันเป็นสวัสดิการที่บริษัทฯ มอบให้แก่คู่สมรส บุตร ของพนักงาน
                และพนักงานประสงค์ขอรับบัตรประกันสุขภาพให้แก่คู่สมรส บุตร ของพนักงาน ตามข้อกำหนดของบริษัทฯ รวมถึง
                การสมัครกรมธรรม์ การเบิกค่ารักษาพยาบาลตามสิทธิ การเคลมหรือการเรียกร้องค่าสินไหมทดแทนอื่น ๆ
                การเรียกเก็บส่วนเกินสิทธิค่ารักษาพยาบาลคืนจากพนักงาน การต่ออายุและการยกเลิกกรมธรรม์ เป็นต้น
              </Text>
            </ContentDetail>
            <br />
            <Text size={14} type="Bold">
              ข้าพเจ้ามีความประสงค์ดังนี้
            </Text>
            <ContentDetail style={{ marginBottom: 4 }}>
              <RadioComp
                options={
                  [
                    {
                      label: 'ยินยอม',
                      value: 1
                    },
                    {
                      label: 'ไม่ยินยอม*',
                      value: 0
                    }
                  ]
                }
                value={isAccept}
                setValue={setIsAccept}
              />
            </ContentDetail>
            <Text size={14} type="Bold" style={{ marginBottom: 4 }}>
              * ในกรณีที่ไม่ได้ให้ความยินยอม เจ้าของข้อมูลส่วนบุคคลอาจไม่ได้รับความสะดวกในการรับการเบิกจ่ายหรือการใช้สวัสดิการ
            </Text>
            <Text size={14}>
              หมายเหตุ: ท่านสามารถอ่านรายละเอียดเกี่ยวกับการเก็บข้อมูลส่วนบุคคลของท่านได้ที่คำประกาศคุ้มครองข้อมูลส่วนบุคคล (Privacy Notice)
              ได้ที่หน้า website <a href='https://www.bst.co.th/index.php/privacy-notice'>https://www.bst.co.th/index.php/privacy-notice</a> ของบริษัทฯ
              และหากท่านประสงค์จะเพิกถอนความยินยอมหรือสอบถามรายละเอียดเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลท่านสามารถติดต่อได้ที่ e-mail : Somchoke_d@bst.co.th
            </Text>
          </ContentScroll>
        </Content>
        <Footer>
          <ConfirmButton onClick={onConfirm} disabled={!Number(isAccept)}>
            ตกลง
          </ConfirmButton>
        </Footer>
      </Layout>
    </Modal>
  )
}

export default ModalRelativeConsent