import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Button from '../../components/common/Button'
import Card from '../../components/common/Card'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import colors from '../../constant/colors'
import { home } from '../../constant/path'
import { RootState } from '../../redux-store'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`
const CardLayout = styled(Card)`
  width: 100%;
  max-width: 784px;
  margin: 65px auto 0 auto;
  padding: 40px 28px;
  box-shadow: 0px 4px 8px rgba(113, 113, 113, 0.1);
  border-radius: 8px;
  z-index: 1;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;

  ${mobile} {
    margin: 22px auto 0 auto;
    padding: 56px 24px;
  }
`
const RelativeLayout = styled.div`
  background: #FAF9F6;
  border: 1px solid #E7E2D8;
  border-radius: 8px;
  padding: 16px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 0px;
  margin-bottom: 50px;
`
const TextCenter = styled(Text)`
  text-align: center;
`
const TextSpan = styled.span`
  color : ${colors.CHOICES_PRIMARY}
`
const ConfirmButton = styled(Button)`
  width: 350px;
  
  ${mobile} {
    width: calc(100vw - 42px);
  }
`

const RelativeCompleted = () => {
  const history = useHistory()
  const relativeFormValue = useSelector((state: RootState) => state.relative.relativeInfo)

  return (
    <Layout>
      <BackgroundHeader />
      <CardLayout>
        <Image style={{ marginBottom: 40 }} imageName='GroupImage' />
        <Text size={20} type="Bold">{relativeFormValue?.id ? 'แก้ไขรายชื่อครอบครัวสำเร็จ' : 'เพิ่มรายชื่อครอบครัวสำเร็จ'}</Text>
        <RelativeLayout>
          <TextCenter size={16} type="Regular">
            เอกสารอยู่ระหว่างการตรวจสอบ <br />
            คุณจะได้รับ e-mail ยืนยันหลังจากได้รับการอนุมัติแล้ว
          </TextCenter>
          <Text size={16} type="Regular">-------------------------------------------------------------------------</Text>
          <TextCenter size={16} type="Regular">
            หลังจากได้รับการอนุมัติแล้ว <br />
            กรณีลงทะเบียนคู่สมรส-บุตรครั้งแรก และต้องการแลกบัตรประกันสุขภาพให้คู่สมรส-บุตร <br />
            โปรดดำเนินการแลก coin
          </TextCenter>
          <TextCenter size={16} type="Regular">
            ที่เมนู <TextSpan>“ประกันสุขภาพของพนักงาน และ คู่สมรส-บุตร”</TextSpan> <br />
            เพื่อขอรับบัตรประกันสุขภาพให้คู่สมรส-บุตร <br />
            กรณีเพิ่มชื่อบุตร (เคยแลก 8000 coins ไปแล้ว) <br />
            ทาง HR จะดำเนินการยื่นเอกสารให้กับบริษัทประกันต่อไป
          </TextCenter>
        </RelativeLayout>
        <ConfirmButton onClick={() => { history.push(home()) }}>
          กลับหน้าแรก
        </ConfirmButton>
      </CardLayout>
    </Layout>
  )
}

export default RelativeCompleted