import styled from '@emotion/styled'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Headbar from '../../components/common/HeadBar'
import Tabs from '../../components/common/Tabs'
import colors from '../../constant/colors'
import * as paths from '../../constant/path'
import { useGetEClaimListHistory } from '../../services/e-claim/e-claim-query'
import { EClaimHistory as EClaimHistoryType, EClaimStatus, UserClaimList } from '../../services/e-claim/e-claim-types'
import { useScreen } from '../../utils/responsive-helper'
import { EClaimHistoryCard } from './component'

const Layout = styled.div`
  margin-bottom: 40px;
`
const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  padding: 16px;
  border-radius: 16px;
  min-height: 500px;
`
const EClaimCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

type EClaimHistoryPageProps = {
  data: EClaimHistoryType
}
const EClaimHistoryPage = (props: Partial<EClaimHistoryPageProps>) => {
  const { data } = props

  return (
    <EClaimCardLayout>
      {data?.userClaimList?.map((item: UserClaimList) => (
        <Link
          key={item.claimId}
          to={paths.eClaimHistoryDetail({ routeParam: { id: item.id } })}
        >
          <EClaimHistoryCard data={item} status={data?.statusName} />
        </Link>
      ))}
    </EClaimCardLayout>
  )
}

const EClaimHistory = () => {
  const { isMobile } = useScreen()
  const { t } = useTranslation()
  const { data: EClaimHistoryList, isLoading } = useGetEClaimListHistory()

  const dataSource = useMemo(() => {
    return [
      {
        name: t('eClaim.history.status.pending'),
        key: '0',
        page: <EClaimHistoryPage data={EClaimHistoryList?.filter((item: EClaimHistoryType) => item.statusName === EClaimStatus.UNAPPROVED)[0]} />,
      },
      {
        name: t('eClaim.history.status.cancel'),
        key: '1',
        page: <EClaimHistoryPage data={EClaimHistoryList?.filter((item: EClaimHistoryType) => item.statusName === EClaimStatus.CANCELED)[0]} />,
      },
      {
        name: t('eClaim.history.status.rejected'),
        key: '2',
        page: <EClaimHistoryPage data={EClaimHistoryList?.filter((item: EClaimHistoryType) => item.statusName === EClaimStatus.REJECTED)[0]} />,
      },
      {
        name: t('eClaim.history.status.approved'),
        key: '3',
        page: <EClaimHistoryPage data={EClaimHistoryList?.filter((item: EClaimHistoryType) => item.statusName === EClaimStatus.APPROVED)[0]} />,
      },
    ]
  }, [EClaimHistoryList])

  return (
    <>
      <Layout style={{ paddingTop: '24px' }}>
        <Headbar title={t('eClaim.history.title')} backWard={paths.eClaim()} />
        <ContentLayout>
          <Tabs
            dataSource={dataSource}
            mode={isMobile ? 'select' : undefined}
          />
        </ContentLayout>
      </Layout>
    </>
  )
}
export default EClaimHistory 
