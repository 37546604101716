import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux-store'
import { mobile } from '../../utils/responsive-helper'
import RelativeForm from './RelativeForm'

const Layout = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 24px;
  }

  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }

  ${mobile} {
    padding: 0px 16px 40px;
  }
`

const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    margin: 0 -16px;
  }
`

const RelativeCreate = () => {
  const relativeFormValue = useSelector((state: RootState) => state.relative.relativeInfo)

  return (
    <Layout>
      <BackgroundHeader />
      <RelativeForm
        relativeFormValue={relativeFormValue}
        disbleField={false}
      />
    </Layout>
  )
}

export default RelativeCreate