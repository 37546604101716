export type EClaimListItem = {
  claimId: number
  claimName: string
  claimIcon: string
  maxClaimablePrice: number
  fixExchangeCoin: string
  availableAmount: number
  claimDetail: string
  claimTypeId: number
  sumClaimAmount: number
}

export type EClaimList = Array<EClaimListItem>

export type GetEClaimByIdProps = {
  claimId: number
}
export type GetEClaimHistoryByIdProps = {
  id: number
}

export type GetCurrentBenefitYear = {
  benefitYearId: number,
  startDate: number,
  endDate: number,
  isActive: boolean,
  active: boolean
}

export type RelativeGroup = {
  typeId: number
  typeName: string
  maxNumber: number
  description: string
  relativeList: RelativeList[]
}

export type RelativeList = {
  id: number
  title: string
  firstname: string
  lastname: string
  birthday: string
  nationalId: number
  userId: number
  relativeTypeId: number
  createdDate: number
  createdBy: string
  updatedDate: number
  updatedBy: string
  enable: boolean
  idCard?: string;
  educationId?: number;
  status?: string;
  statusUpdatedDate?: number;
  statusUpdatedBy?: string;
  reason?: string;
  remark?: string;
}
export type GetRelativeResponse = RelativeGroup[]

export type RelativeScg = {
  typeId: number
  typeName: string
  id: number
  title: string
  firstname: string
  lastname: string
}
export type RelativeScgResponse = RelativeScg[]

export type RelativeTypeResponse = {
  id: number
  name: string
  maxNumber: number
  description: string
  createdDate: number
  createdBy: string
  updatedDate: number
  updatedBy: string
  enable: boolean
}

export enum EClaimStatus {
  UNAPPROVED = 'UNAPPROVED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export type EClaimsHistoryListResponse = EClaimHistory[]

export type EClaimHistory = {
  statusName: EClaimStatus;
  userClaimList: UserClaimList[];
}

export type UserClaimList = {
  id: number;
  userClaimNo: null | string;
  userId: number;
  claimId: number;
  exchangeCoin: number;
  claimName: string;
  claimDate: number;
  receiptFile: string;
  citizenFileUrl: string;
  userClaimStatus: null;
  remark: string;
  userClaimUpdatedBy: string;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  userClaimAmount: number;
  refundDate: number;
  userClaimUpdatedDate: number;
  userClaimAmountApprove: number;
  relativeId: number;
  slipDate: number;
  rejectedReason: string;
  userClaimDocumentList: UserClaimDocumentList[];
  claim: EClaimItem;
  relative: RelativeList;
  canceled: boolean;
  approved: boolean;
  userInfo: UserInfo;
}
// export type EClaimHistory = {
//   id: number
//   claimIcon?: string
//   userId: string
//   userName: string
//   claimId: number
//   exchangeCoin: number
//   claimName: string
//   claimDate: string
//   receiptFile: string
//   optionalFileUrl: string
//   accept: string
//   remark: string
//   acceptBy: string
//   empId: string
//   firstName: string
//   lastName: string
//   fullName: string
//   refundDate: string
//   allpayStatus: string
//   allpayUpdateDate: string
//   allpayRemark: string
//   allpayReason: string
// }

export type EClaimItem = {
  id: number;
  name: string;
  claimTypeId: number;
  exchangeCoin: null;
  fixExchangeCoin: null;
  detail: null;
  show: null;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  showDashboard: null;
  allowedAmount: number;
  limit: number;
  limitType: number;
  probation: boolean;
  maxClaimableLimit: number;
  claimPrice: number;
  icon: string;
  nameEn: string;
  description: null;
  descriptionEn: null;
  claimPercent: null;
  useBudget: null;
  showDialog: boolean;
  showDialogMessage: string;
  showDialogMessageEn: null;
  showDescription: null;
  showDescriptionTitle: null;
  showSubClaim: null;
  showSubClaimTitle: null;
  showReceiptDate: null;
  isDefaultValue: null;
  defaultValue: null;
  claimDocumentTypes: ClaimDocumentTypes[];
  userClaimDocumentList: ClaimDocumentTypes[];
  relativeTypes: RelativeType[];
  tax: boolean;
  // receiptDate?: boolean;
  // claimName?: string;
  // relativeTypeId: number;
  // amountClaim?: number;
  // amountReceipt?: number;
}
export type RelativeType = {
  id: number;
  code: string;
  name: string;
  maxNumber: number;
  description: null | string;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  enable: boolean;
  exchangeBenefit: boolean;
}
export type ClaimDocumentTypes = {
  id: number;
  claimId: number;
  userClaimId: number;
  documentTypeId: number;
  isRequired: boolean;
  documentType: DocumentType;
  filePath: string
}
export type DocumentType = {
  id: number;
  name: string;
  description: null | string;
  enable: boolean;
}
export type CreateEclaimUser = {
  claimId: number;
  receiptAmount: number;
  relativeId: number;
  receiptDate: string;
  userClaimDocumentList: UserClaimDocumentList[];
}
export type UserClaimDocumentList = {
  documentTypeId: number;
  documentPath: string;
}
export type UploadFile = {
  id: number;
  name: string;
  fileType: string;
}[]
export enum FileType {
  ReceiptFile = 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
  OptionalFileUrl = 'ใบรับรองแพทย์'
}

export type EClaimFormParams = {
  id?: number
  name: string
  amountReceipt: number
  claimName: string
  claimDate: moment.Moment
  // userClaimDocumentList: UserClaimDocumentList[]
  receiptFile: string
  optionalFileUrl: string
  relativeTypeId: string
  userRemark?: string
  allpayRef?: string
  relativeTitle?: string
  relativeFirstName?: string
  relativeLastName?: string
  receiptDate: number
  amountClaim: number
  nameShop: string
  slipNo: string
}

export type EClaimEnllomentItem = {
  totalSelectedCoin?: number
  preSelectedClaimWrapperList: PreSelectedClaimWrapperList[]
  claimTypeName: string
  claimTypeId: string | number
}

export type PreSelectedClaimWrapperList = {
  maxClaimablePrice: number
  fixExchangeCoin: boolean
  detail: string
  preSelectedAmount: number
  claimName: string
  claimId: number
}

export type EClaimEnllomentItemList = Array<EClaimEnllomentItem>

export type CreateUserClaimEnrollment = {
  claimTypeId: string
  benefitYearId: string
  usersPreSelectedClaimsDTOList: UsersPreSelectedClaimsDTOList[]
}

export type UsersPreSelectedClaimsDTOList = {
  preSelectedAmount: number
  claimId: string
}

export type CreateUserClaimEnrollments = Array<CreateUserClaimEnrollment>

export type EClaimListResponse = EClaimListGroup[]

export type EClaimListGroup = {
  id: number
  name: string
  createdDate: number
  createdBy: string
  updatedDate: number
  updatedBy: string
  claimTypeGroupId: number
  claimList: EClaimItem[]
}

export type EClaimType = {
  id: number
  tax: boolean
  name: string
  limit: number
  icon: string
  nameEn: string
  limitType: number
  probation: boolean
  description: string
  claimTypeId: number
  showDialog: boolean
  allowedAmount: number
  claimTypeGroupId: number
  maxClaimableLimit: number
  showDialogMessage: string
  claimTypeGroupName: string
  createdBy: string
  updatedBy: string
}

export type EClaimListNew = {
  claimId: number
  claimName: string
  claimIcon: string
  maxClaimablePrice: number
  fixExchangeCoin: boolean
  availableAmount: number
  claimDetail: string
  claimTypeId: number
  sumClaimAmount: number
  claimTypeGroupId: number
  claimTypeName: string
  claimTypeGroupName: string
}

export type ClaimAmountCheckResponse = {
  claimableAmount: number
}

export type CreateEclaimUserResponse = {
  id: number;
  userClaimNo: string;
  userId: number;
  claimId: number;
  exchangeCoin: null;
  claimName: null;
  claimDate: null;
  receiptFile: null;
  citizenFileUrl: null;
  userClaimStatus: null;
  remark: null;
  userClaimUpdatedBy: null;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  userClaimAmount: number;
  refundDate: null;
  userClaimUpdatedDate: null;
  userClaimAmountApprove: number;
  relativeId: number;
  slipDate: number;
  rejectedReason: null;
  userClaimDocumentList: UserClaimDocumentList[];
  claim: null;
  relative: null;
  canceled: boolean;
  approved: null;
  userInfo: UserInfo;
}

export type EClaimHistoryItem = {
  id: number;
  userClaimNo: string;
  userId: number;
  claimId: number;
  exchangeCoin: null;
  claimName: null;
  claimDate: null;
  receiptFile: null;
  citizenFileUrl: null;
  userClaimStatus: null;
  remark: string;
  userClaimUpdatedBy: null;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  userClaimAmount: number;
  refundDate: null;
  userClaimUpdatedDate: null;
  userClaimAmountApprove: number;
  relativeId: number;
  slipDate: number;
  rejectedReason: null;
  status: EClaimStatus;
  userClaimDocumentList: ClaimDocumentTypes[];
  claim: EClaimItem;
  relative: RelativeList;
  canceled: boolean;
  approved: null;
  userInfo: UserInfo;
  paymentDate: number;
}

export type UserInfo = {
  userId: number;
  empId: string;
  firstname: null;
  lastname: null;
  firstnameEn: string;
  lastnameEn: string;
  phoneNumber: null;
  email: string;
}